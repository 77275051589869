import moment from 'moment-timezone';
import { LOAD_WEEK } from '../actionTypes';
const LOGGING = true;
export const loadWeek = (week) => ({
  type: LOAD_WEEK,
  week,
});

export const setNextWeek = () => {
  LOGGING && console.log('setNextWeek called');
  return (dispatch, getState) => {
    const week = [7, 8, 9, 10, 11, 12, 13];
    const nextWeek = week.map((day) =>
      moment().day(day).hour(12).minute(0).second(0).millisecond(0)
    );
    LOGGING &&
      console.log(
        'setNextWeek nextWeek:',
        nextWeek.map((d) => d.format('LLLL'))
      );
    const result = nextWeek.map((d) => d.valueOf());
    dispatch(loadWeek(result));
  };
};

export const moveWeek = (number) => {
  LOGGING && console.log('moveWeek called with:', number);
  return (dispatch, getState) => {
    const { week } = getState();
    const newWeek = week.map((day) => moment(day).add(number, 'w')).map((d) => d.valueOf());
    dispatch(loadWeek(newWeek));
  };
};
