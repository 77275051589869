import moment from "moment-timezone";
import toast from "react-hot-toast";
import { TrackAdsEvent } from "src/api/tracker";
import { setCurrentUser, loadCart, resetCart } from "./";
import { setWeeklyMenu } from "./meals";
import { LOGGING, MinSpendForReferralCode } from "../../constants";
import {
  getGoodsKey,
  calcDueServer,
  orderGoodsToCartGoods,
  getDishKey,
} from "../../util/order";
import { GetMealTypeFromWindowStart } from "../../util/time";
import {
  LOAD_ORDERS,
  RESET_ORDERS,
  LOAD_ORDERS_HISTORY_BY_RESTAURANT,
  LOAD_TRACKING,
  LOAD_CHECKOUTS,
  LOAD_CARTS,
  LOAD_CANCELED_ORDERS,
} from "../actionTypes";
import { apiCall } from "../api";

export const loadOrders = (orders) => ({
  type: LOAD_ORDERS,
  orders,
});

export const loadCheckouts = (checkouts) => ({
  type: LOAD_CHECKOUTS,
  checkouts,
});

export const loadCarts = (carts) => ({
  type: LOAD_CARTS,
  carts,
});

export const loadCanceledOrders = (canceledOrders) => ({
  type: LOAD_CANCELED_ORDERS,
  canceledOrders,
});

export const loadTracking = (tracking) => ({
  type: LOAD_TRACKING,
  tracking,
});
export const loadOrderHistoryByRestaurant = (orderHistoryByRestaurant) => ({
  type: LOAD_ORDERS_HISTORY_BY_RESTAURANT,
  orderHistoryByRestaurant,
});
export const resetOrders = () => ({
  type: RESET_ORDERS,
});

export const readAllOrders = () => {
  return (dispatch, getState) => {
    LOGGING && console.log("readAllOrders called");

    return apiCall("get", `/orders`, {}, getState().currentUser)
      .then((result) => {
        LOGGING &&
          console.log("readAllOrders got result from backend:", result);
        const orders = result.map((o) => {
          const { window } = o;
          const { start } = window;
          const isLunch = moment(start).hour() < 12;
          return { ...o, isLunch };
        });
        dispatch(loadOrders(orders));
      })
      .catch((err) => {
        LOGGING && console.log("readAllOrders got err", err);
      });
  };
};

export const readOrders = (isAdmin, lunchTime, earlyDinnerTime, dinnerTime) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("readOrders called with:", {
        isAdmin,
        lunchTime,
        earlyDinnerTime,
        dinnerTime,
        currentUser,
      });
    return apiCall(
      "put",
      "/orders",
      isAdmin
        ? { dinnerTime, earlyDinnerTime, lunchTime }
        : {
            userId: currentUser.user._id,
          },
      getState().currentUser
    )
      .then((result) => {
        LOGGING &&
          console.log("readOrders got result from backend:", {
            result,
            lunchTime,
            earlyDinnerTime,
            dinnerTime,
          });
        const orders = isAdmin
          ? result
              .filter((o) => o.orderTime || !o.cancelTime)
              .sort((a, b) => (a.payTime > b.payTime ? 1 : -1))
              .map((o) => {
                const { window } = o;
                const { start } = window;
                const mealType = GetMealTypeFromWindowStart(start);
                return { ...o, mealType };
              })
          : result;
        LOGGING && console.log("readOrders got orders:", orders);
        dispatch(loadOrders(orders));
      })
      .catch((err) => {
        LOGGING && console.log("readOrders got err", err);
      });
  };
};

export const readCheckouts = (
  isAdmin,
  lunchTime,
  earlyDinnerTime,
  dinnerTime
) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("readCheckouts called with:", {
        isAdmin,
        lunchTime,
        earlyDinnerTime,
        dinnerTime,
        currentUser,
      });
    return apiCall(
      "put",
      "/orders/admin/checkout",
      isAdmin
        ? { dinnerTime, earlyDinnerTime, lunchTime }
        : {
            userId: currentUser.user._id,
          },
      getState().currentUser
    )
      .then((result) => {
        LOGGING &&
          console.log("readCheckouts got result from backend:", {
            result,
            lunchTime,
            earlyDinnerTime,
            dinnerTime,
          });
        const checkouts = isAdmin
          ? result.map((o) => {
              const { window } = o;
              const { start } = window;
              const mealType = GetMealTypeFromWindowStart(start);
              return { ...o, mealType };
            })
          : result;
        LOGGING && console.log("readCheckouts got checkouts:", checkouts);
        dispatch(loadCheckouts(checkouts));
      })
      .catch((err) => {
        LOGGING && console.log("readCheckouts got err", err);
      });
  };
};

export const payCheckout = (checkout) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("payCheckout called with:", {
        checkout,
        currentUser,
      });
    return apiCall(
      "post",
      `/orders/${checkout._id}`,
      {
        delivery: checkout.user.deliveryInfo,
        userId: checkout.user._id,
        byAdmin: true,
      },
      currentUser
    )
      .then(({ paidOrder, deliveryInfo, creditSpent }) => {
        LOGGING &&
          console.log("payCheckout got paidOrder from backend:", {
            paidOrder,
            deliveryInfo,
            creditSpent,
          });
        const { orders, checkouts, canceledOrders } = getState();
        const updatedOrders = [...orders, paidOrder];
        const updatedCheckouts = checkouts.filter((o) => o._id != checkout._id);
        const updatedCanceledOrders = canceledOrders.filter(
          (o) => o._id != checkout._id
        );
        LOGGING &&
          console.log("payCheckout got updated data:", {
            updatedOrders,
            updatedCheckouts,
            updatedCanceledOrders,
          });
        dispatch(loadOrders(updatedOrders));
        dispatch(loadCheckouts(updatedCheckouts));
        dispatch(loadCanceledOrders(updatedCanceledOrders));
      })
      .catch((err) => {
        LOGGING && console.log("payCheckout got err", err);
        throw err;
      });
  };
};

export const finishCheckout = (checkout) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("finishCheckout called with:", {
        checkout,
        currentUser,
      });
    return apiCall(
      "put",
      `/orders/admin/checkout/${checkout._id}`,
      {
        orderOps: currentUser.user._id,
        orderTime: moment().valueOf(),
      },
      getState().currentUser
    )
      .then((result) => {
        LOGGING &&
          console.log("finishCheckout got result from backend:", {
            result,
          });
        const mealType = GetMealTypeFromWindowStart(result.window.start);
        const newOrder = { ...result, mealType };

        const { orders, checkouts, canceledOrders } = getState();
        const updatedOrders = [...orders, newOrder];
        const updatedCheckouts = checkouts.filter((o) => o._id != checkout._id);
        const updatedCanceledOrders = canceledOrders.filter(
          (o) => o._id != checkout._id
        );
        LOGGING &&
          console.log("finishCheckout got updated data:", {
            updatedOrders,
            updatedCheckouts,
            updatedCanceledOrders,
          });
        dispatch(loadOrders(updatedOrders));
        dispatch(loadCheckouts(updatedCheckouts));
        dispatch(loadCanceledOrders(updatedCanceledOrders));
      })
      .catch((err) => {
        LOGGING && console.log("finishCheckout got err", err);
      });
  };
};

export const resumeOrder = (canceledOrder) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("resumeOrder called with:", {
        canceledOrder,
        currentUser,
      });
    return apiCall(
      "post",
      `/orders/resumeOrder/${canceledOrder._id}`,
      {
        orderOps: currentUser.user._id,
        orderTime: moment().valueOf(),
      },
      getState().currentUser
    )
      .then((result) => {
        LOGGING &&
          console.log("resumeOrder got result from backend:", {
            result,
          });
        const mealType = GetMealTypeFromWindowStart(result.window.start);
        const newOrder = { ...result, mealType };

        const { orders, checkouts, canceledOrders } = getState();
        const updatedOrders = [...orders, newOrder];
        const updatedCheckouts = checkouts.filter(
          (o) => o._id != canceledOrder._id
        );
        const updatedCanceledOrders = canceledOrders.filter(
          (o) => o._id != canceledOrder._id
        );
        LOGGING &&
          console.log("resumeOrder got updated data:", {
            updatedOrders,
            updatedCheckouts,
            updatedCanceledOrders,
          });
        dispatch(loadOrders(updatedOrders));
        dispatch(loadCheckouts(updatedCheckouts));
        dispatch(loadCanceledOrders(updatedCanceledOrders));
      })
      .catch((err) => {
        LOGGING && console.log("resumeOrder got err", err);
      });
  };
};

export const resumeOrderNoPayment = (canceledOrder) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("resumeOrderNoPayment called with:", {
        canceledOrder,
        currentUser,
      });
    return apiCall(
      "put",
      `/orders/resumeOrder/${canceledOrder._id}`,
      {
        orderOps: currentUser.user._id,
        orderTime: moment().valueOf(),
      },
      getState().currentUser
    )
      .then((result) => {
        LOGGING &&
          console.log("resumeOrderNoPayment got result from backend:", {
            result,
          });
        const mealType = GetMealTypeFromWindowStart(result.window.start);
        const newOrder = { ...result, mealType };

        const { orders, checkouts, canceledOrders } = getState();
        const updatedOrders = [...orders, newOrder];
        const updatedCheckouts = checkouts.filter(
          (o) => o._id != canceledOrder._id
        );
        const updatedCanceledOrders = canceledOrders.filter(
          (o) => o._id != canceledOrder._id
        );
        LOGGING &&
          console.log("resumeOrderNoPayment got updated data:", {
            updatedOrders,
            updatedCheckouts,
            updatedCanceledOrders,
          });
        dispatch(loadOrders(updatedOrders));
        dispatch(loadCheckouts(updatedCheckouts));
        dispatch(loadCanceledOrders(updatedCanceledOrders));
      })
      .catch((err) => {
        LOGGING && console.log("resumeOrder got err", err);
      });
  };
};

export const readCarts = (isAdmin, lunchTime, earlyDinnerTime, dinnerTime) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("readCarts called with:", {
        isAdmin,
        lunchTime,
        earlyDinnerTime,
        dinnerTime,
        currentUser,
      });
    return apiCall(
      "put",
      "/orders/admin/cart",
      isAdmin
        ? { dinnerTime, earlyDinnerTime, lunchTime }
        : {
            userId: currentUser.user._id,
          },
      currentUser
    )
      .then((result) => {
        LOGGING &&
          console.log("readCarts got result from backend:", {
            result,
            lunchTime,
            earlyDinnerTime,
            dinnerTime,
          });

        const carts = isAdmin
          ? result
              .filter((o) => !o.memberSaving)
              .map((o) => {
                const { window } = o;
                const { start } = window;
                const mealType = GetMealTypeFromWindowStart(start);
                return { ...o, mealType };
              })
          : result.filter((o) => !o.memberSaving);
        LOGGING && console.log("readCarts got carts:", carts);
        dispatch(loadCarts(carts));

        const checkouts = isAdmin
          ? result
              .filter((o) => o.memberSaving != null)
              .map((o) => {
                const { window } = o;
                const { start } = window;
                const mealType = GetMealTypeFromWindowStart(start);
                return { ...o, mealType };
              })
          : result.filter((o) => o.memberSaving != null);
        LOGGING && console.log("readCarts got checkouts:", checkouts);
        dispatch(loadCheckouts(checkouts));
      })
      .catch((err) => {
        LOGGING && console.log("readCarts got err", err);
      });
  };
};

export const readOrdersAndCarts = (
  isAdmin,
  lunchTime,
  earlyDinnerTime,
  dinnerTime
) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("readOrdersAndCarts called with:", {
        isAdmin,
        lunchTime,
        earlyDinnerTime,
        dinnerTime,
        currentUser,
      });
    return apiCall(
      "post",
      "/orders/admin/cart",
      isAdmin
        ? { dinnerTime, earlyDinnerTime, lunchTime }
        : {
            userId: currentUser.user._id,
          },
      currentUser
    )
      .then((result) => {
        let { orders, carts, checkouts, canceledOrders } = result;
        LOGGING &&
          console.log("readOrdersAndCarts got result from backend:", {
            orders,
            carts,
            checkouts,
            canceledOrders,
            lunchTime,
            earlyDinnerTime,
            dinnerTime,
          });

        if (isAdmin) {
          orders = orders.map((o) => {
            const start = o.window?.start || o.meal?.windowStart;
            const mealType = GetMealTypeFromWindowStart(start);
            return { ...o, mealType };
          });
          // carts = carts.map((o) => {
          //   const { window } = o;
          //   const { start } = window;
          //   const mealType = GetMealTypeFromWindowStart(start);
          //   return { ...o, mealType };
          // });
          checkouts = checkouts.map((o) => {
            const start = o.window?.start || o.meal?.windowStart;
            const mealType = GetMealTypeFromWindowStart(start);
            return { ...o, mealType };
          });
          canceledOrders = canceledOrders.map((o) => {
            const start = o.window?.start || o.meal?.windowStart;
            const mealType = GetMealTypeFromWindowStart(start);
            return { ...o, mealType };
          });
        }

        dispatch(loadOrders(orders));
        // dispatch(loadCarts(carts));
        dispatch(loadCheckouts(checkouts));
        dispatch(loadCanceledOrders(canceledOrders));
      })
      .catch((err) => {
        LOGGING && console.log("readOrdersCarts got err", err);
      });
  };
};

export const finishCart = (cart) => {
  return async (dispatch, getState) => {
    const { _id, goods, couponCode, user } = cart;
    LOGGING &&
      console.log("finishCart called with:", {
        _id,
        goods: Object.values(goods),
        cart,
        user,
        couponCode,
      });

    // calc due before call update order
    const newCart = await calcDueServer(user, cart);
    LOGGING && console.log("newCart: ", newCart);
    let serverSideCart = {
      ...newCart,
      goods: Object.values(goods).map((g) => ({ ...g, dish: g.dish._id })),
      couponCode: couponCode?._id,
    };

    const serverRoute =
      serverSideCart.due === 0 && serverSideCart.total > 0
        ? `/orders/free${_id ? `/${_id}` : ""}`
        : `/orders${_id ? `/${_id}` : ""}`;
    const serverAction = _id ? "put" : "post";
    LOGGING &&
      console.log("finishCart called with:", {
        serverAction,
        serverRoute,
        serverSideCart,
      });
    return (
      apiCall(serverAction, serverRoute, serverSideCart, getState().currentUser)
        .then((updatedOrder) => {
          LOGGING && console.log("finishCart got from server:", updatedOrder);

          const { checkouts, carts } = getState();
          const updatedCheckouts = [...checkouts, updatedOrder];
          const updatedCarts = carts.filter((o) => o._id != cart._id);
          dispatch(loadCheckouts(updatedCheckouts));
          dispatch(loadCarts(updatedCarts));
        })
        // Make sure updated order has meal > restaurant > minOrderValue
        .catch((err) => {
          LOGGING && console.log("finishCart got err", err);
        })
    );
  };
};

export const createGroupOrder = ({ mealId, groupOrderType, window }) => {
  return (dispatch, getState) => {
    const { currentUser, cart } = getState();
    LOGGING &&
      console.log("createGroupOrder called with:", {
        currentUser,
        mealId,
        groupOrderType,
        window,
        cart,
      });
    const existingId = cart?.payLoad?._id;
    const method = existingId ? "put" : "post";
    const body = existingId
      ? {
          groupOrderType,
          _id: existingId,
        }
      : {
          groupOrderType,
          window,
        };
    LOGGING &&
      console.log("createGroupOrder calling server with:", {
        method,
        body,
        existingId,
      });
    return apiCall(
      method,
      `/orders/groupOrder/${mealId}/${currentUser?.user?._id}`,
      body,
      getState().currentUser
    )
      .then((groupOrder) => {
        LOGGING &&
          console.log("createGroupOrder got result from backend:", groupOrder);
        dispatch(loadCart({ ...groupOrder, user: { ...currentUser.user } }));
      })
      .catch((err) => {
        LOGGING && console.log("readOrdersByCustomer got err", err);
      });
  };
};

export const checkGroupOrder = ({ mealId }) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    // LOGGING &&
    //   console.log("checkGroupOrder called with:", {
    //     currentUser,
    //     mealId,
    //   });
    if (!currentUser?.user?._id) {
      return Promise.resolve();
    }
    return apiCall(
      "get",
      `/orders/groupOrder/${mealId}/${currentUser?.user?._id}`,
      getState().currentUser
    )
      .then((groupOrder) => {
        // LOGGING &&
        //   console.log("checkGroupOrder got result from backend:", groupOrder);
        if (groupOrder) {
          const { goods } = groupOrder;
          const goodsWithCorrectKey = Object.fromEntries(
            Object.entries(goods).map(([_, good]) => {
              const { dish, selections, addedBy } = good;
              const addedByUserId = addedBy?._id || addedBy;
              return [getDishKey(dish, selections, addedByUserId), good];
            })
          );
          // LOGGING &&
          //   console.log(
          //     "checkGroupOrder got goodsWithCorrectKey:",
          //     goodsWithCorrectKey
          //   );
          dispatch(
            loadCart({ ...groupOrder, goods: { ...goodsWithCorrectKey } })
          );
        }
      })
      .catch((err) => {
        LOGGING && console.log("checkGroupOrder got err", err);
      });
  };
};

export const loadCartForMeal = ({ mealId }) => {
  return (dispatch, getState) => {
    const { currentUser, carts } = getState();
    LOGGING &&
      console.log("loadCartForMeal called with:", {
        currentUser,
        carts,
        mealId,
      });
    const cart = carts.find((c) => c?.meal?._id === mealId);
    LOGGING && console.log("loadCartForMeal found cart:", cart);
    if (cart) {
      const { goods } = cart;
      const goodsWithCorrectKey = Object.fromEntries(
        Object.entries(goods).map(([_, good]) => {
          const { dish, selections, addedBy } = good;
          const addedByUserId = addedBy?._id || addedBy;
          return [getDishKey(dish, selections, addedByUserId), good];
        })
      );
      // LOGGING &&
      //   console.log(
      //     "checkGroupOrder got goodsWithCorrectKey:",
      //     goodsWithCorrectKey
      //   );
      dispatch(loadCart({ ...cart, goods: { ...goodsWithCorrectKey } }));
    }
  };
};
export const checkExistingOrders = ({ mealId }) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    // LOGGING &&
    //   console.log("checkExistingOrders called with:", {
    //     currentUser,
    //     mealId,
    //   });
    return apiCall(
      "get",
      `/meal/${mealId}/${currentUser?.user?._id}`,
      getState().currentUser
    )
      .then((existingOrders) => {
        LOGGING &&
          console.log("checkExistingOrders from backend:", existingOrders);
        // if (groupOrder) {
        //   const { goods } = groupOrder;
        //   const goodsWithCorrectKey = Object.fromEntries(
        //     Object.entries(goods).map(([_, good]) => {
        //       const { dish, selections, addedBy } = good;
        //       const addedByUserId = addedBy?._id || addedBy;
        //       return [getDishKey(dish, selections, addedByUserId), good];
        //     })
        //   );
        //   LOGGING &&
        //     console.log(
        //       "checkGroupOrder got goodsWithCorrectKey:",
        //       goodsWithCorrectKey
        //     );
        //   dispatch(
        //     loadCart({ ...groupOrder, goods: { ...goodsWithCorrectKey } })
        //   );
        // }
      })
      .catch((err) => {
        LOGGING && console.log("checkGroupOrder got err", err);
      });
  };
};

export const checkExistingCart = ({ mealId }) => {
  return (dispatch, getState) => {
    const { currentUser, cart, carts } = getState();
    LOGGING &&
      console.log("checkExistingCart called with:", {
        currentUser,
        mealId,
        cart,
        carts,
      });

    // If guest user, load existing cart from local storage
    if (!currentUser?.user?._id) {
      const existingCart = carts.find(
        (c) => c?.meal?._id?.toString() === mealId?.toString()
      );
      if (!existingCart) {
        dispatch(resetCart());
      } else if (cart?.payLoad?.meal?._id?.toString() === mealId?.toString()) {
        return Promise.resolve();
      } else {
        const { goods } = existingCart;
        const goodsWithCorrectKey = Object.fromEntries(
          Object.entries(goods).map(([_, good]) => {
            const { dish, selections, addedBy } = good;
            const addedByUserId = addedBy?._id || addedBy;
            return [getDishKey(dish, selections, addedByUserId), good];
          })
        );
        dispatch(
          loadCart({ ...existingCart, goods: { ...goodsWithCorrectKey } })
        );
      }
      return Promise.resolve();
    }

    return apiCall(
      "get",
      `/orders/cart/${mealId}/${currentUser?.user?._id}`,
      getState().currentUser
    )
      .then((existingCart) => {
        LOGGING &&
          console.log("checkExistingCart got from server:", {
            existingCart,
          });
        if (!existingCart) {
          dispatch(resetCart());
          return;
        } else {
          const { goods } = existingCart;
          const goodsWithCorrectKey = Object.fromEntries(
            Object.entries(goods).map(([_, good]) => {
              const { dish, selections, addedBy } = good;
              const addedByUserId = addedBy?._id || addedBy;
              return [getDishKey(dish, selections, addedByUserId), good];
            })
          );
          dispatch(
            loadCart({ ...existingCart, goods: { ...goodsWithCorrectKey } })
          );
          return;
        }
      })
      .catch((err) => {
        LOGGING && console.log("checkExistingCart got err", err);
      });
  };
};

export const readGroupOrder = (orderId) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("readGroupOrder called with:", {
        currentUser,
        orderId,
      });
    return apiCall(
      "get",
      `/orders/ReadGroupCart/${orderId}`,
      getState().currentUser
    )
      .then((groupOrder) => {
        LOGGING &&
          console.log("readGroupOrder got result from backend:", groupOrder);
        const { goods } = groupOrder;
        const goodsWithCorrectKey = Object.fromEntries(
          Object.entries(goods).map(([_, good]) => {
            return [
              getDishKey(
                good.dish,
                good.selections,
                good.addedBy?._id || good.addedBy
              ),
              good,
            ];
          })
        );
        dispatch(
          loadCart({ ...groupOrder, goods: { ...goodsWithCorrectKey } })
        );
      })
      .catch((err) => {
        LOGGING && console.log("readGroupOrder got err", err);
      });
  };
};

export const readOrdersByCustomer = () => {
  return (dispatch, getState) => {
    const { currentUser, orders } = getState();
    LOGGING &&
      console.log("readOrdersByCustomer called with:", {
        currentUser,
        orders,
      });
    return apiCall(
      "get",
      `/orders/customer/${currentUser.user._id}`,
      getState().currentUser
    )
      .then((result) => {
        LOGGING &&
          console.log("readOrdersByCustomer got result from backend:", result);
        dispatch(loadOrders([...orders, ...result]));
      })
      .catch((err) => {
        LOGGING && console.log("readOrdersByCustomer got err", err);
      });
  };
};

export const readFutureOrdersByCustomer = () => {
  return (dispatch, getState) => {
    const { currentUser, orders: oldOrders, weeklyMenu } = getState();
    if (!currentUser?.user?._id) {
      return Promise.resolve();
    }
    return apiCall(
      "get",
      `/orders/customer/future/${currentUser.user._id}`,
      getState().currentUser
    )
      .then((result) => {
        const newOrders = result.filter(
          (r) => !oldOrders.find((o) => o._id === r._id) && r.payTime > 0
        );
        const carts = result.filter((r) => r.payTime < 0);
        LOGGING &&
          console.log("readFutureOrdersByCustomer got from backend:", {
            result,
            carts,
          });
        const orders = {};
        result.forEach((i) => {
          orders[i.meal._id] = (orders[i.meal._id] || []).concat({
            _id: i._id,
            meal: i.meal._id,
            payTime: i.payTime,
            isGroupOrder: i.groupOrderType > -1,
          });
        });

        // LOGGING &&
        //   console.log(
        //     "readFutureOrdersByCustomer got orders map:",
        //     weeklyMenu,
        //     orders
        //   );
        let weeklyMenuWithOrders = weeklyMenu.payLoad;
        weeklyMenuWithOrders.forEach((dailyMenu) => {
          dailyMenu.lunch = dailyMenu.lunch.map((meal) => {
            if (orders.hasOwnProperty(meal._id)) {
              meal.order = orders[meal._id].find((o) => o.payTime > 0);
              meal.cart = orders[meal._id].filter((o) => o.payTime < 0);
            }
            return meal;
          });
          dailyMenu.earlyDinner = dailyMenu.earlyDinner.map((meal) => {
            if (orders.hasOwnProperty(meal._id)) {
              meal.order = orders[meal._id].find((o) => o.payTime > 0);
              meal.cart = orders[meal._id].filter((o) => o.payTime < 0);
            }
            return meal;
          });
          dailyMenu.dinner = dailyMenu.dinner.map((meal) => {
            if (orders.hasOwnProperty(meal._id)) {
              meal.order = orders[meal._id].find((o) => o.payTime > 0);
              meal.cart = orders[meal._id].filter((o) => o.payTime < 0);
            }
            return meal;
          });
          return dailyMenu;
        });
        // LOGGING &&
        //   console.log(
        //     "readFutureOrdersByCustomer about to set:",
        //     weeklyMenuWithOrders[0].earlyDinner
        //   );
        dispatch(setWeeklyMenu(weeklyMenuWithOrders));
        const updatedOrders = [...oldOrders, ...newOrders];
        // LOGGING &&
        //   console.log("readFutureOrdersByCustomer about to set:", {
        //     updatedOrders,
        //     newOrders,
        //     oldOrders,
        //   });

        dispatch(loadOrders(updatedOrders));
        dispatch(loadCarts([...carts]));
      })
      .catch((err) => {
        LOGGING && console.log("readFutureOrdersByCustomer got err", err);
      });
  };
};

// this is for new users landing on single meal, building a cart, then going back to home page
export const readFutureOrdersFromRedux = (cart) => {
  return (dispatch, getState) => {
    const { currentUser, weeklyMenu, carts } = getState();
    LOGGING &&
      console.log("readFutureOrdersFromRedux called with:", { cart, carts });

    if (currentUser?.user?._id || !cart?._id) {
      return;
    }

    // I want to push cart into carts, if carts doesn't have it
    if (!carts.find((c) => c?._id?.toString() === cart?._id?.toString())) {
      carts.push(cart);
      dispatch(loadCarts([...carts]));
    }

    let weeklyMenuWithOrders = weeklyMenu.payLoad;
    weeklyMenuWithOrders.forEach((dailyMenu) => {
      dailyMenu.lunch = dailyMenu.lunch.map((meal) => {
        if (meal?._id?.toString() === cart?.meal?._id?.toString()) {
          meal.cart = [cart];
        }
        return meal;
      });
      dailyMenu.earlyDinner = dailyMenu.earlyDinner.map((meal) => {
        if (meal?._id?.toString() === cart?.meal?._id?.toString()) {
          meal.cart = [cart];
        }
        return meal;
      });
      dailyMenu.dinner = dailyMenu.dinner.map((meal) => {
        if (meal?._id?.toString() === cart?.meal?._id?.toString()) {
          meal.cart = [cart];
        }
        return meal;
      });
      return dailyMenu;
    });

    dispatch(setWeeklyMenu(weeklyMenuWithOrders));
  };
};

export const readPastOrdersByCustomer = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "get",
      `/orders/customer/past/${currentUser.user._id}`,
      getState().currentUser
    )
      .then((result) => {
        const { orders: oldOrders } = getState();
        const newOrders = result.filter(
          (r) => !oldOrders.find((o) => o._id === r._id)
        );
        const updatedOrders = [...newOrders, ...oldOrders];
        dispatch(loadOrders(updatedOrders));
      })
      .catch((err) => {
        LOGGING && console.log("readPastOrdersByCustomer got err", err);
      });
  };
};

export const readPastOrdersFromRestaurantByCustomer = () => {
  LOGGING && console.log("readPastOrdersFromRestaurantByCustomer called");
  return (dispatch, getState) => {
    const { currentUser, weeklyMenu } = getState();
    const weekly = weeklyMenu.payLoad
      .map((m) => [
        ...m.dinner.map((d) => d?.restaurant?._id),
        ...m.lunch.map((d) => d?.restaurant?._id),
        ...m.earlyDinner.map((d) => d?.restaurant?._id),
      ])
      .join();
    const restaurantIds = weekly.split(",").filter((r) => r.length > 0);
    // LOGGING &&
    //   console.log("readPastOrdersFromRestaurantByCustomer called with:", {
    //     currentUser,
    //     weekly,
    //     restaurantIds,
    //     // weekly,
    //   });

    return apiCall(
      "post",
      `/orders/customer/restaurant/${currentUser.user._id}`,
      { restaurantIds },
      getState().currentUser
    )
      .then((result) => {
        // LOGGING &&
        //   console.log(
        //     "readPastOrdersFromRestaurantByCustomer got result from backend:",
        //     result
        //   );
        dispatch(loadOrderHistoryByRestaurant({ ...result }));
      })
      .catch((err) => {
        LOGGING &&
          console.log("readPastOrdersFromRestaurantByCustomer got err", err);
      });
  };
};

export const readPastOrdersFromOneRestaurantByCustomer = (
  userId,
  restaurantId
) => {
  return (dispatch, getState) => {
    // const { currentUser, meal } = getState();
    const restaurantIds = [restaurantId];
    // LOGGING &&
    //   console.log("readPastOrdersFromOneRestaurantByCustomer called with:", {
    //     userId,
    //     restaurantIds,
    //   });
    if (!userId || userId === "undefined") {
      return Promise.resolve();
    }

    return apiCall(
      "post",
      `/orders/customer/restaurant/${userId}`,
      { restaurantIds },
      getState().currentUser
    )
      .then((result) => {
        // LOGGING &&
        //   console.log(
        //     "readPastOrdersFromRestaurantByCustomer got result from backend:",
        //     result
        //   );
        dispatch(loadOrderHistoryByRestaurant({ ...result }));
      })
      .catch((err) => {
        LOGGING &&
          console.log("readPastOrdersFromRestaurantByCustomer got err", err);
      });
  };
};

export const readFutureOrdersByCustomerByMeal = () => {
  return (dispatch, getState) => {
    const { currentUser, weeklyMenu } = getState();

    return apiCall(
      "get",
      `/orders/customer/future/${currentUser.user._id}`,
      getState().currentUser
    )
      .then((result) => {
        LOGGING &&
          console.log(
            "readFutureOrdersByCustomerByMeal got result from backend:",
            result
          );

        const orders = {};
        result.forEach((i) => {
          orders[i.meal._id] = (orders[i.meal._id] || []).concat({
            _id: i._id,
            meal: i.meal._id,
            payTime: i.payTime,
            isGroupOrder: i.groupOrderType > -1,
          });
        });

        LOGGING &&
          console.log(
            "readFutureOrdersByCustomerByMeal got orders map:",
            orders
          );
        let weeklyMenuWithOrders = weeklyMenu.payLoad;
        weeklyMenuWithOrders.forEach((dailyMenu) => {
          dailyMenu.lunch = dailyMenu.lunch.map((meal) => {
            if (orders.hasOwnProperty(meal._id)) {
              meal.order = orders[meal._id].find((o) => o.payTime > 0);
              meal.cart = orders[meal._id].find((o) => o.payTime < 0);
            }
            return meal;
          });
          dailyMenu.earlyDinner = dailyMenu.earlyDinner.map((meal) => {
            if (orders.hasOwnProperty(meal._id)) {
              meal.order = orders[meal._id].find((o) => o.payTime > 0);
              meal.cart = orders[meal._id].find((o) => o.payTime < 0);
            }
            return meal;
          });
          dailyMenu.dinner = dailyMenu.dinner.map((meal) => {
            if (orders.hasOwnProperty(meal._id)) {
              meal.order = orders[meal._id].find((o) => o.payTime > 0);
              meal.cart = orders[meal._id].find((o) => o.payTime < 0);
            }
            return meal;
          });
          return dailyMenu;
        });

        dispatch(setWeeklyMenu(weeklyMenuWithOrders));
      })
      .catch((err) => {
        LOGGING &&
          console.log("readPastOrdersFromRestaurantByCustomer got err", err);
      });
  };
};

export const readCurrentMealOrders = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING && console.log("readCurrentMealOrders called with:", currentUser);
    if (!currentUser.isAuthenticated || !currentUser.user.isRestaurant) {
      throw { message: "not authorized!" };
    }
    return apiCall(
      "put",
      `/meals/restaurant/${currentUser.user.restaurant._id}`,
      getState().currentUser
    )
      .then((result) => {
        LOGGING &&
          console.log("readCurrentMealOrders got result from backend:", result);
        let { orders, meal } = result;
        orders.sort((a, b) => (a.payTime > b.payTime ? -1 : 1));
        dispatch(loadOrders(orders));
        return meal;
      })
      .catch((err) => {
        LOGGING && console.log("readCurrentMealOrders got err", err);
      });
  };
};

export const readPastMealOrders = (mealId) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING && console.log("readPastMealOrders called with:", currentUser);
    if (!currentUser.isAuthenticated || !currentUser.user.isRestaurant) {
      throw { message: "not authorized!" };
    }
    return apiCall(
      "post",
      `/meals/restaurant/${currentUser.user.restaurant._id}`,
      { mealId },
      getState().currentUser
    )
      .then((result) => {
        LOGGING &&
          console.log("readPastMealOrders got result from backend:", result);
        let { orders, meal } = result;
        orders.sort((a, b) => (a.payTime > b.payTime ? -1 : 1));
        dispatch(loadOrders(orders));
        return meal;
      })
      .catch((err) => {
        LOGGING && console.log("readPastMealOrders got err", err);
      });
  };
};

export const readMealOrders = (mealId) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("readMealOrders called with:", { mealId, currentUser });
    if (
      !currentUser.isAuthenticated ||
      (!currentUser.user.isAdmin && !currentUser.user.isRestaurant)
    ) {
      throw { message: "not authorized!" };
    }
    return apiCall("get", `/meals/orders/${mealId}`, getState().currentUser)
      .then((result) => {
        LOGGING &&
          console.log("readMealOrders got result from backend:", result);
        const orders = result.sort((a, b) => (a.payTime > b.payTime ? 1 : -1));
        dispatch(loadOrders(orders));
      })
      .catch((err) => {
        LOGGING && console.log("readMealOrders got err", err);
      });
  };
};

export const readUserStats = (userId) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("readUserStats called with:", { userId, currentUser });
    if (!currentUser.isAuthenticated || !currentUser.user.isAdmin) {
      throw { message: "not authorized!" };
    }
    return apiCall("get", `/orders/stats/${userId}`)
      .then((result) => {
        LOGGING &&
          console.log("readUserStats got result from backend:", result);
        return result;
      })
      .catch((err) => {
        LOGGING && console.log("readUserStats got err", err);
      });
  };
};

export const readOrder = (orderId) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING && console.log("readOrder called with orderId:", orderId);
    return apiCall("post", `/orders/details/${orderId}`, {
      userId: currentUser?.user?._id,
    })
      .then((order) => {
        const { goods } = order;
        const clientSideGoods = goods.reduce((result, g) => {
          const { dish, selections, quantity, comment, groupSubOrders } = g;
          const goodsKey = getGoodsKey(result, dish);
          result[goodsKey] = {
            dish,
            selections,
            quantity,
            comment,
            groupSubOrders,
          };
          return result;
        }, {});
        LOGGING && console.log("readOrder got order from backend:", order);
        return { ...order, clientSideGoods };
      })
      .catch((err) => {
        LOGGING && console.log("readOrder got err", err);
      });
  };
};

export const trackOrders = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall("post", `/orders/track`, {
      userId: currentUser?.user?._id,
    })
      .then((tracking) => {
        // LOGGING && console.log("trackOrders got tracking:", tracking);
        dispatch(loadTracking(tracking));
      })
      .catch((err) => {
        LOGGING && console.log("readOrder got err", err);
      });
  };
};

export const fetchRestaurantOrders = (restaurantId) => {
  return (dispatch, getState) => {
    LOGGING &&
      console.log(
        "fetchRestaurantOrders called with restaurantId:",
        restaurantId
      );
    return apiCall(
      "get",
      `/orders/restaurant/${restaurantId}`,
      {},
      getState().currentUser
    )
      .then((result) => {
        LOGGING &&
          console.log("fetchRestaurantOrders got result from backend:", result);
        const orders = result.map((o) => {
          const { window } = o;
          const { start } = window;
          const isLunch = moment(start).hour() < 12;
          return { ...o, isLunch };
        });
        dispatch(loadOrders(orders));
      })
      .catch((err) => {
        LOGGING && console.log("fetchRestaurantOrders got err", err);
      });
  };
};

export const readDriverOrders = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("readDriverOrders called with currentUser:", currentUser);
    return apiCall(
      "get",
      `/orders/driver/${currentUser.user._id}`,
      {},
      getState().currentUser
    )
      .then((result) => {
        LOGGING &&
          console.log("readDriverOrders got result from backend:", result);
        const orders = result.map((o) => {
          const { window } = o;
          const { start } = window;
          const isLunch = moment(start).hour() < 12;
          const hasDeliveryImage = o.deliveryImage !== undefined;
          return { ...o, isLunch, hasDeliveryImage };
        });
        dispatch(loadOrders(orders));
      })
      .catch((err) => {
        LOGGING && console.log("readDriverOrders got err", err);
      });
  };
};

export const readDrivers = (pickUpTime) => {
  return (dispatch, getState) => {
    return apiCall(
      "get",
      "/users/drivers",
      { pickUpTime },
      getState().currentUser
    )
      .then((result) => {
        LOGGING && console.log("readDrivers got result from backend:", result);
        return result;
      })
      .catch((err) => {
        LOGGING && console.log("readDrivers got err", err);
      });
  };
};

export const assignDriverToOrder = (orderIds, driverId, routeId) => {
  return (dispatch, getState) => {
    LOGGING &&
      console.log("assignDriverToOrder called with:", {
        orderIds,
      });
    const { orders } = getState();
    return apiCall(
      "post",
      "/orders/admin/",
      {
        driverId,
        routeId,
        orderIds,
      },
      getState().currentUser
    )
      .then((result) => {
        LOGGING && console.log("assignDriverToOrder got from backend:", result);
        let updatedOrders = {};
        result.forEach((order) => {
          updatedOrders[order._id] = { ...order };
        });
        dispatch(
          loadOrders(
            orders
              .map((o) =>
                updatedOrders[o._id] ? { ...updatedOrders[o._id] } : { ...o }
              )
              .map((o) => {
                const { window } = o;
                const { start } = window;
                const isLunch = moment(start).hour() < 12;
                return { ...o, isLunch };
              })
          )
        );
      })
      .catch((err) => {
        LOGGING && console.log("assignDriverToOrder got err", err);
      });
  };
};

export const assignStopToOrder = (orderIds, stopId) => {
  return (dispatch, getState) => {
    LOGGING &&
      console.log("assignStopToOrder called with:", {
        orderIds,
        stopId,
      });
    const { orders } = getState();
    return apiCall(
      "post",
      "/orders/stop",
      {
        orderIds,
        stopId,
      },
      getState().currentUser
    )
      .then((result) => {
        LOGGING &&
          console.log(
            "assignStopToOrder got updatedOrder from backend:",
            result
          );
        let updatedOrders = {};
        result.forEach((order) => {
          updatedOrders[order._id] = { ...order };
        });
        dispatch(
          loadOrders(
            orders
              .map((o) =>
                updatedOrders[o._id] ? { ...updatedOrders[o._id] } : { ...o }
              )
              .map((o) => {
                const { window } = o;
                const { start } = window;
                const isLunch = moment(start).hour() < 12;
                return { ...o, isLunch };
              })
          )
        );
      })
      .catch((err) => {
        LOGGING && console.log("assignStopToOrder got err", err);
      });
  };
};

export const getDriverTime = (driverId, restaurantAddress, windowStartTime) => {
  return (dispatch, getState) => {
    LOGGING &&
      console.log("getDriverTime called with:", { windowStartTime, driverId });
    return apiCall(
      "put",
      `/orders/driver/${driverId}`,
      {
        restaurantAddress,
        windowStartTime,
      },
      getState().currentUser
    )
      .then((driverOrders) => {
        LOGGING &&
          console.log(
            "getDriverTime got driverOrders from backend:",
            driverOrders
          );
        let updatedDriverOrders = {};
        driverOrders.forEach((order) => {
          updatedDriverOrders[order._id] = { ...order };
        });
        const { orders } = getState();
        const updatedOrders = orders
          .map((o) =>
            updatedDriverOrders[o._id]
              ? { ...updatedDriverOrders[o._id] }
              : { ...o }
          )
          .map((o) => {
            const { window } = o;
            const { start } = window;
            const isLunch = moment(start).hour() < 12;
            return { ...o, isLunch };
          });

        LOGGING &&
          console.log("getDriverTime got from backend:", {
            orders,
            updatedDriverOrders,
            updatedOrders,
          });
        dispatch(loadOrders(updatedOrders));
        LOGGING &&
          console.log(
            "getDriverTime returned",
            driverOrders[driverOrders.length - 1].etaAtRestaurant
          );
        return driverOrders[driverOrders.length - 1].etaAtRestaurant;
      })
      .catch((err) => {
        LOGGING && console.log("getDriverTime got err", err);
      });
  };
};

export const markOrderAsDispatched = (stop, orderIds) => {
  return (dispatch, getState) => {
    LOGGING &&
      console.log("markOrderAsDispatched called with:", {
        stop,
        orderIds,
      });
    const { orders } = getState();
    const now = new Date();
    return apiCall(
      "put",
      "/orders/stop",
      { stop, orderIds, dispatchTime: now.valueOf() },
      getState().currentUser
    )
      .then((updatedOrders) => {
        LOGGING &&
          console.log(
            "markOrderAsDispatched got updatedOrder from backend:",
            updatedOrders
          );

        dispatch(
          loadOrders(
            [...updatedOrders, ...orders.filter((o) => o.stop > stop)].map(
              (o) => {
                const { window } = o;
                const { start } = window;
                const isLunch = moment(start).hour() < 12;
                return { ...o, isLunch };
              }
            )
          )
        );
        return updatedOrders;
      })
      .catch((err) => {
        LOGGING && console.log("markOrderAsDelivered got err", err);
      });
  };
};
export const markPaymentAsPaid = (orderId) => {
  return (dispatch, getState) => {
    LOGGING &&
      console.log("markPaymentAsPaid called with:", {
        orderId,
      });
    return apiCall(
      "put",
      `/orders/admin/groupPayment/${orderId}`,
      {},
      getState().currentUser
    )
      .then((updatedOrder) => {
        LOGGING &&
          console.log(
            "markPaymentAsPaid got updatedOrder from backend:",
            updatedOrder
          );
        return updatedOrder;
      })
      .then((updatedOrder) => {
        LOGGING &&
          console.log(
            "markPaymentAsPaid got updatedOrder from backend:",
            updatedOrder
          );
        const { orders } = getState();
        LOGGING && console.log("orders:", orders);
        dispatch(
          loadOrders(
            orders
              .map((o) => (o._id === orderId ? { ...updatedOrder } : { ...o }))
              .map((o) => {
                const { window } = o;
                const { start } = window;
                const isLunch = moment(start).hour() < 12;
                return { ...o, isLunch };
              })
          )
        );
        return updatedOrder;
      })
      .catch((err) => {
        LOGGING && console.log("markPaymentasPaid got err", err);
      });
  };
};

export const markOrderAsOrdered = (orderId) => {
  return (dispatch, getState) => {
    LOGGING &&
      console.log("markOrderAsOrdered called with:", {
        orderId,
      });
    const { orders, currentUser } = getState();
    const now = new Date();
    return apiCall(
      "put",
      `/orders/admin/${orderId}`,
      {
        orderTime: now.valueOf(),
        orderOps: currentUser.user._id,
      },
      getState().currentUser
    )
      .then((updatedOrder) => {
        LOGGING &&
          console.log(
            "markOrderAsOrdered got updatedOrder from backend:",
            updatedOrder
          );
        dispatch(
          loadOrders(
            orders
              .map((o) => (o._id === orderId ? { ...updatedOrder } : { ...o }))
              .map((o) => {
                const { window } = o;
                const { start } = window;
                const isLunch = moment(start).hour() < 12;
                return { ...o, isLunch };
              })
          )
        );
        return updatedOrder;
      })
      .catch((err) => {
        LOGGING && console.log("markOrderAsOrdered got err", err);
      });
  };
};

export const markOrdersAsRestaurantConfirmed = (orderIds) => {
  return (dispatch, getState) => {
    LOGGING &&
      console.log("markOrdersAsRestaurantConfirmed called with:", {
        orderIds,
      });
    const { orders } = getState();
    const restaurantConfirmTime = new Date().valueOf();
    return apiCall(
      "put",
      `/orders/restaurant`,
      {
        restaurantConfirmTime,
        orderIds,
      },
      getState().currentUser
    )
      .then((result) => {
        LOGGING &&
          console.log(
            "markOrderAsRestaurantConfirmed got updatedOrders from backend:",
            result,
            orderIds
          );

        dispatch(
          loadOrders(
            orders
              .map((o) =>
                orderIds.includes(o._id)
                  ? { ...o, restaurantConfirmTime }
                  : { ...o }
              )
              .map((o) => {
                const { window } = o;
                const { start } = window;
                const isLunch = moment(start).hour() < 12;
                return { ...o, isLunch };
              })
          )
        );
      })
      .catch((err) => {
        LOGGING && console.log("markOrderAsOrdered got err", err);
      });
  };
};

export const markAllOrderAsOrdered = (ordersLeft) => {
  return (dispatch, getState) => {
    LOGGING && console.log("markOrderAsOrdered called with:");

    const { orders, currentUser } = getState();
    const now = new Date();
    return apiCall(
      "put",
      `/orders/admin/markAll`,
      {
        orders: ordersLeft,
        orderTime: now.valueOf(),
        orderOps: currentUser.user._id,
      },
      getState().currentUser
    )
      .then((updatedOrders) => {
        LOGGING &&
          console.log(
            "markOrderAsOrdered got updatedOrder from backend:",
            updatedOrders
          );
        const ids = updatedOrders.map((v) => v._id);
        dispatch(
          loadOrders(
            orders
              .map((o) =>
                ids.indexOf(o._id) !== -1
                  ? {
                      ...updatedOrders.filter(
                        (order) => order._id === o._id
                      )[0],
                    }
                  : { ...o }
              )
              .map((o) => {
                const { window } = o;
                const { start } = window;
                const isLunch = moment(start).hour() < 12;
                return { ...o, isLunch };
              })
          )
        );
      })
      .catch((err) => {
        LOGGING && console.log("markOrderAsOrdered got err", err);
      });
  };
};

export const markOrderAsDelivered = (stop, orderIds) => {
  return (dispatch, getState) => {
    LOGGING &&
      console.log("markOrderAsDelivered called with:", {
        stop,
        orderIds,
      });
    const { orders } = getState();
    const now = new Date();
    return apiCall(
      "put",
      "/orders/stop",
      {
        orderIds,
        deliveryTime: now.valueOf(),
      },
      getState().currentUser
    )
      .then((updatedOrders) => {
        LOGGING &&
          console.log(
            "markOrderAsDelivered got updatedOrders from backend:",
            updatedOrders
          );
        dispatch(
          loadOrders(
            [
              ...updatedOrders,
              ...orders.filter((o) => !updatedOrders.includes(o._id)),
            ].map((o) => {
              const { window } = o;
              const { start } = window;
              const isLunch = moment(start).hour() < 12;
              return { ...o, isLunch };
            })
          )
        );
        return updatedOrders;
      })
      .catch((err) => {
        LOGGING && console.log("markOrderAsDelivered got err", err);
      });
  };
};

export const uploadOrderDeliveryPicture = (orderId, picture) => {
  return (dispatch, getState) => {
    LOGGING &&
      console.log("uploadOrderDeliveryPicture called with:", {
        orderId,
        picture,
      });
    const { orders } = getState();
    return apiCall(
      "post",
      `/orders/deliveryPicture/${orderId}`,
      {
        picture,
      },
      getState().currentUser
    ).then((response) => {
      if (response === "ok") {
        dispatch(
          loadOrders(
            orders
              .map((o) =>
                o._id === orderId ? { ...o, hasDeliveryImage: true } : { ...o }
              )
              .map((o) => {
                const { window } = o;
                const { start } = window;
                const isLunch = moment(start).hour() < 12;
                return { ...o, isLunch };
              })
          )
        );
      }
    });
  };
};

export const cancelOrder = (orderId, isFreeOrder) => {
  return (dispatch, getState) => {
    LOGGING &&
      console.log("cancelOrder called with:", { orderId, isFreeOrder });
    const { orders, currentUser } = getState();
    return apiCall(
      "delete",
      `/orders/${isFreeOrder ? "free/" : ""}${orderId}`,
      {},
      getState().currentUser
    )
      .then((creditSpent) => {
        LOGGING &&
          console.log("cancelOrder got result from creditSpent:", creditSpent);
        dispatch(loadOrders(orders.filter((o) => o._id !== orderId)));
        dispatch(setCurrentUser({ ...currentUser.user, creditSpent }));
      })
      .catch((err) => {
        LOGGING && console.log("cancelOrder got err", err);
      });
  };
};

export const cancelOrderByOps = (orderId, options) => {
  return async (dispatch, getState) => {
    LOGGING && console.log("cancelOrderByOps called with:", { orderId });
    try {
      await apiCall("delete", `/orders/${orderId}`, {}, getState().currentUser);
      options?.success?.();
    } catch (err) {
      LOGGING && console.log("cancelOrderByOps got err", err);
      options?.error?.(err);
    }
  };
};

export const checkBudget = (
  { orderId, windowStart, userId, userGroupId, companyBudget },
  options
) => {
  return async (dispatch, getState) => {
    const { currentUser, cart } = getState();
    LOGGING &&
      console.log("checkBudget called with:", {
        orderId,
        windowStart,
        userId,
        userGroupId,
        companyBudget,
        cart,
        currentUser,
      });

    try {
      await apiCall(
        "post",
        `/orders/budget/${orderId}`,
        { windowStart, userId, userGroupId, companyBudget },
        getState().currentUser
      ).then((updatedCompanyBudget) => {
        LOGGING &&
          console.log(
            "checkBudget got updatedCompanyBudget from server:",
            updatedCompanyBudget
          );
        dispatch(
          loadCart({ ...cart.payLoad, companyBudget: updatedCompanyBudget })
        );
      });
    } catch (err) {
      LOGGING && console.log("checkBudget got err", err);
      options?.error?.(err);
    }
  };
};

//both update and create
export const updateOrder = () => {
  return async (dispatch, getState) => {
    const { cart, currentUser } = getState();
    const { _id, goods, couponCode, groupOrderType, user } = cart.payLoad;
    // LOGGING &&
    //   console.log("updateOrder called with:", {
    //     _id,
    //     goods,
    //     cart,
    //     currentUser,
    //     couponCode,
    //     groupOrderType,
    //   });

    // calc due before call update order, need to use organizer, not currentUser
    const newCart = await calcDueServer(
      user ? { user } : currentUser,
      cart.payLoad
    );
    LOGGING && console.log("newCart: ", newCart);
    let serverSideCart = {
      ...newCart,
      goods: Object.values(goods).map((g) => ({
        ...g,
        dish: g.dish._id,
        addedBy: g?.addedBy?._id || g?.addedBy,
      })),
      couponCode: couponCode?._id,
    };

    if (!serverSideCart.user && currentUser.isAuthenticated) {
      serverSideCart.user = currentUser?.user?._id;
    }
    const serverRoute =
      serverSideCart.due === 0 && serverSideCart.total > 0
        ? `/orders/free${_id ? `/${_id}` : ""}`
        : `/orders${_id ? `/${_id}` : ""}`;
    const serverAction = _id ? "put" : "post";
    // LOGGING &&
    //   console.log("updateOrder calling with:", {
    //     serverAction,
    //     serverRoute,
    //     serverSideCart,
    //   });
    return (
      apiCall(serverAction, serverRoute, serverSideCart, getState().currentUser)
        .then((order) => {
          LOGGING && console.log("updateOrder got from server:", order);
          // if (order?.isExistingOrder) {
          //   toast.error("Found an existing cart, please update it instead.");
          // }
          const updatedCart = {
            ...newCart,
            ...order,
            goods: orderGoodsToCartGoods(order.goods),
          };
          dispatch(loadCart(updatedCart));
          return updatedCart; //no op
        })
        // Make sure updated order has meal > restaurant > minOrderValue
        .catch((err) => {
          LOGGING && console.log("updatedOrder got err", err);
          throw err;
        })
    );
  };
};

export const updateOrderDue = () => {
  return async (dispatch, getState) => {
    const { cart, currentUser } = getState();
    LOGGING &&
      console.log("updateOrderDue called with:", {
        cart,
        currentUser,
      });
    const {
      _id,
      meal,
      deliveryZone,
      user,
      goods,
      credit,
      couponCode,
      groupOrderType,
      paymentIntentId,
      doordashComparables,
    } = cart.payLoad;

    const cartItems = Object.values(goods).map((g) => {
      return {
        quantity: g.quantity,
        dish: g.dish,
        price: g.price,
        selectedSelections: g.selections
          .map((selection) => {
            return selection.selectedItems;
          })
          .flat(),
      };
    });

    let tip = cart?.payLoad?.tips;
    let tipRate = cart?.payLoad?.tipRate;
    let tipCustom = cart?.payLoad?.tipCustom;
    if (!tip && !tipRate && !(tipCustom !== null && tipCustom >= 0)) {
      tipRate = 0.15;
    }

    const payLoad = {
      userId: cart?.user?._id || currentUser?.user?._id,
      userGroup: currentUser?.user?.userGroup,
      mealId: meal?._id || meal,
      orderId: _id,
      cartItems: cartItems,
      credit,
      couponCode: couponCode?._id,
      tip,
      tipCustom,
      tipRate,
      isMember: user?.membership?.isActive,
      doordashComparables,
      goods: Object.values(goods).map((g) => ({
        ...g,
        dish: g.dish._id,
        addedBy: g?.addedBy?._id || g?.addedBy,
      })),
      groupOrderType,
      paymentIntentId,
    };

    LOGGING &&
      console.log("updateOrderDue called server with payload:", payLoad);

    return (
      apiCall(
        "post",
        `/orders/updateOrderDue/${_id}`,
        payLoad,
        getState().currentUser
      )
        .then((order) => {
          LOGGING && console.log("updateOrderDue got from server:", order);
          // if (order?.isExistingOrder) {
          //   toast.error("Found an existing cart, please update it instead.");
          // }
          const updatedCart = {
            meal,
            deliveryZone,
            ...order,
            goods: orderGoodsToCartGoods(order.goods),
          };
          dispatch(loadCart(updatedCart));
          return updatedCart; //no op
        })
        // Make sure updated order has meal > restaurant > minOrderValue
        .catch((err) => {
          LOGGING && console.log("updatedOrderDue got err", err);
          throw err;
        })
    );
  };
};

export const updateOrderOnCart = () => {
  return async (dispatch, getState) => {
    const { cart, currentUser } = getState();
    const { _id, goods, couponCode, groupOrderType, user, tips } = cart.payLoad;
    LOGGING &&
      console.log("updateOrderOnCart called with:", {
        _id,
        goods,
        cart,
        currentUser,
        couponCode,
        groupOrderType,
      });

    // calc due before call update order, need to use organizer, not currentUser
    const newCart = await calcDueServer(
      user ? { user } : currentUser,
      cart.payLoad
    );
    LOGGING && console.log("newCart: ", newCart);
    let serverSideCart = {
      ...newCart,
      goods: Object.values(goods).map((g) => ({
        ...g,
        dish: g.dish._id,
        addedBy: g?.addedBy?._id || g?.addedBy,
      })),
      couponCode: couponCode?._id,
    };

    if (!serverSideCart.user && currentUser.isAuthenticated) {
      serverSideCart.user = currentUser?.user?._id;
    }

    const serverRoute = _id ? `/orders/update/${_id}` : `/orders`;
    const serverAction = _id ? "put" : "post";
    // LOGGING &&
    //   console.log("updateOrderCart calling with:", {
    //     serverAction,
    //     serverRoute,
    //     serverSideCart,
    //   });
    return (
      apiCall(serverAction, serverRoute, serverSideCart, getState().currentUser)
        .then((order) => {
          LOGGING && console.log("updateOrderOnCart got from server:", order);
          const updatedCart = {
            ...newCart,
            ...order,
            goods: orderGoodsToCartGoods(order.goods),
          };
          dispatch(loadCart(updatedCart));
          return updatedCart; //no op
        })
        // Make sure updated order has meal > restaurant > minOrderValue
        .catch((err) => {
          LOGGING && console.log("updatedOrderOnCart got err", err);
          throw err;
        })
    );
  };
};

export const updateOrderOnMeal = () => {
  return async (dispatch, getState) => {
    const { cart, currentUser } = getState();
    const { _id } = cart.payLoad;

    LOGGING &&
      console.log("updateOrderOnMeal called with:", { cart, currentUser });
    const serverRoute = _id ? `/orders/update/${_id}` : `/orders/create`;

    const sum = cart?.payLoad?.sum;
    const window = cart?.payLoad?.window;
    const meal = cart?.payLoad?.meal?._id || cart?.payLoad?.meal;
    const customerId = cart?.payLoad?.customerId;
    const requesterVersion = cart?.payLoad?.requesterVersion;
    const groupOrderType = cart?.payLoad?.groupOrderType;

    const payLoad = {
      sum,
      window,
      meal,
      customerId,
      requesterVersion,
      groupOrderType,
      user: currentUser?.user?._id,
      goods: Object.values(cart.payLoad.goods).map((g) => ({
        ...g,
        dish: g.dish._id,
        addedBy: g?.addedBy?._id || g?.addedBy,
      })),
    };

    return (
      apiCall("post", serverRoute, payLoad, getState().currentUser)
        .then((order) => {
          LOGGING && console.log("updateOrderOnMeal got from server:", order);
          // if (order?.isExistingOrder) {
          //   toast.error("Found an existing cart, please update it instead.");
          // }
          const updatedCart = {
            ...cart.payLoad,
            ...order,
            goods: orderGoodsToCartGoods(order.goods),
          };
          dispatch(loadCart(updatedCart));
          return updatedCart; //no op
        })
        // Make sure updated order has meal > restaurant > minOrderValue
        .catch((err) => {
          LOGGING && console.log("updatedOrderOnMeal got err", err);
          throw err;
        })
    );
  };
};

export const payCartWithNewCard = (card, delivery, saveCard) => {
  LOGGING && console.log("payCartWithNewCard called with card:", card);
  const { number, cvc, exp_month, exp_year } = card;

  return (dispatch, getState) => {
    const { cart, currentUser } = getState();
    const { _id: orderId } = cart.payLoad;
    LOGGING &&
      console.log("payCartWithNewCard got", {
        card,
        orderId,
        delivery,
        saveCard,
      });

    return apiCall(
      "post",
      `/orders/${orderId}`,
      {
        card: { number, cvc, exp_month, exp_year: exp_year.slice(-2) },
        delivery,
        saveCard,
        userId: currentUser.user._id,
      },
      getState().currentUser
    )
      .then(({ paidOrder, stripeInfo, deliveryInfo }) => {
        LOGGING &&
          console.log("payCartWithNewCard got paidOrder from backend:", {
            paidOrder,
            deliveryInfo,
            stripeInfo,
          });
        // dispatch(loadCart(paidOrder));
        dispatch(resetCart());
        if (currentUser.isAuthenticated) {
          dispatch(
            setCurrentUser({ ...currentUser.user, stripeInfo, deliveryInfo })
          );
        }
      })
      .catch((err) => {
        LOGGING && console.log("payCartWithNewCard got err", err);
        let error = err;
        if (!err.param) {
          if (err.code === "incorrect_number" || err.code === "card_declined") {
            error.param = "number";
          }
          if (err.code === "invalid_cvc") {
            error.param = "cvc";
          }
          if (err.code === "invalid_expiry_month") {
            error.param = "exp_month";
          }
          if (err.code === "invalid_expiry_year") {
            error.param = "exp_year";
          }
        }
        throw error;
      });
  };
};

export const paidCartWithPaypal = (captureId, deliveryInfo) => {
  LOGGING &&
    console.log("paidCartWithPaypal called with:", captureId, deliveryInfo);
  return (dispatch, getState) => {
    const { cart, currentUser, pixel } = getState();
    const { _id: orderId } = cart.payLoad;
    LOGGING &&
      console.log("paidCartWithPaypal got", {
        orderId,
        cart,
        pixel,
      });

    // Insert Facebook Pixel tracking here
    if (window.fbq && pixel?.fbclid && !pixel?.isConverted) {
      LOGGING && console.log("pay order by paypal click is tracked by fbq");
      window.fbq("track", "Purchase", {
        content_name: `${cart?.payLoad?.meal?.restaurant?.name} checkout to pay order by paypal`,
        content_category: "pageCheckout",
        value: cart?.payLoad?.total,
        currency: "USD",
      });

      // Track Ads Event
      TrackAdsEvent(pixel?.fbclid, {
        source: pixel?.source,
        eventCode: "Purchase",
        page: "pageCheckout",
        contentName: `${cart?.payLoad?.meal?.restaurant?.name} checkout to pay order by paypal`,
        orderId: orderId,
      });
    }

    return apiCall(
      "post",
      `/orders/paidwithpaypal/${orderId}`,
      {
        captureId,
        deliveryInfo,
      },
      getState().currentUser
    )
      .then(({ order, user }) => {
        LOGGING &&
          console.log("paidCartWithPaypal got result from backend:", {
            order,
            user,
          });
        dispatch(resetCart());
        if (currentUser.isAuthenticated) {
          const { deliveryInfo, creditSpent } = user;
          dispatch(
            setCurrentUser({
              ...currentUser.user,
              deliveryInfo,
              creditSpent,
            })
          );
        }
      })
      .catch((err) => {
        LOGGING && console.log("paidCartWithPaypal got err", err);
        throw err;
      });
  };
};

export const payCartWithExistingCard = (delivery) => {
  LOGGING &&
    console.log("payCartWithExistingCard called with delivery:", delivery);
  return (dispatch, getState) => {
    const { cart, currentUser } = getState();
    const { _id: orderId } = cart.payLoad;

    LOGGING &&
      console.log("payCartWithExistingCard got", { orderId, delivery });

    return apiCall(
      "post",
      `/orders/${orderId}`,
      {
        delivery: delivery,
        userId: currentUser.user._id,
      },
      getState().currentUser
    )
      .then(({ paidOrder, deliveryInfo, creditSpent }) => {
        LOGGING &&
          console.log("payCartWithExistingCard got paidOrder from backend:", {
            paidOrder,
            deliveryInfo,
            creditSpent,
          });
        dispatch(resetCart());
        dispatch(
          setCurrentUser({ ...currentUser.user, deliveryInfo, creditSpent })
        );
        return;
      })
      .catch((err) => {
        LOGGING && console.log("payCartWithExistingCard got err", err);
        throw err;
      });
  };
};

export const changePaidOrder = (updatedOrder) => {
  LOGGING &&
    console.log("changePaidOrder called with updatedOrder:", updatedOrder);

  return (dispatch, getState) => {
    const { currentUser, orders } = getState();
    const {
      _id,
      // content
      goods,
      comment,
      sum,
      tax,
      tips,
      total,
      discount,

      // contacts
      email,
      phone,
      name,
      address,

      // payment
      credit,
      due,

      // paypal
      paypalCaptureId,
    } = updatedOrder;

    return apiCall("post", `/orders/modify/${_id}`, {
      goods,
      comment,
      sum,
      tax,
      tips,
      total,
      discount,
      couponCode: updatedOrder.couponCode?._id,
      email,
      phone,
      name,
      address,
      credit,
      due,
      paypalCaptureId,
      user: currentUser?.user?._id,
    })
      .then(({ updatedUser, updatedOrder }) => {
        LOGGING &&
          console.log("changePaidOrder got paidOrder from backend:", {
            updatedUser,
            updatedOrder,
          });
        const updatedOrders = orders.map((o) =>
          o._id === _id
            ? {
                ...o,
                goods,
                comment,
                sum,
                tax,
                tips,
                total,
                discount,
                email,
                phone,
                name,
                address,
                credit,
                due,
                ...updatedOrder,
              }
            : { ...o }
        );
        dispatch(loadOrders(updatedOrders));
        dispatch(setCurrentUser({ ...currentUser.user, ...updatedUser }));
        return updatedOrder;
      })
      .catch((err) => {
        LOGGING && console.log("changePaidOrder got err", err);
        throw err;
      });
  };
};

export const submitFreeMeal = (delivery) => {
  LOGGING && console.log("submitFreeMeal called with delivery:", delivery);
  return (dispatch, getState) => {
    const { cart, currentUser } = getState();
    const { _id: orderId } = cart.payLoad;

    LOGGING && console.log("submitFreeMeal got", { orderId, delivery });

    return apiCall(
      "post",
      `/orders/free/${orderId}`,
      {
        delivery: delivery,
        userId: currentUser.user._id,
      },
      getState().currentUser
    )
      .then(({ paidOrder, deliveryInfo, creditSpent }) => {
        LOGGING &&
          console.log("submitFreeMeal got paidOrder from backend:", {
            paidOrder,
            deliveryInfo,
            creditSpent,
          });
        dispatch(resetCart());
        dispatch(
          setCurrentUser({ ...currentUser.user, deliveryInfo, creditSpent })
        );
      })
      .catch((err) => {
        LOGGING && console.log("submitFreeMeal got err", err);
        throw err;
      });
  };
};

export const payCartWithCompanyCard = () => {
  LOGGING && console.log("payCartAsGroupOrder called");
  return (dispatch, getState) => {
    const { cart, currentUser } = getState();
    const { _id: orderId } = cart.payLoad;

    LOGGING && console.log("payCartWithCompanyCard got", { orderId });

    // return apiCall(
    //   'post',
    //   `/orders/group/${orderId}`,
    //   {
    //     userId: currentUser.user._id,
    //   },
    //   getState().currentUser
    // )
    return apiCall(
      "post",
      `/orders/withCompanyCard/${orderId}`,
      getState().currentUser
    )
      .then(({ paidOrder }) => {
        LOGGING &&
          console.log("payCartWithCompanyCard got paidOrder from backend:", {
            paidOrder,
          });
        dispatch(resetCart());
      })
      .catch((err) => {
        LOGGING && console.log("payCartWithCompanyCard got err", err);
        throw err;
      });
  };
};

export const addUserToOrder = () => {
  return (dispatch, getState) => {
    const { cart, currentUser } = getState();
    const { _id } = cart.payLoad;
    LOGGING &&
      console.log("addUserToOrder called with:", { cart, currentUser });
    return apiCall(
      "put",
      `/orders/${_id}`,
      {
        user: currentUser.user._id,
      },
      getState().currentUser
    )
      .then((order) => {
        LOGGING &&
          console.log("addUserToOrder got result from backend:", order);
        dispatch(loadCart(order));
      })
      .catch((err) => {
        LOGGING && console.log("addUserToOrder got err", err);
      });
  };
};

export const deleteCardFromOrder = () => {
  return (dispatch, getState) => {
    const { cart, currentUser } = getState();
    const { _id: orderId } = cart.payLoad;
    LOGGING && console.log("deleteCardFromOrder called with orderId:", orderId);
    return apiCall(
      "delete",
      `/orders/card/${orderId}`,
      {},
      getState().currentUser
    )
      .then(({ updatedOrder, stripeInfo }) => {
        LOGGING &&
          console.log("deleteCardFromOrder got result from backend:", {
            updatedOrder,
            stripeInfo,
          });
        dispatch(loadCart(updatedOrder));
        dispatch(setCurrentUser({ ...currentUser.user, stripeInfo }));
      })
      .catch((err) => {
        LOGGING && console.log("deleteCardFromOrder got err", err);
      });
  };
};

export const startFromRestaurant = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "post",
      `/orders/driver/${currentUser.user._id}`,
      {},
      getState().currentUser
    )
      .then(() => {
        LOGGING && console.log("startFromRestaurant got result from backend:");
      })
      .catch((err) => {
        LOGGING && console.log("startFromRestaurant got err", err);
      });
  };
};

export const refundOrder = (orderId, refundAmount) => {
  LOGGING &&
    console.log("refundOrder is called with: ", { orderId, refundAmount });
  return (dispatch, getState) => {
    let { meals } = getState();
    LOGGING && console.log("refundOrder has meals: ", meals);
    return apiCall(
      "post",
      `/orders/refund/${orderId}`,
      { refundAmount },
      getState().currentUser
    ).catch((err) => {
      LOGGING && console.log("refundOrder got err", err);
    });
  };
};

export const orderCancelItem = (orderId, index, dishId, cancelQuantity) => {
  LOGGING &&
    console.log("orderCancelItem is called with: ", {
      orderId,
      index,
      dishId,
      cancelQuantity,
    });
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/orders/cancelItem/${orderId}`,
      { index, dishId, cancelQuantity },
      getState().currentUser
    ).catch((err) => {
      LOGGING && console.log("orderCancelItem got err", err);
    });
  };
};

export const giveOrderFeedback = (orderId, feedback) => {
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/orders/feedback/${orderId}`,
      { feedback },
      getState().currentUser
    );
  };
};

export const saveOrderFeedbackComment = (orderId, comment) => {
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/orders/comment/${orderId}`,
      { comment },
      getState().currentUser
    );
  };
};

export const updateOrderOpsNote = (orderId, opsNote) => {
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/orders/opsNote/${orderId}`,
      { opsNote },
      getState().currentUser
    );
  };
};

export const emailRestaurant = (orders) => {
  LOGGING && console.log("inside emailRestaurant");
  const ordersLeft = orders.filter(
    (order) => !order.hasOwnProperty("orderOps")
  );
  var res = null;

  if (ordersLeft.length > 0) {
    let emailNum =
      Math.max.apply(
        Math,
        orders.map((o) => o?.emailOps?.emailNum || 0)
      ) + 1;
    LOGGING && console.log(orders.map((o) => o?.emailOps?.emailNum || 0));
    for (let order in orders) {
      orders[order].emailNum = emailNum;
    }
    let orderNum = Math.max.apply(
      Math,
      orders.map((o) => o?.emailOps?.orderNum || 0)
    );
    LOGGING && console.log(orders.map((o) => o?.emailOps?.orderNum || 0));
    console.log(
      orders.map((o) => (o.emailOps.orderNum == null ? 0 : o.emailOps.orderNum))
    );
    for (let order in orders) {
      orders[order].orderNum = orderNum;
    }
    res = emailRestaurantCall(ordersLeft, emailNum, orderNum);
  }
  LOGGING && console.log("exiting email restaurant:", orders);
  return res;
};

const emailRestaurantCall = (ordersLeft, emailNum, orderNum) => {
  return (dispatch, getState) => {
    apiCall(
      "POST",
      `/meals/emailRestaurant`,
      { ordersLeft, emailNum, orderNum },
      getState().currentUser
    );
  };
};
export const readGroupOrders = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("readGroupOrders called with currentUser:", currentUser);
    return apiCall(
      "put",
      "/orders/group",
      { userId: currentUser.user._id },
      getState().currentUser
    );
  };
};
export const checkPreorderInventoryAvailablity = () => {
  return (dispatch, getState) => {
    const { cart, currentUser } = getState();
    const { _id: orderId } = cart.payLoad;
    LOGGING &&
      console.log(
        "checkPreorderInventoryAvailablity called with currentUser:",
        currentUser
      );
    return apiCall(
      "get",
      `/orders/checkPreorderInventoryAvailablity/${orderId}`,
      { userId: currentUser.user._id },
      getState().currentUser
    );
  };
};
export const checkOrderCount = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("checkOrderCount called with currentUser:", currentUser);
    return apiCall(
      "get",
      `/orders/count/${currentUser?.user?._id}`,
      getState().currentUser
    );
  };
};

export const checkOrderTotal = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("checkOrderTotal called with currentUser:", currentUser);
    return apiCall(
      "get",
      `/orders/total/${currentUser?.user?._id}`,
      getState().currentUser
    ).then((result) => {
      LOGGING && console.log("checkOrderTotal got from backend: ", result);
      return result > MinSpendForReferralCode;
    });
  };
};

export const directOrder = () => {
  return async (dispatch, getState) => {
    const { currentUser, cart } = getState();
    const { goods, couponCode, user } = cart.payLoad;
    LOGGING && console.log("directOrder called with:", currentUser, cart);

    const newCart = await calcDueServer(
      user ? { user } : currentUser,
      cart?.payLoad
    );
    LOGGING && console.log("directOrder got newCart: ", newCart);
    let serverSideCart = {
      ...newCart,
      goods: Object.values(goods).map((g) => ({
        ...g,
        dish: g.dish._id,
        addedBy: g?.addedBy?._id || g?.addedBy,
      })),
      couponCode: couponCode?._id,
    };

    return apiCall(
      "post",
      `/orders/direct/${serverSideCart?._id}`,
      serverSideCart
    ).then(({ paidOrder, deliveryInfo, creditSpent }) => {
      LOGGING &&
        console.log("directOrder got paidOrder from backend:", {
          paidOrder,
          deliveryInfo,
          creditSpent,
        });
      dispatch(resetCart());
      dispatch(
        setCurrentUser({ ...currentUser.user, deliveryInfo, creditSpent })
      );
      return;
    });
  };
};

export const placeOrderFromDraftedCart = (draftedCart) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log(
        "placeOrderFromDraftedCart called with currentUser:",
        currentUser
      );
    return apiCall(
      "post",
      "/orders/placeOrderFromDraftedCart",
      draftedCart,
      getState().currentUser
    )
      .then((result) => {
        const { orders } = getState();
        dispatch(loadOrders([...orders, result]));
        return result;
      })
      .catch((err) => {
        LOGGING && console.log("placeOrderFromDraftedCart got err", err);
        throw err;
      });
  };
};
