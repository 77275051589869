import { createRef, useEffect, useState } from "react";
import { useRef } from "react";
import moment from "moment-timezone";
import { useSelector, useDispatch, useStore } from "react-redux";
import { LOGGING } from "src/constants";
import { readHolidayMenus } from "src/store/actions";
import styled from "styled-components";
import { actionTransparent, CenteredByColumn, TextTitle, textUpper } from "./components/Shared";
import { GetMealLink } from "../../constants";
import { RootState } from "../../store";

const MealContainer = styled(CenteredByColumn)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const RestaurantName = styled(TextTitle)``;
const MealImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;
const MealDate = styled.div`
  ${textUpper}
`;
const HolidaMeal = ({ meal }) => {
  const { windowStart, restaurant } = meal;
  const mealDate = moment(windowStart).format("YYYY-MM-DD");
  const { name, heroImages, heroImage } = restaurant;
  const link = GetMealLink(meal);
  return (
    // make meal container a link to the meal
    <MealContainer href={link}>      
      <MealImage src={heroImage || heroImages?.[0] || heroImage} />
      <RestaurantName>{name}</RestaurantName>
      <MealDate>{mealDate}</MealDate>
    </MealContainer>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Carousel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;  
  position: sticky;  
  top: 0;
  button {
    ${actionTransparent}
    margin: 10px;
  }
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: "auto";
  max-height: "100vh";
`;
const PageHoliday = () => {
  const holiday = useSelector((state: RootState) => state.holiday);
  const [loading, setLoading] = useState(true);
  const [uniqueDates, setUniqueDates] = useState([]);
  const dispatch = useDispatch();
  const store = useStore();
  const listRef = useRef<HTMLDivElement>(null);
  const dateRefs = useRef<HTMLDivElement[]>([]);

  const scrollToDate = (index: number) => {
    const date = dateRefs.current[index];
    console.log("listRef.current", listRef.current.offsetTop);
    console.log("scrollToDate", date.offsetTop);
    if (date?.offsetTop) {
      console.log("scrolling");
      window.scrollTo({
        top: date.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (!holiday?.meals || loading) {
      readHolidayMenus()(dispatch, store.getState).then((holiday) => {
        setLoading(false);
        // console.log("PageHoliday got holiday", holiday);
      });
    }
    const dates = holiday?.meals?.map((meal: any) =>
      moment(meal.windowStart).format("YYYY-MM-DD")
    );
    LOGGING && console.log("PageHoliday useEffect dates", dates);
    setUniqueDates(Array.from(new Set(dates)));
  }, [holiday]);

  LOGGING && console.log("PageHoliday uniqueDates", uniqueDates);
  return (
    <Container>
      <Carousel>
        {uniqueDates.map((date, index) => (
          <button
            key={index}
            onClick={(e) => {
              e.preventDefault();
              scrollToDate(index);
            }}
          >
            {date}
          </button>
        ))}
      </Carousel>
      <List ref={listRef}>
        {uniqueDates.map((date, index) => (
          <div key={date} ref={(el) => (dateRefs.current[index] = el!)}>
            <h2>{date}</h2>
            {holiday?.meals
              ?.filter(
                (meal: any) =>
                  moment(meal.windowStart).format("YYYY-MM-DD") === date
              )
              .map((meal: any) => (
                <HolidaMeal key={meal._id} meal={meal} />
              ))}
          </div>
        ))}
      </List>
    </Container>
  );
};
export default PageHoliday;
