import './WeeklyCalendar.scss';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';

const WeeklyCalendar = ({ week, onMove }) => (
  <div className="weekly-calendar">
    <div className="month">{`${moment(week[0]).format('MMM YYYY')}`}</div>
    <div className="days">
      <button className="move-week" onClick={onMove.bind(this, -1)}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      {week.map((day, index) => (
        <div className="day" key={index}>
          <div className="day-of-week">{moment(day).format('ddd')[0]}</div>
          <div className="day-of-month">{moment(day).format('D')}</div>
        </div>
      ))}
      <button className="move-week" onClick={onMove.bind(this, 1)}>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  </div>
);

export default WeeklyCalendar;
