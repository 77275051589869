import { LOAD_MENUS } from '../actionTypes';
import { apiCall } from '../api';

const LOGGING = true;
export const loadMenus = (menus) => ({
  type: LOAD_MENUS,
  menus,
});

export const fetchMenus = () => {
  LOGGING && console.log('fetchMenus');

  return (dispatch, getState) => {
    return apiCall('GET', `/menus`, {}, getState().currentUser)
      .then((menus) => {
        LOGGING && console.log('fetchMenus got menus', menus);
        const menusWithSum = menus.map((menu) => {
          const { dishes } = menu;
          LOGGING && console.log('fetchMenus got dishes', dishes);
          const sum = dishes.reduce((a, b) => a + b.price, 0);
          LOGGING && console.log('fetchMenus got sum', sum);
          return { ...menu, sum };
        });
        dispatch(loadMenus(menusWithSum));
      })
      .catch((err) => {
        LOGGING && console.log('fetchMenus got err', err);
      });
  };
};
