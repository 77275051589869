import React, { ReactElement, useCallback } from 'react';
import Select from 'react-dropdown-select';
import { DishType } from 'src/api/dish';

type DishTypeSelectorProps = {
  selectedDishType: DishType;
  onSelectDishType: (dishType: DishType) => void;
};

export const DishTypeSelector: React.FC<DishTypeSelectorProps> = ({
  selectedDishType,
  onSelectDishType,
}): ReactElement => {
  const changeDishType = useCallback(
    (selectedDishTypes) => {
      onSelectDishType(selectedDishTypes[0].value);
    },
    [onSelectDishType]
  );
  return (
    <Select
      options={Object.values(DishType)
        .filter((dishType) => dishType !== DishType.UNKNOWN)
        .map((dishType) => ({ value: dishType }))}
      onChange={changeDishType}
      labelField="value"
      valueField="value"
      values={[{ value: selectedDishType }]}
    />
  );
};
