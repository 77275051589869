import './RestaurantList.scss';
import React from 'react';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { Button } from 'src/components/Button';
import { Flex } from 'src/components/Layout';
import { LOGGING, Back, RestaurantLevel, DeliveryZonesSelector } from './';
import { MealMenuAdmin } from './mealMenu/MealMenuAdmin';

const RestaurantItem = ({ restaurant, onViewDetails, restaurantIndex, selected }) => (
  <button
    className={`restaurant-item ${selected ? 'selected' : ''}`}
    onClick={onViewDetails.bind(this, restaurantIndex)}
  >
    <FontAwesomeIcon icon={faCheck} />
    <span className="restaurant-name">{restaurant.name && restaurant.name.split(',')[0]}</span>
    <div className="restaurant-last-selected-time">
      {restaurant.lastMeal?.windowStart
        ? moment(restaurant.lastMeal.windowStart).format('MM/DD HH:mm')
        : '-'}
    </div>
  </button>
);

const RestaurantList = ({
  restaurants,
  onClose,
  day,
  isLunch,
  mealId,
  onViewRestaurant,
  onCloseRestaurant,
  selectedRestaurant,
  dishes,
  selectedDishes,
  onToggleDish,
  onSelectAllDishes,
  onDeselectAllDishes,
  onSaveMeal,
  showRestaurantDetails,
  supportEarlyDelivery,
  onToggleSupportEarlyDelivery,
  selectedPreOrderedDishes,
  onChangePreOrderQuantity,
  categoriesForRestaurant,
  onSelectAllDishesInCategory,
  onDeselectAllDishesInCategory,
  deliveryZones,
  onSelectDeliveryZone,
  selectedDeliveryZone,
  onEditDish,
  mealNotes,
  onEditMealNotes,
  onSetHeroImage,
}) => {
  LOGGING &&
    console.log('RestaurantList rendering with:', {
      day,
      restaurants,
      selectedRestaurant,
      showRestaurantDetails,
      supportEarlyDelivery,
      categoriesForRestaurant,
      deliveryZones,
      selectedDeliveryZone,
      mealNotes,
      dishes,
    });
  // const [notes, setNotes] = useState('');
  const restaurant = selectedRestaurant >= 0 ? restaurants[selectedRestaurant] : null;
  return (
    <div className="list restaurants">
      <div className="list-title">
        <div className="restaurant-summary">
          <div className="restaurant-name">{`${
            restaurant ? restaurant.name.split(',')[0] : ''
          }`}</div>
          {restaurant && restaurant.preOrderOnly ? (
            <div className="restaurant-preorder">pre-order only</div>
          ) : null}
        </div>
        <div className="save-dishes">
          <Flex align="center" gap={12}>
            <input placeholder="notes" value={mealNotes} onChange={onEditMealNotes} />
            {/* <Button variant="secondary" onClick={(e) => onSaveMeal(e, true, mealNotes)}>
              Save Draft
            </Button> */}
            <Button onClick={(e) => onSaveMeal(e, mealId)}>Save</Button>
          </Flex>
        </div>
        <Back onClick={onClose} />
      </div>
      {showRestaurantDetails ? (
        <React.Fragment>
          <DeliveryZonesSelector
            supportMulti={true}
            deliveryZones={deliveryZones}
            onSelectDeliveryZone={onSelectDeliveryZone}
            selectedDeliveryZone={selectedDeliveryZone}
          />
          <MealMenuAdmin
            restaurant={restaurant}
            onClose={onCloseRestaurant}
            dishes={dishes}
            onToggleDish={onToggleDish}
            selectedDishes={selectedDishes}
            selectedPreOrderedDishes={selectedPreOrderedDishes}
            onSelectAllDishes={onSelectAllDishes}
            onDeselectAllDishes={onDeselectAllDishes}
            isLunch={isLunch}
            onToggleSupportEarlyDelivery={onToggleSupportEarlyDelivery}
            onChangePreOrderQuantity={onChangePreOrderQuantity}
            categoriesForRestaurant={categoriesForRestaurant}
            onSelectAllDishesInCategory={onSelectAllDishesInCategory}
            onDeselectAllDishesInCategory={onDeselectAllDishesInCategory}
            onEditDish={onEditDish}
            onSetHeroImage={onSetHeroImage}
          />
        </React.Fragment>
      ) : (
        <div className="restaurant-list">
          {restaurants
            .filter((r) => r.level === RestaurantLevel.Admitted)
            .map((restaurant, index) =>
              restaurant.name && restaurant.name.length > 0 ? (
                <RestaurantItem
                  restaurant={restaurant}
                  key={index}
                  onViewDetails={onViewRestaurant}
                  onCloseDetails={onCloseRestaurant}
                  restaurantIndex={index}
                  selected={selectedRestaurant === index}
                />
              ) : null
            )}
        </div>
      )}
    </div>
  );
};

export default RestaurantList;
