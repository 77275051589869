import { clearTimeout } from "timers";
import React, { Component } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import moment from "moment-timezone";
import posthog from "posthog-js";
import { confirmAlert } from "react-confirm-alert";
import { isMobile } from "react-device-detect";
import toast, { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { DishType } from "src/api/dish";
import { GetIsMealOrderable, MealVisited } from "src/api/meals";
import { FoodieEvent, FoodieSource, TrackEvent } from "src/api/tracker";
import { TrackAdsEvent } from "src/api/tracker";
import { getGoodKeyFromCart } from "src/util/cart";
import {
  LOGGING,
  Footer,
  // NavbarSingleMeal,
  MenuTwoChoices,
  CheckoutBanner,
  NominationBanner,
  // getCurrentDayDeliveryWindows,
  // getViewableDays,
  // getWeeklyMenuWindows,
  // getWeeklyMealWindows,
  // DeliveryWindowBanner,
  ConfirmAlert,
  // WeeklyMenu,
  NavWithDate,
  CATimeZone,
  // OrderBanner,
  // DeliveryZoneByCitySelector,
  DeliveryZoneByCitySelectorUnselected,
  findRestaurant,
  DishesToCategories,
  CATEGORY_OFFSET,
  MenuLink,
  HomeFromMenu,
  // Hero,
  getItemNumberInCart,
} from ".";
import AppStoreLink from "./components/customers/banners/AppStoreLink";
import { CheckoutThumbnail } from "./components/customers/carts/CheckoutThumbnail";
import { BackToTop } from "./components/customers/mealMenu/BackToTop";
import { InviteGroupOrder } from "./components/customers/mealMenu/InviteGroupOrder";
import { Recommendation } from "./components/customers/mealMenu/Recommendation";
import { GroupOrderCard } from "./components/customers/mealMenu/ViralActions";
import { DishChooserContainer } from "./components/dish/DishChooserContainer";
import FullScreenCollage from "./components/restaurants/FullScreenCollage";
import ImageCarousel from "./components/restaurants/ImageCarousel";
import RestaurantComment from "./components/restaurants/RestaurantComment";
import { Beige, BorderGrey } from "./components/Shared";
import {
  emptyCart,
  setDishCommentInCart,
  submitNomination,
  saveNomination,
  selectIsLunch,
  setIsLunch,
  // checkReferralEligibility,
  checkPreorderInventoryAvailablity,
  fetchDeliveryZoneByCity,
  setDeliveryCity,
  updateDeliveryCity,
  getReferralCode,
  checkOrderCount,
  checkOrderTotal,
  fetchSingleMealByName,
  reportBug,
  startMembership,
  resumeMembership,
  createGroupOrder,
  checkGroupOrder,
  readGroupOrder,
  loadCartForMeal,
  updateOrderOnMeal,
  checkExistingCart,
  readPastOrdersFromOneRestaurantByCustomer,
  updateExistingGoodInCart,
  readMealRestaurantUserStats,
  setRestaurantReview,
  loadClickID,
} from "../../store/actions";
import { removeDishFromCart, addGoodsToCart } from "../../store/actions/cart";
import { isWindowStartLunch, kCritialHourMinute } from "../../util/time";
import { Loading, PopUp } from "../shared";

class PageSingleMeal extends Component {
  constructor(props) {
    super(props);
    const { currentUser, location, pixel } = props;
    const address = currentUser?.user?.deliveryInfo?.address;
    const deliveryCity = currentUser?.user?.deliveryZone?.name;
    const urlParams = new URLSearchParams(location.search);
    const source = urlParams.get("source") || pixel?.source;
    const fbclid = urlParams.get("fbclid") || pixel?.fbclid;
    const restaurantId = urlParams.get("restaurantId") || pixel?.restaurantId;
    const cuisineName = urlParams.get("cuisineName") || pixel?.cuisineName;
    if (fbclid || source || restaurantId || cuisineName) {
      this.props.loadClickID(source, fbclid, restaurantId, cuisineName);
      LOGGING && console.log("PageSingleMeal got pixel:", this.props?.pixel);
    }

    const hasGiftCard = currentUser?.features?.includes("GIFT_CARD");
    const {
      date,
      city,
      mealType: mealTypeRaw,
      restaurantName,
      groupOrderId,
    } = props.match.params;
    const { restaurant, distance, fromHoliday, showCartOnMeal } =
      props.location.state ?? {};
    this.state = {
      loading: false,
      initializing: false,
      mealType: mealTypeRaw === "express-dinner" ? "earlyDinner" : mealTypeRaw,
      restaurant,
      timer: null,
      hasAnnouncement: false, //!hasGiftCard, // props?.currentUser?.isAuthenticated,
      hasGiftCard,
      dishQuantity: {},
      viewableOptions: {},
      showConfirmation: false,
      showRestaurantDetailsMobile: false,
      confirmedDish: "",
      confirmedQuantity: 1,
      showNominationConfirmation: false,
      orderTime: null,
      windows: null,
      selectedWindowIndex: 0,
      showDeliveryWindowOptions: false,
      nominationErrorMessage: "",
      showDailyMenu: false,
      nomination: { ...props.nomination },
      hideNav: false,
      showViewSelections: false,
      selectedDish: null,
      goodKey: null,
      dishesLoadedByDayIndex: null,
      selectedDeliveryZone: null,
      showDeliveryCityOptions: false,
      referralCode: null,
      showReferralCodeAnnouncement: false,
      dishesLoaded: false,
      dishesToDisplay: [],
      categoriesToDisplay: {},
      activeCategory: "",
      activeIndex: -1,
      showMenuLink: false,
      showGroupOrder: false,
      showInviteGroupOrder: false,
      groupOrderId: -1,
      itemsChanged: false,
      showCartOnMeal,
      showCartPeek: false,
      goodsToPeek: [],
      fullScreenCollage: false,
      fbclid,
      source,
    };
    this.handleToggleShowPeek = this.handleToggleShowPeek.bind(this);
    this.handleCreateGroupOrder = this.handleCreateGroupOrder.bind(this);
    this.handleShowGroupOrder = this.handleShowGroupOrder.bind(this);
    this.handleHideGroupOrder = this.handleHideGroupOrder.bind(this);
    this.handleShowInviteGroupOrder =
      this.handleShowInviteGroupOrder.bind(this);
    this.handleHideInviteGroupOrder =
      this.handleHideInviteGroupOrder.bind(this);
    this.handleEditNomination = this.handleEditNomination.bind(this);
    this.handleSubmitNomination = this.handleSubmitNomination.bind(this);
    this.handleHideNominationConfirmation =
      this.handleHideNominationConfirmation.bind(this);
    this.handleOpenDishDetails = this.handleOpenDishDetails.bind(this);
    this.handleEditGoodInCart = this.handleEditGoodInCart.bind(this);
    this.handleEditDishComment = this.handleEditDishComment.bind(this);
    this.handleHideConfirmation = this.handleHideConfirmation.bind(this);
    this.handleToggleShowDeliveryWindowOptions =
      this.handleToggleShowDeliveryWindowOptions.bind(this);
    this.handleChangeRestaurantName =
      this.handleChangeRestaurantName.bind(this);
    this.handleSelectRestaurantAddress =
      this.handleSelectRestaurantAddress.bind(this);
    this.handleSelectMealSimple = this.handleSelectMealSimple.bind(this);
    this.handleChangeMealEmptyCart = this.handleChangeMealEmptyCart.bind(this);
    this.handleConfirmChangeMealEmptyCart =
      this.handleConfirmChangeMealEmptyCart.bind(this);
    this.handleViewByWeekEmptyCart = this.handleViewByWeekEmptyCart.bind(this);
    this.handleConfirmViewByWeekEmptyCart =
      this.handleConfirmViewByWeekEmptyCart.bind(this);
    this.handleToggleMobileRestaurantDetails =
      this.handleToggleMobileRestaurantDetails.bind(this);
    this.handleBackToTop = this.handleBackToTop.bind(this);
    this.handleCloseSelections = this.handleCloseSelections.bind(this);
    this.handleToggleCategoryDisplay =
      this.handleToggleCategoryDisplay.bind(this);
    this.handleGoToCategory = this.handleGoToCategory.bind(this);
    this.categoryBarMobileRef = React.createRef();
    this.categoryBarDesktopRef = React.createRef();
    // this.handleCarouselScroll = this.handleCarouselScroll.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleToggleUserMenu = this.handleToggleUserMenu.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleMoveDestkopCategoryPage =
      this.handleMoveDestkopCategoryPage.bind(this);
    this.handleAddDishToCart = this.handleAddDishToCart.bind(this);
    this.handleAddGoodsToCart = this.handleAddGoodsToCart.bind(this);
    this.handleSelectDeliveryCity = this.handleSelectDeliveryCity.bind(this);
    this.handleStartMembership = this.handleStartMembership.bind(this);
    this.handleResumeMembership = this.handleResumeMembership.bind(this);
    this.handleShowAuth = this.handleShowAuth.bind(this);
    this.handleHideCartOnMeal = this.handleHideCartOnMeal.bind(this);
    this.handleShowCartOnMeal = this.handleShowCartOnMeal.bind(this);
    this.handleFlashCartPeek = this.handleFlashCartPeek.bind(this);
    this.handleHidePeek = this.handleHidePeek.bind(this);
    this.handleUpdateGoodInCart = this.handleUpdateGoodInCart.bind(this);
    this.handleRemoveDishFromCart = this.handleRemoveDishFromCart.bind(this);
    this.handleSetFullScreenCollage =
      this.handleSetFullScreenCollage.bind(this);
    this.handleReview = this.handleReview.bind(this);
  }

  handleSetFullScreenCollage(value, e) {
    e?.preventDefault();
    e.stopPropagation();
    this.setState({ fullScreenCollage: value });
  }
  handleRemoveDishFromCart(dish) {
    LOGGING && console.log("handleRemoveDishFromCart called with dish:", dish);
    const passed = moment().diff(moment(this.props.cart.lastUpdate), "ms");
    const updatedCart = this.props.removeDishFromCart({
      dish: dish,
      quantity: 1,
    });
    LOGGING &&
      console.log("handleRemoveDishFromCart updatedCart:", updatedCart);
    if (passed < 500) {
      LOGGING &&
        console.log("handleRemoveDishFromCart clearing the old time out");
      clearTimeout(this.state.timer);
    }

    this.setState({
      timer: setTimeout(() => {
        LOGGING && console.log("handleRemoveDishFromCart settime out is up");
        this.setState({ addingDish: dish?._id });
        this.props
          .updateOrderOnMeal()
          .then(() => {
            this.setState({ addingDish: null });
          })
          .catch((e) => {
            LOGGING && console.log("updateOrderOnMeal error:", e);
            toast.error(e.message);
          });
      }, 500),
    });
  }
  handleEditGoodInCart(goodKey) {
    const existingGoodInCart = this.props.cart.goods[goodKey];
    const { dish } = existingGoodInCart;
    this.setState({
      selectedDish: dish,
      goodKey,
    });
  }
  handleUpdateGoodInCart(goodKey, updatedGood) {
    LOGGING &&
      console.log("handleUpdateGoodInCart called with:", {
        goodKey,
        updatedGood,
      });
    this.props.updateExistingGoodInCart(goodKey, updatedGood);
    this.props.updateOrderOnMeal().then(() => {
      this.setState({
        selectedDish: null,
        goodKey: null,
      });
    });
  }
  handleToggleShowPeek(e) {
    e.preventDefault();
    this.setState({ showCartPeek: !this.state.showCartPeek });
  }
  handleHidePeek() {
    this.setState({ showCartPeek: false });
  }
  handleFlashCartPeek(goodsToPeek) {
    this.setState({ showCartPeek: true, goodsToPeek });
    setTimeout(() => {
      this.setState({ showCartPeek: false, goodsToPeek: [] });
    }, 3000);
  }
  handleShowCartOnMeal(e) {
    e.preventDefault();
    this.setState({ showCartOnMeal: true });
  }
  handleHideCartOnMeal(e) {
    e.preventDefault();
    this.setState({ showCartOnMeal: false });
  }
  handleCreateGroupOrder(groupOrderType, e) {
    LOGGING &&
      console.log("handleCreateGroupOrder called with:", { groupOrderType, e });
    e.preventDefault();
    e.stopPropagation();
    const { meal } = this.state;
    const { currentUser } = this.props;

    LOGGING &&
      console.log("handleCreateGroupOrder called with:", {
        groupOrderType,
        meal,
        currentUser,
      });

    if (!currentUser?.isAuthenticated) {
      this.props.history.push({
        pathname: "/auth",
        state: {
          title: "Sign In To Create Group Order",
          next: this.props.location.pathname,
        },
      });
      return;
    }

    this.props
      .createGroupOrder({
        mealId: meal?._id,
        groupOrderType,
        window: {
          start: meal.windowStart,
          end: meal.windowStart + 60 * 1000 * 60,
          cost: 0,
        },
      })
      .then((groupOrderId) => {
        this.setState({
          groupOrderId,
          showGroupOrder: false,
          showInviteGroupOrder: true,
        });
      });
  }
  handleShowGroupOrder(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showGroupOrder: true });
  }

  handleHideGroupOrder() {
    LOGGING && console.log("handleHideGroupOrder called");
    this.setState({ showGroupOrder: false });
  }

  handleShowInviteGroupOrder(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showInviteGroupOrder: true });
  }

  handleHideInviteGroupOrder() {
    LOGGING && console.log("handleHideInviteGroupOrder called");
    this.setState({ showInviteGroupOrder: false });
  }

  handleShowAuth(e) {
    e?.preventDefault();
    this.props.history.push({
      // pathname: "/signin",
      pathname: "/auth",
      state: {
        title: "Sign In To Continue",
        next: this.props.location.pathname,
        secondary: { title: "Create an Account", next: "/signup" },
      },
    });
  }

  handleStartMembership(level1, level2, level3) {
    let page = "";
    if (level1) {
      page = page + "=>" + level1;
    }
    if (level2) {
      page = page + "=>" + level2;
    }
    if (level3) {
      page = page + "=>" + level3;
    }
    LOGGING &&
      console.log("PageSingleMeal handleStartMembership called with: ", {
        page,
        level1,
        level2,
        level3,
      });
    this.setState({ loading: true }, () => {
      this.props.startMembership(page).then(() => {
        this.setState({ loading: false });
      });
    });
  }

  handleResumeMembership() {
    this.setState({ loading: true }, () => {
      this.props.resumeMembership().then(() => {
        this.setState({ loading: false });
      });
    });
  }

  handleToggleShowMenuLink(e) {
    e.preventDefault();
    // e.stopPropagation();
    const { showMenuLink } = this.state;
    LOGGING &&
      console.log(
        "handleToggleShowMenuLink called with showMenuLink:",
        showMenuLink
      );
    this.setState({ showMenuLink: !showMenuLink });
  }

  handleSelectDeliveryCity(selected, e) {
    e.preventDefault();

    const { deliveryZoneByCity } = this.props;
    const deliveryCity = Object.keys(deliveryZoneByCity)[selected];
    const selectedDeliveryZone = deliveryZoneByCity[deliveryCity];
    LOGGING &&
      console.log("handleSelectDeliveryCity called with:", {
        deliveryCity,
        selected,
        deliveryZoneByCity,
        selectedDeliveryZone,
      });

    this.props.updateDeliveryCity(selectedDeliveryZone);
    this.setState({
      selectedDeliveryZone,
    });
    window.scrollTo(0, 0);
  }

  handleMoveDestkopCategoryPage(nextOrPrev, e) {
    e.preventDefault();

    this.categoryBarDesktopRef.current.scrollLeft +=
      nextOrPrev * this.categoryBarDesktopRef.current.clientWidth;
    LOGGING &&
      console.log("handleMoveDestkopCategoryPage got:", {
        scrollWidth: this.categoryBarDesktopRef?.current?.scrollWidth,
        clientWidth: this.categoryBarDesktopRef?.current?.clientWidth,
        scrollLeft: this.categoryBarDesktopRef?.current?.scrollLeft,
      });
    const isOverflowDesktop =
      this.categoryBarDesktopRef?.current?.scrollWidth >
      this.categoryBarDesktopRef?.current?.scrollLeft +
        this.categoryBarDesktopRef?.current?.clientWidth +
        10;
    const isUnderflowDesktop =
      this.categoryBarDesktopRef?.current.scrollLeft > 0;
    this.setState({ isOverflowDesktop, isUnderflowDesktop });
  }
  handleSignOut(e) {
    e.preventDefault();
    this.props.signOut();
    this.setState({ showUserMenu: false });
  }
  handleToggleUserMenu(e) {
    e.preventDefault();
    const { showUserMenu } = this.state;
    this.setState({ showUserMenu: !showUserMenu });
  }
  handleGoBack(e) {
    LOGGING && console.log("handleGoBack called");
    e.preventDefault();

    const { fromHoliday, fromGuest, fromWeekly } =
      this.props?.location?.state || {};
    const { pixel } = this.props;
    const isAuthenticated = this.props?.currentUser?.isAuthenticated;
    console.log("handleGoBack called with:", {
      fromGuest,
      fromHoliday,
      fromWeekly,
      isAuthenticated,
    });

    // For new user, we don't save carts on the server, so we have to save it locally
    if (!isAuthenticated) {
      const cart = this.props.cart;
      const carts = this.props.carts;
      if (cart) {
        const cartIndex = carts.findIndex(
          (c) => c.meal?._id?.toString() === cart?.meal?._id?.toString()
        );
        if (cartIndex === -1) {
          carts.push(cart);
        } else {
          carts[cartIndex] = cart;
        }
        this.setState({ carts });
      }
    }

    if (window.fbq && pixel?.fbclid) {
      LOGGING &&
        console.log("page single meal back to home click is tracked by fbq");
      window.fbq("track", "ViewContent", {
        content_name: `backToHome`,
        content_category: "pageSingleMeal",
        value: 0,
        currency: "USD",
      });

      // Track Ads Event
      TrackAdsEvent(pixel?.fbclid, {
        source: pixel?.source,
        eventCode: "ViewContent",
        page: "pageSingleMeal",
        contentName: `backToHome`,
      });
    }

    this.props.history.push({
      pathname: fromHoliday
        ? "/holiday"
        : fromGuest || !isAuthenticated
        ? "/guest"
        : "/",
      state: { homeScrollY: this.props?.location?.state?.homeScrollY },
    });
  }

  handleGoToCategory(isMobile, activeIndex, e) {
    // ? categories[c].refMobile : categories[c].refDesktop

    const { categoriesToDisplay } = this.state;
    const activeCategory = Object.keys(categoriesToDisplay)[activeIndex];
    LOGGING &&
      console.log("handleGoToCategory called with: ", {
        categoriesToDisplay,
        isMobile,
        activeIndex,
        activeCategory,
      });
    e.preventDefault();
    this.setState(
      {
        activeIndex,
        activeCategory,
      },
      () => {
        const currentRef = isMobile
          ? categoriesToDisplay[activeCategory].refMobile
          : categoriesToDisplay[activeCategory].refDesktop;
        if (isMobile) {
          window.scrollTo({
            top: currentRef.current.offsetTop - 200,
            left: 0,
            behavior: "auto",
          });
          categoriesToDisplay[activeCategory].refNav.current.scrollIntoView({
            block: "start",
            inline: "center",
            behavior: "auto",
          });
        } else {
          currentRef.current.scrollIntoView({
            block: "center",
            inline: "nearest",
          });
        }
      }
    );
  }
  handleToggleCategoryDisplay(categoryName, e) {
    e.preventDefault();
    let { categoriesToDisplay } = this.state;
    categoriesToDisplay[categoryName] = !categoriesToDisplay[categoryName];
    this.setState({ categoriesToDisplay });
  }

  handleBackToTop(e) {
    e.preventDefault();
    const { categoriesToDisplay } = this.state;
    LOGGING && console.log("handleBackToTop got:", categoriesToDisplay);
    if (Object.keys(categoriesToDisplay).length <= 0) return;
    const activeIndex = 0,
      activeCategory = Object.keys(categoriesToDisplay)[activeIndex];
    LOGGING &&
      console.log("handleBackToTop called:", { activeIndex, activeCategory });
    this.setState(
      {
        activeCategory,
        activeIndex,
      },
      () => {
        window.scrollTo(0, 0);
        this.categoryBarDesktopRef?.current?.scrollTo(0, 0);
        this.categoryBarMobileRef?.current?.scrollTo(0, 0);
      }
    );
  }

  handleToggleMobileRestaurantDetails(e) {
    e.preventDefault();
    const { showRestaurantDetailsMobile } = this.state;
    this.setState({
      showRestaurantDetailsMobile: !showRestaurantDetailsMobile,
    });
  }

  handleConfirmChangeMealEmptyCart(isLunch) {
    LOGGING &&
      console.log(
        "handleConfirmChangeMealEmptyCart called with isLunch:",
        isLunch
      );
    const updatedCart = this.props.emptyCart();
    this.setState({ cart: updatedCart }, () => {
      this.props.selectIsLunch(isLunch);
    });
  }

  handleConfirmViewByWeekEmptyCart() {
    LOGGING && console.log("handleConfirmViewByWeekEmptyCart called");
    const updatedCart = this.props.emptyCart();
    this.setState({ cart: updatedCart }, () => {
      this.props.setViewByWeek(true);
      window.scrollTo(0, 0);
    });
  }

  handleChangeMealEmptyCart(isLunch, e) {
    e.preventDefault();
    LOGGING &&
      console.log("handleChangeMealEmptyCart called with isLunch:", isLunch);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            message={`You currently have items in your ${
              isLunch ? "dinner" : "lunch"
            } cart, would you like to clear your  ${
              isLunch ? "dinner" : "lunch"
            } cart and start your ${!isLunch ? "dinner" : "lunch"} order?`}
            onConfirm={this.handleConfirmChangeMealEmptyCart.bind(
              this,
              isLunch
            )}
            title={`Start ${!isLunch ? "dinner" : "lunch"} order?`}
            onClose={onClose}
            actionName="empty"
            yesWord="OK"
            noWord="Cancel"
          />
        );
      },
    });
  }

  handleViewByWeekEmptyCart(cartTime, e) {
    e.preventDefault();
    LOGGING &&
      console.log("handleChangeMealEmptyCart called with cartTime:", cartTime);
    const isLunch = isWindowStartLunch(cartTime);
    const cartDate = moment(cartTime).tz(CATimeZone).format("ddd, MM/DD");
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            message={`You currently have items in your ${cartDate} ${
              isLunch ? "lunch" : "dinner"
            } cart, would you like to clear it and start a new order?`}
            onConfirm={this.handleConfirmViewByWeekEmptyCart}
            title={`Start another order?`}
            onClose={onClose}
            actionName="empty"
            yesWord="OK"
            noWord="Cancel"
          />
        );
      },
    });
  }

  handleSelectMealSimple(isLunch) {
    LOGGING &&
      console.log("handleSelectMealSimple called with isLunch:", isLunch);
    const {
      viewableOptions,
      selectedOption,
      weeklyMenu,
      selectedDeliveryZone,
    } = this.state;
    const { dishes } = this.props;
    const windowStart =
      viewableOptions[selectedOption]?.windows?.[isLunch]?.default?.start;
    const restaurant = findRestaurant(
      windowStart,
      weeklyMenu,
      selectedDeliveryZone
    );
    const dishesToDisplay = dishes.filter(
      (d) =>
        d.windowStart === windowStart &&
        d.supportedDeliveryZone.includes(selectedDeliveryZone)
    );
    const categoriesToDisplay = DishesToCategories(dishesToDisplay);
    const categoryNames = Object.keys(categoriesToDisplay);

    this.setState({
      weeklyMenu,
      restaurant,
      dishesToDisplay,
      categoriesToDisplay,
      activeIndex: categoryNames.length > 0 ? 0 : -1,
      activeCategory: categoryNames.length > 0 ? categoryNames[0] : null,
    });
    this.props.selectIsLunch(isLunch);

    this.setState({ windowStart, restaurant }, () => {
      window.scrollTo(0, 0);
    });
  }

  handleChangeRestaurantName(name) {
    LOGGING && console.log("handleChangeRestaurantName called with ", name);
    const { nomination } = this.state;
    const { restaurant } = nomination;
    this.setState({
      nomination: { ...nomination, restaurant: { ...restaurant, name } },
      isSearching: true,
    });
  }

  handleSelectRestaurantAddress(address) {
    LOGGING && console.log("handleSelectAddress called with ", address);
    let newAddress = address,
      name = "";
    const { nomination } = this.state;
    if (address?.gmaps?.address_components) {
      const { gmaps } = address;
      const { address_components } = gmaps;
      const city = address_components.find((c) =>
        c.types.includes("locality")
      ).short_name;
      const number = address_components.find((c) =>
        c.types.includes("street_number")
      ).short_name;
      const street = address_components.find(
        (c) => c.types.includes("route") || c.types.includes("premise")
      ).short_name;
      const state = address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      ).short_name;
      const zip = address_components.find((c) =>
        c.types.includes("postal_code")
      ).short_name;

      newAddress = `${number} ${street}, ${city}, ${state} ${zip}`;
    }
    if (address?.gmaps?.name) {
      name = address.gmaps.name;
    }

    this.setState({
      nomination: {
        ...nomination,
        restaurant: { address: newAddress, name },
      },
      isSearching: false,
    });
  }

  handleSubmitNomination(e) {
    e.preventDefault();
    this.setState({
      nominationErrorMessage: "",
    });

    const { nomination } = this.state;
    LOGGING &&
      console.log(
        "handleSubmitNomination called with nomination: ",
        nomination
      );
    if (nomination.restaurant.name === "") {
      this.setState({
        nominationErrorMessage: "Please provide the restaurant name.",
      });
      return;
    }
    this.setState({ loading: true });
    this.props.saveNomination(nomination);

    LOGGING &&
      console.log(
        "saveNomination call done with this.props.nomination:",
        this.props.nomination
      );

    if (this.props.currentUser.isAuthenticated) {
      this.props.submitNomination().then(() => {
        this.setState({
          showNominationConfirmation: nomination,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            showNominationConfirmation: false,
            nomination: { restaurant: { name: "" }, dish: "" },
          });
        }, 2000);
      });
    } else {
      this.props.history.push("/signup");
    }
  }

  handleEditNomination(field, e) {
    const { nomination } = this.state;
    LOGGING &&
      console.log("handleEditNomination called with: ", {
        field,
        content: e.target.value,
      });
    this.setState({ nomination: { ...nomination, [field]: e.target.value } });
  }
  handleHideNominationConfirmation(e) {
    e.preventDefault();
    this.setState({
      showNominationConfirmation: false,
      nomination: { restaurant: { name: "" }, dish: "" },
    });
  }

  handleToggleShowDeliveryWindowOptions(e) {
    e.preventDefault();
    LOGGING && console.log("handleToggleShowDeliveryWindowOptions called");
    const { showDeliveryWindowOptions } = this.state;
    this.setState({ showDeliveryWindowOptions: !showDeliveryWindowOptions });
  }

  handleHideConfirmation(e) {
    e.preventDefault();
    this.setState({ showConfirmation: false });
  }

  handleEditDishComment(dishId, e) {
    const { cart } = this.props;
    const { goods } = cart;
    const comment = e.target.value;
    LOGGING &&
      console.log("handleEditDishComment called with: ", {
        cart,
        goods,
        dishId,
        comment,
      });
    if (dishId in goods) {
      this.props.setDishCommentInCart({ dishId, comment }).then(() => {
        this.setState({ itemsChanged: true });
      });
    }
  }

  handleCloseSelections() {
    this.setState({
      selectedDish: null,
    });
  }

  // goods are Good in PureDishChooser
  handleAddGoodsToCart(goods) {
    const startAt = moment().valueOf();
    LOGGING && console.log("handleAddGoodsToCart called with goods: ", goods);

    const { referralCode, windowStart, meal } = this.state;

    if (meal?._id === null) {
      const {
        date,
        city,
        mealType: mealTypeRaw,
        restaurantName,
        groupOrderId,
      } = this?.props?.match?.params || {};
      const {
        _id: userId,
        firstName,
        lastName,
      } = this?.props?.currentUser?.user || {};
      reportBug(`PageSingleMeal has no meal ID but user tried to add to cart ${date}\
        ${city} ${mealTypeRaw} ${restaurantName} ${groupOrderId} by ${userId} ${firstName} ${lastName}`);
    }

    // util on client, no server call

    const passed = moment().diff(moment(this.props.cart.lastUpdate), "ms");
    LOGGING &&
      console.log("handleAddGoodsToCart got old:", {
        cart: this.props.cart,
        passed,
      });

    // this doesnt' call server, just updates the cart in redux
    const updatedCart = this.props.addGoodsToCart({
      goodsToAdd: goods,
      window: {
        start: windowStart,
        end: windowStart + 60 * 60 * 1000,
        cost: 0,
      },
      referralCode,
      mealId: meal._id,
    });

    LOGGING &&
      console.log(
        "handleAddGoodsToCart got new lastUpdate:",
        updatedCart.lastUpdate
      );

    if (passed < 500) {
      LOGGING && console.log("clearing the old time out");
      clearTimeout(this.state.timer);
    }
    this.setState({
      timer: setTimeout(() => {
        LOGGING && console.log("settime out is up");
        this.setState(
          { addingDish: goods[0]?.dish?._id, initializing: true },
          () => {
            // this is the only call to server
            this.props
              .updateOrderOnMeal()
              .then(() => {
                LOGGING &&
                  console.log("updateOrderOnMeal done on PageSingleMeal.");
                this.setState({
                  addingDish: null,
                  itemsChanged: true,
                  initializing: false,
                });
                TrackEvent(
                  FoodieEvent.ACTION_ADD_TO_CART,
                  null,
                  FoodieSource.UNKNOWN,
                  this.props.currentUser?.user?._id,
                  meal?._id,
                  moment().valueOf() - startAt
                );
              })
              .catch((e) => {
                LOGGING && console.log("updateOrderOnMeal error:", e);
                toast.error(e.message);
              });
          }
        );
      }, 500),
    });
    this.handleFlashCartPeek(goods);
  }

  handleAddDishToCart(dish) {
    const { cart, currentUser, pixel } = this.props;
    const fbclid = pixel?.fbclid;
    const source = pixel?.source;
    const menuLink = this.props.match.url;
    LOGGING &&
      console.log("handleAddDishToCart called with:", {
        dish,
        menuLink,
        source,
        fbclid,
        orderId: cart?._id,
      });

    // Insert Facebook Pixel tracking here
    if (window.fbq && fbclid) {
      LOGGING && console.log("add dish to cart click is tracked by fbq");
      window.fbq("track", "AddToCart", {
        content_name: `${menuLink} add to cart`,
        content_category: "pageSingleMeal",
        value: 0,
        currency: "USD",
      });

      // Track Ads Event
      TrackAdsEvent(fbclid, {
        source,
        eventCode: "AddToCart",
        page: "pageSingleMeal",
        contentName: `${menuLink}`,
        orderId: cart?._id,
      });
    }

    // case 1: if there's no selection
    if (
      (dish.selections ?? []).length === 0 &&
      dish.dishType !== DishType.GROUP
    ) {
      const dishSelectionData = {
        dish,
        selections: [],
        price: dish.priceFoodieListed,
        quantity: 1,
        comment: "",
        addedBy: currentUser?.user?._id,
      };
      LOGGING && console.log("handleAddDishToCart called with case 1.");
      this.handleAddGoodsToCart([dishSelectionData]);
      return;
    }

    const goods = this.props.cart?.goods;
    const goodKey = getGoodKeyFromCart(goods, dish._id);
    const existingGoodInCart = goods[goodKey];

    //case 2.1 if there's selection and this dish is already in the cart
    if (existingGoodInCart != null) {
      const dishSelectionData = {
        ...existingGoodInCart,
        quantity: 1,
      };
      this.handleAddGoodsToCart([dishSelectionData]);
      LOGGING && console.log("handleAddDishToCart called with case 2.1.");
      return;
    }
    LOGGING && console.log("handleAddDishToCart called with case 2.2.");
    //case 2.2 if there's selection and this dish is new, this is called with dish module pops up
    this.handleOpenDishDetails(dish, 1, null);
  }

  handleOpenDishDetails(dish, change, e) {
    e?.preventDefault();
    LOGGING &&
      console.log("handleOpenDishDetails called with: ", {
        dish,
        change,
      });
    const { cart, pixel } = this.props;
    const countInCart = cart?.goods
      ? Object.keys(cart.goods).reduce(
          (a, b) =>
            (String(b).includes(String(dish._id))
              ? cart.goods[b].quantity
              : 0) + a,
          0
        )
      : 0;

    // Insert Facebook Pixel tracking here
    if (cart?.goods?.length === 0) {
      const fbclid = pixel?.fbclid;
      const source = pixel?.source;
      const menuLink = this.props.match.url;
      if (window.fbq && fbclid) {
        LOGGING && console.log("add dish to cart click is tracked by fbq");
        window.fbq("track", "AddToCart", {
          content_name: `${menuLink} add to cart`,
          content_category: "pageSingleMeal",
          value: 0,
          currency: "USD",
        });

        // Track Ads Event
        TrackAdsEvent(fbclid, {
          source,
          eventCode: "AddToCart",
          page: "pageSingleMeal",
          contentName: `${menuLink}`,
          orderId: cart?._id,
        });
      }
    }

    this.setState({
      selectedDish: dish,
      countInCart,
    });
  }

  handleReview = (restaurantId, review) => {
    LOGGING &&
      console.log("handleReview called with: ", { restaurantId, review });
    const userId = this.props.currentUser?.user?._id;
    if (!userId) {
      this.props.history.push({
        pathname: "/auth",
        state: {
          title: `To add a note`,
          next: `/${this.props.location.pathname}`,
        },
      });
      return;
    }
    this.props.setRestaurantReview(restaurantId, review).then((res) => {
      LOGGING &&
        console.log("setRestaurantReview response: ", {
          restaurantId,
          review: res,
        });
    });
  };
  loadDeliveryZone() {
    // TODO: load default zone
    if (!this.state.selectedDeliveryZone) {
    }
  }

  trackPageSingleMealEvent(endAt, startAt, preloaded = true) {
    const userId = this.props.currentUser?.user?._id;
    const { meal } = this.state;
    const uuid = `${FoodieEvent.PAGE_SINGLE_MEAL}${startAt}`;
    const event = preloaded
      ? FoodieEvent.PAGE_SINGLE_MEAL
      : FoodieEvent.PAGE_SINGLE_MEAL_FETCH;
    TrackEvent(
      event,
      uuid,
      FoodieSource.UNKNOWN,
      userId,
      meal?._id,
      endAt > startAt ? endAt - startAt : -1
    );
  }

  async validateMealOrderable(meal) {
    const lastCall = meal?.earlyCutoffTime ?? meal?.windowStart;
    if (!lastCall) {
      this.props.history.push("/");
      toast.error(`Undefined window for meal ${meal?._id}!`);
    }

    const now = moment().valueOf();
    const timeLeft = lastCall - now;
    const gracePeriod = 1000 * 60 * 60; // 1 hour
    LOGGING &&
      console.log("validateMealOrderable called with:", {
        mealId: meal?._id,
        lastCall,
        now,
        timeLeft,
      });

    if (timeLeft < -gracePeriod) {
      const mealDateStr = moment(meal.windowStart)
        .tz("America/Los_Angeles")
        .fromNow();
      this.props.history.push("/");
      toast.error(`Meal for ${meal.restaurant} closed ${mealDateStr}!`);
    }
    // check if meal is orderable (time and capacity) that needs to call server
    // const details = await GetIsMealOrderable(meal?._id);
    // if (details?.isOrderable === false) {
    //   this.props.history.push("/");
    //   toast.error(details.errorMsgs.join("\n"));
    // }
  }

  async componentDidMount() {
    const startAt = moment().valueOf();
    this.trackPageSingleMealEvent(-1, startAt, true);
    this.setState({ isMobile: window.innerWidth < 500 });

    const {
      date,
      city,
      mealType: mealTypeRaw,
      restaurantName,
      groupOrderId,
    } = this.props.match.params;
    const { mealType } = this.state;
    const fromWeekly = this.props?.location?.state?.fromWeekly;
    const fromHoliday = this.props?.location?.state?.fromHoliday;
    const fromGuest = this.props?.location?.state?.fromGuest;
    const distance = this.props?.location?.state?.distance;

    const menuLink = `${date}/${mealType}/${restaurantName}`;

    const hour = kCritialHourMinute[mealType].deliveryStart[0];
    const minute = kCritialHourMinute[mealType].deliveryStart[1];

    const windowStart = moment(date, "MM-DD-YYYY")
      .tz("America/Los_Angeles")
      .set({ hour, minute })
      .valueOf();

    const { cart, currentUser, meals } = this.props;
    const shortName = `${date}/${
      mealType === "earlyDinner" ? "express-dinner" : mealType
    }/${restaurantName}`;
    const foundMeal =
      meals !== undefined && meals !== null
        ? meals?.find((m) => m?.shortName === shortName)
        : null;
    LOGGING &&
      console.log("PageSingleMeal componentDidMount foundMeal:", foundMeal);

    const isToday = moment().isSame(moment(windowStart), "day");
    const cutOffBeforeDelivery =
      mealType === "lunch" ? 60 : mealType === "earlyDinner" ? 30 : 90;
    const lateCutoff = foundMeal?.restaurant?.allowLateCutoff ? 15 : 0;
    const orderBy = moment(windowStart)
      .subtract(cutOffBeforeDelivery - lateCutoff, "minutes")
      .add(5, "minute")
      .valueOf();

    this.setState({
      windowStart,
      isToday,
      orderBy,
      fromWeekly,
      fromHoliday,
      fromGuest,
      mealType,
      menuLink,
      distance,
      fromHoliday,
    });
    await this.props.fetchDeliveryZoneByCity();

    const { code } = this.props.match.params;
    if (code) {
      const orderCount = currentUser.isAuthenticated
        ? await this.props.checkOrderCount()
        : 0;
      if (orderCount > 0) {
        this.props.history.push("/");
      } else {
        LOGGING && console.log("componentdidmount with code:", code);
        const referralCode = await this.props.getReferralCode(code);
        this.setState({ referralCode });
      }
    } else {
      // if (orderCount > 0) {
      //   const showReferralCodeAnnouncement = await this.props.checkOrderTotal();
      this.setState({ showReferralCodeAnnouncement: true });
      // }
    }

    if (
      currentUser?.user?.isAdmin ||
      currentUser?.user?.isUserGroupAdmin ||
      currentUser?.user?.isDriver ||
      currentUser?.user?.isLeadDriver ||
      currentUser?.user?.isOps ||
      currentUser?.user?.isRestaurant ||
      currentUser?.user?.isMarketer ||
      currentUser?.user?.isPendingActivation ||
      currentUser?.user?.isBlocked
    ) {
      posthog.opt_out_capturing();
    }

    window.scrollTo(0, 0);
    this.setState(
      {
        loading: true,
        initializing: true,
      },
      async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const source = urlParams.get("source");
        const shortName = `${date}/${
          mealType === "earlyDinner" ? "express-dinner" : mealType
        }/${restaurantName}`;
        const foundMeal =
          meals !== undefined && meals !== null
            ? meals?.find((m) => m?.shortName === shortName)
            : null;
        LOGGING && console.log("PageSingleMeal foundMeal:", foundMeal);
        if (foundMeal) {
          const meal = foundMeal;
          LOGGING &&
            console.log(
              `meal loaded: yes!${moment().format("HH:mm:ss:SSS")}`,
              meal
            );
          this.validateMealOrderable(meal).then(() => {
            const {
              restaurant,
              dishes: dishesToDisplay,
              supportedDeliveryZone,
              trendy,
            } = meal;
            const categoriesToDisplay = DishesToCategories(dishesToDisplay);

            const userGroupName = currentUser?.user?.userGroup?.name;
            const zoneNames = supportedDeliveryZone.map((z) => z.name);
            const isUserGroupMeal = zoneNames.includes(userGroupName);
            const categoryNames = Object.keys(categoriesToDisplay);

            this.props
              .readMealRestaurantUserStats(meal?.restaurant?._id)
              .then((result) => {
                const updatedRestaurant = {
                  ...restaurant,
                  ...result,
                };
                const updatedMeal = {
                  ...meal,
                  restaurant: updatedRestaurant,
                };
                this.setState(
                  {
                    meal: updatedMeal,
                    mealId: meal?._id,
                    restaurant: updatedRestaurant,
                    isUserGroupMeal,
                    windowStart,
                    loading: false,
                    dishesToDisplay,
                    categoriesToDisplay,
                    showConfirmation: false,
                    activeIndex: categoryNames.length > 0 ? 0 : -1,
                    activeCategory:
                      categoryNames.length > 0 ? categoryNames[0] : null,
                  },
                  () => {
                    window.scrollTo(0, 0);
                    this.trackPageSingleMealEvent(
                      moment().valueOf(),
                      startAt,
                      true
                    );

                    // LOGGING &&
                    //   console.log("pagesignlemenu mounted with:", {
                    //     mobile: this.categoryBarMobileRef,
                    //     desktop: this.categoryBarDesktopRef?.current,
                    //     scrollWidth:
                    //       this.categoryBarDesktopRef?.current?.scrollWidth,
                    //     clientWidth:
                    //       this.categoryBarDesktopRef?.current?.clientWidth,
                    //   });
                    const isOverflowDesktop =
                      this.categoryBarDesktopRef?.current?.scrollWidth >
                      this.categoryBarDesktopRef?.current?.scrollLeft +
                        this.categoryBarDesktopRef?.current?.clientWidth +
                        10;
                    const isUnderflowDesktop =
                      this.categoryBarDesktopRef?.current?.scrollLeft > 0;
                    this.setState(
                      { isOverflowDesktop, isUnderflowDesktop },
                      () => {
                        const { user } = currentUser;
                        const userName =
                          currentUser.isAuthenticated && currentUser.user?._id
                            ? `${currentUser.user?.firstName} ${currentUser.user?.lastName}`
                            : this.props.pixel?.fbclid
                            ? `anonymous user ${this.props.pixel?.fbclid}`
                            : "anonymous user";

                        MealVisited(
                          shortName,
                          userName,
                          meal?._id,
                          user?._id,
                          meal?.windowStart,
                          source
                        );
                        this.props.readPastOrdersFromOneRestaurantByCustomer(
                          user?._id,
                          restaurant?._id
                        );

                        if (groupOrderId) {
                          this.props.readGroupOrder(groupOrderId).then(() => {
                            this.setState({ initializing: false });
                          });
                        } else {
                          this.props
                            .checkExistingCart({ mealId: meal?._id })
                            .then(() => {
                              LOGGING &&
                                console.log(
                                  "checkExistingCart done with cart:",
                                  this.props.cart
                                );
                              this.setState({
                                initializing: false,
                                // temporarily for debugging ease
                                // showCartPeek: true,
                                // goodsToPeek: Object.values(
                                //   this.props?.cart?.goods
                                // ).slice(0, 1),
                              });
                            });
                          this.props
                            .checkGroupOrder({ mealId: meal?._id })
                            .then(() => {
                              this.setState({ initializing: false });
                            });
                        }
                      }
                    );
                  }
                );
              });
          });
        } else {
          LOGGING &&
            console.log(`meal loaded: no!${moment().format("HH:mm:ss:SSS")}`);
          this.props
            .fetchSingleMealByName({
              city,
              date,
              restaurantName,
              mealType: mealTypeRaw,
            })
            .then((meal) => {
              LOGGING &&
                console.log(
                  "fetchSingleMealByName got meal from server:",
                  meal
                );

              this.validateMealOrderable(meal).then(() => {
                const { dishes: dishesToDisplay } = this.props;
                const { restaurant, userGroup, trendy } = meal;
                LOGGING &&
                  console.log("fetchSingleMealByName returned with:", {
                    dishesToDisplay,
                    restaurant,
                  });
                const categoriesToDisplay = DishesToCategories(dishesToDisplay);
                const categoryNames = Object.keys(categoriesToDisplay);

                this.props
                  .readMealRestaurantUserStats(meal?.restaurant?._id)
                  .then((result) => {
                    const updatedRestaurant = {
                      ...restaurant,
                      ...result,
                    };
                    const updatedMeal = {
                      ...meal,
                      restaurant: updatedRestaurant,
                    };
                    this.setState(
                      {
                        mealId: meal._id,
                        meal: updatedMeal,
                        userGroup,
                        restaurant: updatedRestaurant,
                        windowStart,
                        loading: false,
                        dishesToDisplay,
                        categoriesToDisplay,
                        showConfirmation: false,
                        activeIndex: categoryNames.length > 0 ? 0 : -1,
                        activeCategory:
                          categoryNames.length > 0 ? categoryNames[0] : null,
                      },
                      () => {
                        window.scrollTo(0, 0);
                        this.trackPageSingleMealEvent(
                          moment().valueOf(),
                          startAt,
                          false
                        );
                        const isOverflowDesktop =
                          this.categoryBarDesktopRef?.current?.scrollWidth >
                          this.categoryBarDesktopRef?.current?.scrollLeft +
                            this.categoryBarDesktopRef?.current?.clientWidth +
                            10;
                        const isUnderflowDesktop =
                          this.categoryBarDesktopRef?.current?.scrollLeft > 0;
                        this.setState(
                          { isOverflowDesktop, isUnderflowDesktop },
                          () => {
                            const { user } = currentUser;
                            const userName =
                              currentUser.isAuthenticated &&
                              currentUser.user?._id
                                ? `${currentUser.user?.firstName} ${currentUser.user?.lastName}`
                                : this.props.pixel?.fbclid
                                ? `anonymous user ${this.props.pixel?.fbclid}`
                                : "anonymous user";

                            MealVisited(
                              shortName,
                              userName,
                              meal?._id,
                              user?._id,
                              meal?.windowStart,
                              source
                            );

                            if (groupOrderId) {
                              this.props
                                .readGroupOrder(groupOrderId)
                                .then(() => {
                                  this.setState({ initializing: false });
                                });
                            } else {
                              this.props
                                .checkExistingCart({ mealId: meal?._id })
                                .then(() => {
                                  this.setState({ initializing: false });
                                });
                              this.props
                                .checkGroupOrder({ mealId: meal?._id })
                                .then(() => {
                                  this.setState({ initializing: false });
                                });
                            }
                            this.props.readPastOrdersFromOneRestaurantByCustomer(
                              user?._id,
                              restaurant?._id
                            );
                          }
                        );
                      }
                    );
                  });
              });
            })
            .catch((e) => {
              LOGGING && console.log("no such meal!");
              this.props.history.push("/");
            });
        }
      }
    );

    const { nomination } = this.props;
    if (!nomination.submitted && currentUser.isAuthenticated) {
      window.scrollTo(0, document.body.scrollHeight);
      this.setState({ loading: true });
      this.props.submitNomination().then(() => {
        this.setState(
          {
            showNominationConfirmation: nomination,
            nomination: { ...this.props.nomination },
          },
          () => {
            window.scrollTo(0, document.body.scrollHeight);
            this.setState({ loading: false });
          }
        );
        setTimeout(() => {
          this.setState({ showNominationConfirmation: false });
        }, 2000);
      });
    }
  }
  render() {
    const {
      loading,
      initializing,
      windows,
      showConfirmation,
      showNominationConfirmation,
      confirmedDish,
      confirmedQuantity,
      nomination,
      hasAnnouncement,
      showRestaurantDetailsMobile,
      showOrderConfirmation,
      selectedDish,
      goodKey,
      countInCart,
      trendy,
      windowStart,
      restaurant,
      dishesToDisplay,
      categoriesToDisplay,
      activeIndex,
      isUserGroupMeal,
      fromWeekly,
      fromHoliday,
      distance,
      showUserMenu,
      isOverflowDesktop,
      isUnderflowDesktop,
      mealType,
      showMenuLink,
      menuLink,
      mealId,
      meal,
      showGroupOrder,
      showInviteGroupOrder,
      itemsChanged,
      addingDish,
      isToday,
      orderBy,
      showCartOnMeal,
      showCartPeek,
      goodsToPeek,
      isMobile,
      fromGuest,
      fullScreenCollage,
    } = this.state;

    LOGGING &&
      console.log("PageSingleMeal rendering with", {
        props: this.props,
        state: this.state,
      });
    const { cart, currentUser, deliveryZoneByCity, pixel } = this.props;
    const urlParams = new URLSearchParams(this.props?.location?.search);
    const restaurantId = urlParams.get("restaurantId");

    // if (!currentUser.isAuthenticated || !currentUser.user) {
    //   const { restaurantName } = this.props.match.params;
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: "/auth",
    //         state: {
    //           title: `To See \n${restaurantName.split("-").join(" ")} Menu`,
    //           next: source
    //             ? `${this.props.location.pathname}?source=${source}`
    //             : this.props.location.pathname,
    //         },
    //       }}
    //     />
    //   );
    // }

    if (currentUser.isAuthenticated && currentUser.user.isDriver) {
      return <Redirect to="/driver" />;
    }
    if (currentUser.isAuthenticated && currentUser.user.isRestaurant) {
      return <Redirect to="/restaurant" />;
    }
    const isMember =
      cart?.groupOrderType > -1
        ? cart?.user?.membership?.isActive
        : currentUser?.user?.membership?.isActive;
    const dishesLoaded = dishesToDisplay && dishesToDisplay.length > 0;
    const wasMember = currentUser?.user?.membership?.canceled_at ? true : false;
    const isNotOrganizer =
      cart?.user?._id && cart?.user?._id !== currentUser?.user?._id;

    const cartCount = getItemNumberInCart(cart);

    if (currentUser.isAuthenticated && currentUser.user.isDriver) {
      return <Redirect to="/driver" />;
    }

    return fullScreenCollage ? (
      <FullScreenCollage
        images={
          restaurant.heroImages || restaurant.bestSellers.map((d) => d.imageURL)
        }
        onClose={this.handleSetFullScreenCollage.bind(this, false)}
      />
    ) : (
      <div className="page single-meal">
        {/* <HomeFromMenu isAuthenticated={currentUser.isAuthenticated} source={source} fbclid={fbclid} /> */}
        <Toaster />
        <NavWithDate
          windowStart={windowStart}
          restaurant={restaurant}
          onBack={this.handleGoBack}
          mealType={mealType}
          fromWeekly={fromWeekly || fromHoliday || fromGuest}
          onToggleUserMenu={this.handleToggleUserMenu}
          currentUser={currentUser}
          onSignOut={this.handleSignOut}
          showUserMenu={showUserMenu}
          cart={cart}
          onToggleShowMenuLink={this.handleToggleShowMenuLink}
          distance={distance}
          isToday={isToday}
          orderBy={orderBy}
          fbclid={pixel?.fbclid}
          source={pixel?.source}
          restaurantId={pixel?.restaurantId}
        />
        {/* <AppStoreLink
          customStyle={{
            backgroundColor: Beige,
            width: "100%",
            borderBottom: `1px solid ${BorderGrey}`,
          }}
          fbclid={pixel?.fbclid}
          source={pixel?.source}
        /> */}

        <PopUp
          isPoppedUp={
            currentUser.isAuthenticated && !currentUser.user?.deliveryZone?.name
          }
          componentToDisplay={
            <DeliveryZoneByCitySelectorUnselected
              deliveryZoneByCity={deliveryZoneByCity}
              onSelectCity={this.handleSelectDeliveryCity}
            />
          }
          backgroundColor={"#333"}
        />
        {restaurant?.isTopChoice && (
          <ImageCarousel
            images={
              restaurant?.heroImages ||
              restaurant?.bestSellers?.map((d) => d?.imageURL)
            }
            onOpenFullScreenCollage={this.handleSetFullScreenCollage.bind(
              this,
              true
            )}
          />
        )}
        {currentUser?.isAuthenticated && (
          <RestaurantComment
            restaurant={restaurant}
            onReview={this.handleReview}
            customStyle={{
              padding: "0px 10px 10px 10px",
              borderBottom: "1px solid #ddd",
            }}
          />
        )}

        <MenuTwoChoices
          distance={distance}
          activeIndex={activeIndex}
          isAnonymous={!currentUser.isAuthenticated}
          dishes={dishesToDisplay}
          categories={categoriesToDisplay}
          categoryNavDesktopRef={this.categoryBarDesktopRef}
          categoryNavMobileRef={this.categoryBarMobileRef}
          onToggleCategory={this.handleToggleCategoryDisplay}
          restaurant={restaurant}
          cart={cart}
          onOpenDishDetails={this.handleOpenDishDetails}
          onEditDishComment={this.handleEditDishComment}
          nomination={nomination}
          nominationErrorMessage={this.state.nominationErrorMessage}
          onEditNomination={this.handleEditNomination}
          onSubmitNomination={this.handleSubmitNomination}
          onSelectRestaurantAddress={this.handleSelectRestaurantAddress}
          onChangeResstaurantName={this.handleChangeRestaurantName}
          mealType={mealType}
          showRestaurantDetailsMobile={showRestaurantDetailsMobile}
          onToggleMobileRestaurantDetails={
            this.handleToggleMobileRestaurantDetails
          }
          onBackToTop={this.handleBackToTop}
          loading={!dishesLoaded}
          onGoToCategory={this.handleGoToCategory}
          windowStart={windowStart}
          mealId={mealId || meal?._id}
          isOverflowDesktop={isOverflowDesktop}
          isUnderflowDesktop={isUnderflowDesktop}
          onMoveCategoryCarousel={this.handleMoveDestkopCategoryPage}
          onToggleShowMenuLink={this.handleToggleShowMenuLink}
          onAddDishToCart={this.handleAddDishToCart}
          onAddGoodsToCart={this.handleAddGoodsToCart}
          isUserGroupMeal={isUserGroupMeal}
          onRemoveDishFromCart={this.handleRemoveDishFromCart}
          trendy={trendy}
          isMember={isMember}
          onStartMembership={this.handleStartMembership.bind(
            this,
            "MenuTwoChoices"
          )}
          onShowAuth={this.handleShowAuth}
          isAuthenticated={currentUser?.isAuthenticated}
          onShowGroupOrder={this.handleShowGroupOrder}
          onOpenInvite={this.handleShowInviteGroupOrder}
          isNotOrganizer={isNotOrganizer}
          addingDish={addingDish}
          onFlashCartPeek={this.handleFlashCartPeek}
          onShowCartOnMeal={this.handleShowCartOnMeal}
          fromHoliday={fromHoliday}
          menuLink={menuLink}
          source={pixel?.source}
          fbclid={pixel?.fbclid}
        />
        <GroupOrderCard
          onClose={this.handleHideGroupOrder}
          show={showGroupOrder}
          onStart={this.handleCreateGroupOrder}
        />
        <InviteGroupOrder
          onClose={this.handleHideInviteGroupOrder}
          show={showInviteGroupOrder}
          menuLink={menuLink}
          groupOrderId={cart?._id}
          restaurantName={restaurant?.name}
          organizerName={`${cart?.user?.firstName} ${cart?.user?.lastName}`}
          myName={`${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`}
        />
        <NominationBanner
          showConfirmation={showNominationConfirmation}
          onHideConfirmation={this.handleHideNominationConfirmation}
        />
        {selectedDish && (
          <DishChooserContainer
            modalify={true}
            dishId={selectedDish._id}
            goodKey={goodKey}
            good={cart?.goods[goodKey]}
            onClose={this.handleCloseSelections}
            onAddGoodsToCart={this.handleAddGoodsToCart}
            onUpdateGoodInCart={this.handleUpdateGoodInCart}
            countInCart={countInCart}
            isMember={isMember}
            isAuthenticated={currentUser?.isAuthenticated}
            onStartMembership={this.handleStartMembership.bind(
              this,
              "DishChooserContainer"
            )}
            isNotOrganizer={isNotOrganizer}
            menuLink={menuLink}
            source={pixel?.source}
            fbclid={pixel?.fbclid}
          />
        )}
        {/* {showGroupOrder || showInviteGroupOrder ? null : isMobile ? (
          <CheckoutBanner
            initializing={initializing}
            cart={cart}
            onHideConfirmation={this.handleHideConfirmation}
            isMember={isMember}
            isAuthenticated={currentUser?.isAuthenticated}
            wasMember={wasMember}
            onStartMembership={this.handleStartMembership.bind(
              this,
              "CheckoutBanner"
            )}
            onResumeMembership={this.handleResumeMembership}
            card={currentUser?.user?.stripeInfo?.card}
            restaurantIsMemberOnly={restaurant?.memberOnly}
            userId={currentUser?.user?._id}
            isNotOrganizer={isNotOrganizer}
            somethingChanged={itemsChanged}
          />
        ) : ( */}
        <CheckoutThumbnail
          showCartOnMeal={showCartOnMeal}
          number={cartCount}
          showPeek={showCartPeek}
          onHidePeek={this.handleHidePeek}
          goodsToPeek={goodsToPeek}
          // for debuggin
          // goodsToPeek={Object.values(this.props?.cart?.goods).slice(0, 1)}
          cartCount={cartCount}
          isMember={isMember}
          onEditGoodInCart={this.handleEditGoodInCart}
          menuLink={this.props.match?.url}
          source={pixel?.source}
          fbclid={pixel?.fbclid}
        />
        {/* )} */}
        {/* <CartPeek
          show={showCartPeek}
          onHide={this.handleHidePeek}
          
        /> */}
        {/* <PopUp
          isPoppedUp={showCartOnMeal}
          componentToDisplay={<CartOnMeal onHide={this.handleHideCartOnMeal} />}
          hidePopUp={this.handleHideCartOnMeal}
          backgroundColor="transparent"
        /> */}
        <PopUp
          isPoppedUp={showMenuLink}
          componentToDisplay={
            <MenuLink
              link={menuLink}
              // onCopy={this.handleSaveMealConfirmStatus}
              onCancel={this.handleToggleShowMenuLink}
            />
          }
          hidePopUp={this.handleToggleShowMenuLink}
          backgroundColor="#222"
        />
        <BackToTop bottom={110} />
        {/* <LiveChat hasShadow={false} /> */}
        {/* <Footer /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  // LOGGING && console.log("PageSingleMeal got redux state:", state);
  return {
    dishes: state.dishes.payLoad,
    menuLastUpdate: state.dishes.loadedAt,
    cart: state.cart.payLoad,
    currentUser: state.currentUser,
    nomination: state.nomination,
    deliveryZoneByCity: state.deliveryZoneByCity,
    meals: state.meals,
    orders: state.orders,
    carts: state.carts,
    pixel: state.pixel,
  };
}

const WrappedPageSingleMeal = GoogleApiWrapper({
  apiKey: "AIzaSyBjfTfgJdRah5wXvVj8U7fH4ACoqKry9HI",
  LoadingContainer: () => null,
})(PageSingleMeal);

export default connect(mapStateToProps, {
  emptyCart,
  addGoodsToCart,
  removeDishFromCart,
  setDishCommentInCart,
  saveNomination,
  checkPreorderInventoryAvailablity,
  submitNomination,
  selectIsLunch,
  setIsLunch,
  // checkReferralEligibility,
  getReferralCode,
  checkOrderCount,
  checkOrderTotal,
  setDeliveryCity,
  updateDeliveryCity,
  fetchSingleMealByName,
  fetchDeliveryZoneByCity,
  startMembership,
  resumeMembership,
  createGroupOrder,
  checkGroupOrder,
  readGroupOrder,
  loadCartForMeal,
  checkExistingCart,
  updateOrderOnMeal,
  updateExistingGoodInCart,
  readPastOrdersFromOneRestaurantByCustomer,
  readMealRestaurantUserStats,
  setRestaurantReview,
  loadClickID,
})(PageSingleMeal);
