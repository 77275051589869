import { faCrown, faStar, faTag } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronSquareRight as faEnter,
  faTruck,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCheck,
  faRocketLaunch as faExpress,
  faCrown as faExclusive,
  faTag as faSaving,
  faArrowToTop,
  faBan,
  faShoppingCart,
  faUsers,
  faCar as faLocation,
  faTrophy as faHonor,
  faCalendar,
  faCalendarAlt,
  faCalendarDay,
} from "@fortawesome/pro-regular-svg-icons";

// import { faRocket as faExpress } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import "./WeeklyMenu.scss";
import { Link } from "react-router-dom";
import { MealVisited } from "src/api/meals";
import { TrackAdsEvent } from "src/api/tracker";
import { Flex } from "src/components/Layout";
import { calcDistance } from "src/util/location";
import {
  CreateReferralCode,
  OrderingWithCode,
  MenuDateFormat,
  LoadingTop,
  HeroImage,
  LOGGING,
} from ".";
import { BackToTop } from "./customers/mealMenu/BackToTop";
import { LoadingDishDescription } from "./customers/mealMenu/LoadingMeals";
import { AlertButton } from "./favorites/AlertButton";
import { Yellow, MapPin } from "./Shared";
import { CATimeZone, MenuDateFormatLong } from "../../../constants";
import {
  isBeforeCutOffTimeForOrderTime,
  DeliveryWindowDisplay,
  CutOffDisplay,
} from "../../../util/time";
import { faYelp } from "@fortawesome/free-brands-svg-icons";

const MealItem = ({
  meal,
  userLocation,
  isClosed,
  isPlanned,
  mealType,
  date,
  restaurantName,
  mealRef,
  homeScrollY,
  isAuthenticated,
  source,
  fbclid,
}) => {
  let rushText = null;

  if (meal.earlyCutoffTime) {
    const isCutSameDay = moment(meal.earlyCutoffTime).isSame(
      moment(meal.windowStart),
      "d"
    );
    rushText = `${moment(meal.earlyCutoffTime)
      .tz("America/Los_Angeles")
      .format(isCutSameDay ? "h:mmA" : "ddd MM/DD h:mmA")}`;
  }

  const { markups, name } = meal.restaurant || {};
  const isExclusive = !markups?.doordash?.isAvailable;
  LOGGING && meal?.cart && console.log(`MealItem got cart`, meal?.cart);
  let distance = 0;
  if (userLocation?.lat && userLocation?.lng && meal?.restaurant?.map) {
    distance = calcDistance(userLocation, meal?.restaurant?.map);
  }

  const mealText = (
    <div className="meal-text">
      <span className="restaurant-name">
        {isPlanned && meal.restaurant?.name
          ? meal.restaurant?.name?.split(/,|-/)[0]
          : "TBD"}
      </span>
      <div className="restaurant-info line-1">
        {distance > 0 ? (
          <div className="info-field miles">
            <MapPin />
            {/* <label><FontAwesomeIcon icon={faLocation} /></label> */}
            <span>{(distance * 1.3).toFixed(1)} miles to you</span>
          </div>
        ) : null}
        {meal?.restaurant?.yelp?.rating ? (
          <div className="info-field yelp">
            <label>
              <FontAwesomeIcon icon={faYelp} />
              Yelp
            </label>
            <span>
              {" "}
              {meal?.restaurant?.yelp?.rating} (
              {meal?.restaurant?.yelp?.reviews?.toLocaleString()})
            </span>
          </div>
        ) : null}
      </div>
      <div className="restaurant-info line-2">
        {markups?.doordash?.amount > 0 ? (
          <div className="info-field member saving">
            <div className="icon-container">
              <FontAwesomeIcon icon={faCrown} />
            </div>
            <span>
              Member
              {` ${
                markups?.doordash?.amount2
                  ? `up to ${markups?.doordash?.amount2}% off`
                  : `${markups?.doordash?.amount}% off`
              }`}
            </span>
          </div>
        ) : null}
        {isExclusive ? (
          <div className="info-field member only">
            <div className="icon-container">
              <FontAwesomeIcon icon={faCrown} />
            </div>
            <span>member only</span>
          </div>
        ) : null}
      </div>
      {meal?.restaurant?.hasSushi || meal?.restaurant?.honor ? (
        <div className="restaurant-info line-3">
          {meal?.restaurant?.hasSushi ? (
            <div className="info-field transport">
              <FontAwesomeIcon icon={faTruck} />
              with AC
            </div>
          ) : null}
          {meal?.restaurant?.honor ? (
            <div className="info-field honor">
              <FontAwesomeIcon icon={faHonor} />
              {` ${meal?.restaurant?.honor}`}
            </div>
          ) : null}
        </div>
      ) : null}

      {isClosed ? (
        <span className="last-call mobile closed">
          Closed
          <FontAwesomeIcon icon={faBan} />
        </span>
      ) : (
        <span className="last-call mobile">
          order now
          <FontAwesomeIcon icon={faEnter} />
        </span>
      )}
    </div>
  );
  return isClosed ? (
    <div className="meal-menu short">
      {rushText != null ? (
        <div className="capacity-left">
          <span>last call</span>
          <span>{rushText}</span>
        </div>
      ) : null}
      <div className="overlay"></div>

      {meal?.restaurant?.heroImages &&
      meal?.restaurant?.heroImages.length > 0 ? (
        <HeroImage images={meal.restaurant.heroImages} />
      ) : meal?.restaurant?.heroImage ? (
        <img
          className="hero-dish-image"
          src={meal.restaurant.heroImage || meal.heroDish.imageURL}
          alt="dish"
          // style={{ height: `${heroHeight}px` }}
        />
      ) : (
        <div className="hero-dish-image"></div>
      )}
      {mealText}
      {meal?.order ? (
        // if this customer has ordered for this meal, show the future order.
        <Link
          className="meal-menu-order ordered"
          // to={{ pathname: `/order/${meal.orders[0]._id}`, state: { from: 'weekly' } }}
          to={`/order/${meal?.order?._id}`}
          onClick={(e) => e.stopPropagation()}
        >
          <Flex direction="column" align="center" justify="center" heightFull>
            <FontAwesomeIcon icon={faCheck} />
            <span className="ordered-label">ordered</span>
          </Flex>
        </Link>
      ) : null}
    </div>
  ) : (
    // is open
    <Link
      className={`meal-menu ${meal?.order || meal?.cart ? "tall" : "short"}`}
      to={{
        //:date/:mealType/:restaurantName
        pathname: meal?.cart?.isGroupOrder
          ? `group/${meal?.cart?._id}`
          : `meal/${date}/${
              mealType === "earlyDinner" ? "express-dinner" : mealType
            }/${restaurantName}`,
        state: {
          fromWeekly: isAuthenticated,
          fromGuest: !isAuthenticated,
          mealId: meal._id,
          restaurant: meal.restaurant,
          homeScrollY,
          distance,
          userLocation,
          restaurantLocation: meal.restaurant?.map,
        },
      }}
      disabled={isClosed || !isPlanned}
      ref={mealRef}
      onClick={() => {
        // Insert Facebook Pixel tracking here
        if (window.fbq && fbclid) {
          LOGGING && console.log("single meal click is tracked by fbq");
          window.fbq("track", "ViewContent", {
            content_name: `meal/${date}/${
              mealType === "earlyDinner" ? "express-dinner" : mealType
            }/${restaurantName}`,
            content_category: "pageGuest",
            value: meal?.cart?.total || 0,
            currency: "USD",
          });

          // Track Ads Event
          TrackAdsEvent(fbclid, {
            source,
            eventCode: "ViewContent",
            page: "pageGuest",
            contentName: `meal/${date}/${
              mealType === "earlyDinner" ? "express-dinner" : mealType
            }/${restaurantName}`,
          });
        }
      }}
    >
      {meal?.restaurant?._id ? (
        <AlertButton
          restaurantId={meal?.restaurant?._id}
          restaurantName={name}
          position={{ right: 15 }}
        />
      ) : null}
      {rushText != null ? (
        <span className="capacity-left">
          last call <br></br>
          {rushText}
        </span>
      ) : null}

      <div className="overlay">
        <span className="which-meal">
          {/* <FontAwesomeIcon icon={faCalendarAlt} /> */}
          {`${moment(meal.windowStart).format(
            mealType.includes("early") ? "ddd" : "dddd"
          )} ${mealType.includes("early") ? "early dinner" : mealType}`}
        </span>
      </div>

      {meal?.restaurant?.heroImages &&
      meal?.restaurant?.heroImages.length > 0 ? (
        <HeroImage images={meal.restaurant.heroImages.slice(0, 3)} />
      ) : meal?.restaurant?.heroImage ? (
        <img
          className="hero-dish-image"
          src={meal.restaurant.heroImage || meal.heroDish.imageURL}
          alt="dish"
          // style={{ height: `${heroHeight}px` }}
        />
      ) : (
        <div className="hero-dish-image"></div>
      )}
      {mealText}
      {meal?.order ? (
        <Link
          className="meal-menu-order ordered"
          // to={{ pathname: `/order/${meal.orders[0]._id}`, state: { from: 'weekly' } }}
          to={`/order/${meal?.order?._id}`}
          onClick={(e) => e.stopPropagation()}
        >
          <label>
            <FontAwesomeIcon icon={faCheck} />
          </label>
          <span>ordered</span>
        </Link>
      ) : meal?.cart?.length || meal?.carts?.length ? (
        <div className={`meal-menu-order ordered cart`}>
          <Flex direction="column" align="center" justify="center" heightFull>
            <FontAwesomeIcon icon={faShoppingCart} />
            <span className="ordered-label">cart</span>
          </Flex>
        </div>
      ) : null}
    </Link>
  );
};

const MealMenu = ({
  meal,
  userLocation,
  mealType,
  mealRef,
  windowStart,
  homeScrollY,
  onFreeze,
  freeze,
  innerHeight,
  isAuthenticated,
  source,
  fbclid,
}) => {
  const isClosed = meal.isOrderable === false;
  const isPlanned = meal?.restaurant?.name;

  if (!isPlanned) return null; //return <MealTBD isLunch={isLunch} />;
  const restaurantName = meal.restaurant.name
    .trim()
    .replace(/[\W_]+/g, " ")
    .toLowerCase()
    .split(" ")
    .join("-");

  const date = moment(windowStart)
    .tz("America/Los_Angeles")
    .format("MM-DD-YYYY");

  return isClosed ? (
    // <a> and <div> calculate padding differently. Normally this would be a div but
    // change to an <a> so it has consistent UI with other elements
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <div
      style={{ cursor: "initial" }}
      className={`meal-menu-container closed-container`}
    >
      <MealItem
        meal={meal}
        userLocation={userLocation}
        isClosed={isClosed}
        mealType={mealType}
        isPlanned={isPlanned}
        isAuthenticated={isAuthenticated}
        source={source}
        fbclid={fbclid}
      />
    </div>
  ) : (
    <div
      className={`meal-menu-container`}
      // style={{ height: `${heroHeight}px` }}
    >
      {/* {meal?.restaurant?.hasSushi ? <SushiDelivery /> : null}
      {meal?.restaurant?.honor ? (
        <RestaurantHonor honor={meal.restaurant.honor} />
      ) : null} */}
      <MealItem
        meal={meal}
        userLocation={userLocation}
        restaurantName={restaurantName}
        date={date}
        homeScrollY={homeScrollY}
        mealRef={mealRef}
        isClosed={isClosed}
        mealType={mealType}
        isPlanned={isPlanned}
        onFreeze={onFreeze}
        freeze={freeze}
        innerHeight={innerHeight}
        isAuthenticated={isAuthenticated}
        source={source}
        fbclid={fbclid}
      />
    </div>
  );
};
const OneMealTime = ({
  mealType,
  userLocation,
  isClosed,
  mealsForZone,
  mealRefs,
  windowStart,
  homeScrollY,
  onFreeze,
  freeze,
  innerHeight,
  isAuthenticated,
  source,
  fbclid,
}) => {
  if (isClosed) return null;
  return (
    <div className={`menu-meals ${mealType} ${isClosed ? "closed" : ""}`}>
      <div className="view-meal">
        <span className="meal-type">
          {/* {mealType === "earlyDinner" ? (
            <FontAwesomeIcon icon={faExpress} />
          ) : null} */}
          {`${mealType === "earlyDinner" ? "Early Dinner" : mealType}`}
        </span>
        <span className="delivery-window mobile">
          Order by <b>{CutOffDisplay[mealType]}</b>, Receive{" "}
          <b>{DeliveryWindowDisplay[mealType]}</b>
        </span>
        <span className="delivery-window desktop">{`last call ${CutOffDisplay[mealType]}`}</span>
      </div>
      <div className="meal-list">
        {mealsForZone.map((mealforZone, index) => (
          <MealMenu
            key={index}
            meal={mealforZone}
            userLocation={userLocation}
            mealType={mealType}
            windowStart={windowStart}
            homeScrollY={homeScrollY}
            onFreeze={onFreeze}
            freeze={freeze}
            innerHeight={innerHeight}
            isAuthenticated={isAuthenticated}
            mealRef={
              mealforZone && mealforZone._id && mealRefs
                ? mealRefs[mealforZone._id]
                : null
            }
            source={source}
            fbclid={fbclid}
          />
        ))}
      </div>
    </div>
  );
};
const DayMenu = ({
  menu,
  dishesLoaded,
  hiddenForMobile,
  selectedDeliveryZone,
  userLocation,
  mealRefs,
  dayRef,
  today,
  tomorrow,
  homeScrollY,
  orderHistoryByRestaurant,
  onFreeze,
  freeze,
  innerHeight,
  isAuthenticated,
  source,
  fbclid,
}) => {
  const {
    time,
    lunchTime,
    dinnerTime,
    earlyDinnerTime,
    earlyDinner,
    lunch,
    dinner,
  } = menu || {};
  const date = moment(time || lunchTime);
  const menuDate = date.format(MenuDateFormatLong);
  const lunchGraceMinutes = (lunch || []).some(
    (meal) => meal.restaurant?.allowLateCutoff
  )
    ? 15
    : 0;
  const earlyDinnerGraceMinutes = (earlyDinner || []).some(
    (meal) => meal.restaurant?.allowLateCutoff
  )
    ? 15
    : 0;
  const dinnerGraceMinutes = (dinner || []).some(
    (meal) => meal.restaurant?.allowLateCutoff
  )
    ? 15
    : 0;

  const dateHighlight =
    menuDate === today ? "today" : menuDate === tomorrow ? "tomorrow" : null;
  const lunchIsClosed =
    lunchTime && !isBeforeCutOffTimeForOrderTime(lunchTime, lunchGraceMinutes);
  const earlyDinnerIsClosed =
    earlyDinnerTime &&
    !isBeforeCutOffTimeForOrderTime(earlyDinnerTime, earlyDinnerGraceMinutes);
  const dinnerIsClosed =
    dinnerTime &&
    !isBeforeCutOffTimeForOrderTime(dinnerTime, dinnerGraceMinutes);

  // let lunchesForDeliveryZone = lunch?.filter((entry) =>
  //   entry.supportedDeliveryZone.includes(selectedDeliveryZone)
  // );
  // if (!lunchesForDeliveryZone || lunchesForDeliveryZone.length === 0) {
  //   lunchesForDeliveryZone = [];
  // }

  // let earlyDinnersForDeliveryZone = earlyDinner?.filter((entry) =>
  //   entry.supportedDeliveryZone.includes(selectedDeliveryZone)
  // );
  // if (
  //   !earlyDinnersForDeliveryZone ||
  //   earlyDinnersForDeliveryZone.length === 0
  // ) {
  //   earlyDinnersForDeliveryZone = [];
  // }

  // let dinnersForDeliveryZone = dinner?.filter((entry) =>
  //   entry.supportedDeliveryZone.includes(selectedDeliveryZone)
  // );
  // if (!dinnersForDeliveryZone || dinnersForDeliveryZone.length === 0) {
  //   dinnersForDeliveryZone = [];
  // }

  const shouldShowDay =
    (lunch ?? []).length > 0 ||
    (earlyDinner ?? []).length > 0 ||
    (dinner ?? []).length > 0;

  if (!shouldShowDay) return null;

  // const lunchOrders = lunch
  //   ?.map((entry) => entry.orders)
  //   .reduce((x, y) => x.concat(y), []);
  // const earlyDinnerOrders = earlyDinner
  //   ?.map((entry) => entry.orders)
  //   .reduce((x, y) => x.concat(y), []);
  // const dinnerOrders = dinner
  //   ?.map((entry) => entry.orders)
  //   .reduce((x, y) => x.concat(y), []);
  return (
    <div
      className={`day-menu ${
        (lunchIsClosed && dinnerIsClosed && earlyDinnerIsClosed) ||
        hiddenForMobile
          ? "hidden-for-mobile"
          : ""
      } `}
      ref={dayRef}
    >
      <div
        className={`day-menu-date mobile ${
          lunchIsClosed && dinnerIsClosed && earlyDinnerIsClosed
            ? "closed-date"
            : ""
        } ${
          dateHighlight === "today" || dateHighlight === "tomorrow"
            ? "high-light"
            : ""
        }`}
      >
        {dateHighlight ? <span>{dateHighlight}</span> : null}
        <span>{menuDate != null ? menuDate : null}</span>
      </div>
      <div className="menu-content">
        {lunch?.length > 0 ? (
          <OneMealTime
            mealType="lunch"
            userLocation={userLocation}
            mealsForZone={lunch}
            mealRefs={mealRefs}
            isClosed={lunchIsClosed}
            windowStart={lunchTime}
            homeScrollY={homeScrollY}
            onFreeze={onFreeze}
            freeze={freeze}
            innerHeight={innerHeight}
            isAuthenticated={isAuthenticated}
            source={source}
            fbclid={fbclid}
          />
        ) : null}

        {earlyDinner?.length > 0 ? (
          <OneMealTime
            mealType="earlyDinner"
            userLocation={userLocation}
            mealsForZone={earlyDinner}
            mealRefs={mealRefs}
            isClosed={earlyDinnerIsClosed}
            windowStart={earlyDinnerTime}
            homeScrollY={homeScrollY}
            onFreeze={onFreeze}
            freeze={freeze}
            innerHeight={innerHeight}
            isAuthenticated={isAuthenticated}
            source={source}
            fbclid={fbclid}
          />
        ) : null}
        {dinner?.length > 0 ? (
          <OneMealTime
            mealType="dinner"
            userLocation={userLocation}
            mealsForZone={dinner}
            mealRefs={mealRefs}
            isClosed={dinnerIsClosed}
            windowStart={dinnerTime}
            homeScrollY={homeScrollY}
            onFreeze={onFreeze}
            freeze={freeze}
            innerHeight={innerHeight}
            isAuthenticated={isAuthenticated}
            source={source}
            fbclid={fbclid}
          />
        ) : null}
      </div>
    </div>
  );
};

export const DateNavbar = ({
  dates,
  ordersByDate,
  onGoToDate,
  selectedDayIndex,
  showUserMenu,
}) => {
  // LOGGING && console.log('DateNavbar rendering with:', { ordersByDate });

  if (dates == null) return null;

  return (
    <div
      className={`date-navbar ${
        showUserMenu ? "show-user-menu" : "hide-user-menu"
      }`}
    >
      {dates.map((date, index) => (
        <button
          className={`date-button ${
            selectedDayIndex == index ? "selected" : ""
          }`}
          onClick={onGoToDate.bind(this, index)}
          key={index}
        >
          <span className="day-of-week">{moment(date).format("ddd")}</span>
          <div className="day-of-month">
            <span className="date">{moment(date).format("D")}</span>
            {/* {ordersByDate != null && ordersByDate[index] != null ? (
              <div className="orders-of-day">
                {Array.from(Array(ordersByDate[index]))?.map((o) => (
                  <div className="one-order">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                ))}
              </div>
            ) : null} */}
          </div>
        </button>
      ))}
    </div>
  );
};

const CurrentDayMenu = ({
  menu,
  dayRef,
  mealRefs,
  dishesLoaded,
  selectedDeliveryZone,
  userLocation,
  today,
  tomorrow,
  homeScrollY,
  orderHistoryByRestaurant,
  onFreeze,
  freeze,
  isAuthenticated,
  source,
  fbclid,
}) => {
  return (
    <div className="current-day">
      {/* <FontAwesomeIcon icon={pinIcon} /> */}
      <DayMenu
        menu={menu}
        key={0}
        dayIndex={0}
        dateKey={moment(menu.lunchTime).tz(CATimeZone).format(MenuDateFormat)}
        dishesLoaded={dishesLoaded}
        selectedDeliveryZone={selectedDeliveryZone}
        userLocation={userLocation}
        mealRefs={mealRefs}
        dayRef={dayRef}
        today={today}
        tomorrow={tomorrow}
        homeScrollY={homeScrollY}
        orderHistoryByRestaurant={orderHistoryByRestaurant}
        onFreeze={onFreeze}
        freeze={freeze}
        isAuthenticated={isAuthenticated}
        source={source}
        fbclid={fbclid}
      />
    </div>
  );
};

const UpcomingDayMenus = ({
  menu,
  dayRefs,
  mealRefs,
  dishesLoaded,
  selectedDeliveryZone,
  today,
  tomorrow,
  isAuthenticated,
  userLocation,
  weekOfYear,
  homeScrollY,
  onFreeze,
  orderHistoryByRestaurant,
  freeze,
  innerHeight,
  source,
  fbclid,
}) => {
  // LOGGING && console.log("UpcomingDayMenus rendering with menu:", menu);
  return (
    <div className={`upcoming-days ${isAuthenticated ? "authenticated" : ""}`}>
      {/* <ScheduledMeals /> */}
      {menu.map((day, index) => (
        <DayMenu
          menu={day}
          key={index}
          dayIndex={index}
          dateKey={moment(day.lunchTime).tz(CATimeZone).format(MenuDateFormat)}
          dishesLoaded={dishesLoaded}
          selectedDeliveryZone={selectedDeliveryZone}
          userLocation={userLocation}
          mealRefs={mealRefs}
          dayRef={dayRefs[index]}
          today={today}
          tomorrow={tomorrow}
          weekOfYear={weekOfYear}
          homeScrollY={homeScrollY}
          orderHistoryByRestaurant={orderHistoryByRestaurant}
          onFreeze={onFreeze}
          freeze={freeze}
          innerHeight={innerHeight}
          isAuthenticated={isAuthenticated}
          source={source}
          fbclid={fbclid}
        />
      ))}
    </div>
  );
};

const WeeklyMenuLink = ({
  userName,
  menu,
  dishesLoaded,
  selectedDeliveryZone,
  userLocation,
  referralCode,
  showReferralCodeAnnouncement,
  mealRefs,
  isAuthenticated,
  dayRefs,
  today,
  tomorrow,
  weekOfYear,
  homeScrollY,
  orderHistoryByRestaurant,
  freeze,
  onFreeze,
  innerHeight,
  readingCounter,
  onSetReadingCounter,
  source,
  fbclid,
}) => {
  LOGGING &&
    console.log(
      "WeeklyMenuLink rendering with isAuthenticated:",
      isAuthenticated
    );
  if (!dayRefs?.[0]?.current) {
    onSetReadingCounter(readingCounter + 1);
    // LOGGING &&
    //   console.log(`${userName} got weeklyMenu`, {
    //     menu,
    //     lunch: menu?.lunch,
    //     earlyDinner: menu?.earlyDinner,
    //     dinner: menu?.dinner,
    //   });
    let message = `menu ${readingCounter + 1} time`;

    // try {
    //   const lunchNames = menu?.[0]?.lunch?.map((m) => m.restaurant?.name);
    //   const earlyDinnerNames = menu?.[0]?.earlyDinner?.map(
    //     (m) => m.restaurant?.name
    //   );
    //   const dinnerNames = menu?.[0]?.dinner?.map((m) => m.restaurant?.name);

    //   if (lunchNames?.length > 0) {
    //     message += `\n[${lunchNames.join(", ")}]`;
    //   }
    //   if (earlyDinnerNames?.length > 0) {
    //     message += `\n[${earlyDinnerNames.join(", ")}]`;
    //   }
    //   if (dinnerNames?.length > 0) {
    //     message += `\n[${dinnerNames.join(", ")}]`;
    //   }
    // } catch (error) {
    //   // console.error('WeeklyMenuLink error:', error);
    // }

    if (readingCounter > 0 && readingCounter % 100 === 0) {
      MealVisited(message, userName, "", "", 0);
    }

    if (readingCounter === 3) {
      const menuString = menu
        .map((dayMenu) => {
          const { lunchTime, lunch, earlyDinner, dinner } = dayMenu;
          const lunchIds = lunch.map(
            (lunchItem) => lunchItem?.restaurant?.name
          );
          const earlyDinnerIds = earlyDinner.map(
            (earlyDinnerItem) => earlyDinnerItem?.restaurant?.name
          );
          const dinnerIds = dinner.map(
            (dinnerItem) => dinnerItem?.restaurant?.name
          );
          const dateStr = moment(lunchTime).format("MMM Do");
          return `\n${dateStr}\nlunch:${lunchIds.join(
            ","
          )}\nearlyDinner:${earlyDinnerIds.join(",")}\ndinner:${dinnerIds.join(
            ","
          )}`;
        })
        .join("\n");
      MealVisited(`read menu 3 times got:${menuString})}`, userName, "", "", 0);
      // console.log(
      //   `${userName} read menu ${readingCounter + 1} times got weeklyMenu`,
      //   menu
      // );
    }
  }

  return (
    <div
      className={`weekly-menu ${
        referralCode || showReferralCodeAnnouncement ? "has-gift-card" : ""
      } ${isAuthenticated ? "logged-in" : "anonymous"} ${
        freeze >= 0 ? "freeze" : ""
      }`}
    >
      {freeze < 0 ? <BackToTop bottom={20} /> : null}
      {referralCode ? <OrderingWithCode code={referralCode} /> : null}
      {showReferralCodeAnnouncement ? <CreateReferralCode /> : null}
      {menu ? (
        <div className="weekly-menu-content mobile">
          <CurrentDayMenu
            menu={menu[0]}
            dayRef={dayRefs[0]}
            mealRefs={mealRefs}
            dishesLoaded={dishesLoaded}
            today={today}
            tomorrow={tomorrow}
            selectedDeliveryZone={selectedDeliveryZone}
            userLocation={userLocation}
            homeScrollY={homeScrollY}
            orderHistoryByRestaurant={orderHistoryByRestaurant}
            onFreeze={onFreeze}
            freeze={freeze}
            innerHeight={innerHeight}
            isAuthenticated={isAuthenticated}
            source={source}
            fbclid={fbclid}
          />
          {/* {isAuthenticated ? null : (
              <HowItWorks
                howItWorksRef={howItWorksRef}
                show={showHowItWorks}
                onToggleShow={onToggleShowHowItWorks}
              />
            )} */}
          {/* {isAuthenticated ? (
              <UpvoteForm placeholder="e.g. Din Tai Fung for next Sunday dinner!" />
            ) : null} */}
          <UpcomingDayMenus
            menu={menu.slice(1)}
            dayRefs={dayRefs.slice(1)}
            dishesLoaded={dishesLoaded}
            today={today}
            tomorrow={tomorrow}
            weekOfYear={weekOfYear}
            selectedDeliveryZone={selectedDeliveryZone}
            mealRefs={mealRefs}
            isAuthenticated={isAuthenticated}
            userLocation={userLocation}
            homeScrollY={homeScrollY}
            orderHistoryByRestaurant={orderHistoryByRestaurant}
            onFreeze={onFreeze}
            innerHeight={innerHeight}
            source={source}
            fbclid={fbclid}
          />
          {dayRefs?.[0]?.current ? null : (
            <div className="warning-message">
              <label>Reading menu...</label>
              <p>
                If reading persists, please <b>log out</b> and <b>log in</b>{" "}
                again, thanks!
                {/* <Link to="/auth" className="auth-link">
                  sign in
                </Link> */}
              </p>
            </div>
          )}
        </div>
      ) : (
        <LoadingTop text="loading menu" />
      )}
    </div>
  );
};

export const EmptyWeeklyMenu = () => {
  return (
    <div className="weekly-menu">
      <div className="weekly-menu-content">
        <div className="current-day empty">
          <div className="day-menu">
            <div className="menu-content">
              <div className="menu-meals empty">
                <div className="view-meal empty"></div>
                <div className="meal-list">
                  <div className="meal-menu-container empty">
                    <div className="meal-menu empty">
                      <div className="overlay empty shimmer"></div>
                      <div className="meal-text empty">
                        <LoadingDishDescription />
                        <LoadingDishDescription />
                        <LoadingDishDescription />
                        <LoadingDishDescription />
                      </div>
                    </div>
                  </div>
                  <div className="meal-menu-container empty">
                    <div className="meal-menu empty">
                      <div className="overlay empty shimmer"></div>
                      <div className="meal-text empty">
                        <LoadingDishDescription />
                        <LoadingDishDescription />
                        <LoadingDishDescription />
                        <LoadingDishDescription />
                      </div>
                    </div>
                  </div>
                  <div className="meal-menu-container empty">
                    <div className="meal-menu empty">
                      <div className="overlay empty shimmer"></div>
                      <div className="meal-text empty">
                        <LoadingDishDescription />
                        <LoadingDishDescription />
                        <LoadingDishDescription />
                        <LoadingDishDescription />
                      </div>
                    </div>
                  </div>
                  <div className="meal-menu-container empty">
                    <div className="meal-menu empty">
                      <div className="overlay empty shimmer"></div>
                      <div className="meal-text empty">
                        <LoadingDishDescription />
                        <LoadingDishDescription />
                        <LoadingDishDescription />
                        <LoadingDishDescription />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="weekly-menu-content desktop">
          <div className="current-day empty">
            <div className="day-menu">
              <div className="menu-content">
                <div className="menu-meals empty">
                  {/* <div className="view-meal empty">                  
                </div>  */}
                  <div className="meal-list">
                    <div className="meal-menu-container empty">
                      <div className="meal-menu">
                        <div className="overlay empty shimmer"></div>
                        <div className="meal-text empty"></div>
                      </div>
                    </div>
                    <div className="meal-menu-container empty">
                      <div className="meal-menu">
                        <div className="overlay empty shimmer"></div>
                        <div className="meal-text empty"></div>
                      </div>
                    </div>
                    <div className="meal-menu-container empty">
                      <div className="meal-menu">
                        <div className="overlay empty shimmer"></div>
                        <div className="meal-text empty"></div>
                      </div>
                    </div>
                    <div className="meal-menu-container empty">
                      <div className="meal-menu">
                        <div className="overlay empty shimmer"></div>
                        <div className="meal-text empty"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeeklyMenuLink;
