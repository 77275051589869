import { current } from "immer";
import {
  resetBookmarks,
  resetPosts,
  resetCart,
  emptyCart,
  fetchDeliveryZoneByCity,
  LoadFavorites,
} from ".";
import { LOGGING, TOKEN_KEY } from "../../constants";
import {
  SET_CURRENT_USER,
  SET_PHONE_ONLY,
  SET_FEATURES,
  RESET_ORDERS_HISTORY_BY_RESTAURANT,
  RESET_CURRENT_USER,
} from "../actionTypes";
import { apiCall } from "../api";

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  user,
});
export const resetCurrentUser = () => ({
  type: RESET_CURRENT_USER,
});
export const setFeatures = (features) => ({
  type: SET_FEATURES,
  features,
});

export const setPhone = (phone) => ({
  type: SET_PHONE_ONLY,
  phone,
});

export const resetOrderHistoryByRestaurant = () => ({
  type: RESET_ORDERS_HISTORY_BY_RESTAURANT,
});
export function signOut() {
  return (dispatch) => {
    localStorage.clear();
    dispatch(resetPosts());
    dispatch(resetBookmarks());
    dispatch(setCurrentUser({}));
    // dispatch(resetCart());
    dispatch(fetchDeliveryZoneByCity());
    dispatch(resetOrderHistoryByRestaurant());
  };
}

export const addPhone = (phone) => {
  LOGGING && console.log("setPhone");
  return (dispatch) => {
    dispatch(setPhone(phone));
  };
};

export const signUp = (user) => {
  LOGGING && console.log("signUp called with user;", user);
  return (dispatch, getState) => {
    const pixel = getState().pixel;
    LOGGING && console.log("signUp called with pixel;", pixel);

    if (pixel) {
      user = { ...user, pixel };
    }
    return apiCall("POST", `/auth`, user)
      .then(({ token, ...user }) => {
        LOGGING && console.log("signup got", { token, user });
        if (token && user && user._id) {
          localStorage.setItem(TOKEN_KEY, token);
          dispatch(setCurrentUser(user));
          return true;
        } else {
          return false;
        }
      })
      .catch((err) => {
        LOGGING && console.log("signup got err", err);
      });
  };
};

// signUp2 has a more updated signature, more friendly to be called by tsx
export const signUp2 = (user) => async (dispatch, getState) => {
  try {
    const pixel = getState().pixel;
    LOGGING && console.log("signUp2 called with pixel;", pixel);
    if (pixel) {
      user = { ...user, pixel };
    }
    const result = await apiCall("POST", `/auth`, user).then(
      ({ token, ...user }) => {
        LOGGING && console.log("signup2 got", { token, user });
        if (token && user && user._id) {
          localStorage.setItem(TOKEN_KEY, token);
          dispatch(setCurrentUser(user));
          return true;
        } else {
          return false;
        }
      }
    );

    return result;
  } catch (err) {
    LOGGING && console.log("signup2 got err", err);
    throw err;
  }
};

export const signIn = (user) => {
  LOGGING && console.log("signIn");
  return (dispatch, getState) => {
    return apiCall("PUT", `/auth`, user, getState().currentUser)
      .then(({ token, features, ...user }) => {
        LOGGING && console.log("signIn got", { token, features, user });
        if (token && user && user._id) {
          localStorage.setItem(TOKEN_KEY, token);
          dispatch(setCurrentUser(user));
          dispatch(setFeatures(features));
          dispatch(resetPosts());
          dispatch(resetBookmarks());
        }
      })
      .catch((err) => {
        LOGGING && console.log("signIn got err", err);
        throw err;
      });
  };
};

export const startMembership = (page, paypalSubscribeId, newCard, saveCard) => {
  LOGGING &&
    console.log("startMembership called:", {
      paypalSubscribeId,
      newCard,
      saveCard,
      page,
    });
  return (dispatch, getState) => {
    const { currentUser } = getState();
    const { user } = currentUser;
    return apiCall(
      "PUT",
      `/auth/membership/${user._id}`,
      { paypalSubscribeId, newCard, saveCard, page },
      getState().currentUser
    )
      .then((membership) => {
        LOGGING && console.log("startMembership got", { membership });
        const {
          status,
          next_billing_date,
          start_date,
          card,
          isPaypal,
          isActive,
          canceled_at,
          subscriptionId,
          stripeInfo,
        } = membership;

        dispatch(
          setCurrentUser({
            ...user,
            membership: {
              ...user.membership,
              status,
              next_billing_date,
              start_date,
              isPaypal,
              isActive,
              canceled_at,
              subscriptionId,
            },
            stripeInfo: card ? { card, ...stripeInfo } : { ...stripeInfo },
          })
        );
      })
      .catch((err) => {
        LOGGING && console.log("startMembership got err", err);
        throw err;
      });
  };
};

export const resumeMembership = () => {
  LOGGING && console.log("resumeMembership");
  return (dispatch, getState) => {
    const { currentUser } = getState();
    const { user } = currentUser;
    return apiCall(
      "POST",
      `/auth/membership/${user._id}`,
      {},
      getState().currentUser
    )
      .then((membership) => {
        LOGGING && console.log("resumeMembership got", { membership });
        dispatch(
          setCurrentUser({
            ...user,
            membership: { ...user.membership, ...membership },
          })
        );
      })
      .catch((err) => {
        LOGGING && console.log("resumeMembership got err", err);
        throw err;
      });
  };
};

export const cancelMembership = ({
  reason = null,
  feedback = null,
  isPaused,
}) => {
  LOGGING &&
    console.log("cancelMembership called with", { reason, feedback, isPaused });
  return (dispatch, getState) => {
    const { currentUser } = getState();
    const { user } = currentUser;
    return apiCall(
      "DELETE",
      `/auth/membership/${currentUser.user._id}`,
      isPaused ? { isPaused } : { reason, feedback },
      getState().currentUser
    )
      .then((membership) => {
        LOGGING && console.log("cancelMembership got", { membership });
        dispatch(
          setCurrentUser({
            ...user,
            membership: { ...user.membership, ...membership },
          })
        );
      })
      .catch((err) => {
        LOGGING && console.log("startMembership got err", err);
        throw err;
      });
  };
};

export const getMembershipStatus = () => {
  LOGGING && console.log("getMembershipStatus");
  return (dispatch, getState) => {
    const { currentUser } = getState();
    const { user } = currentUser;
    return apiCall(
      "get",
      `/auth/membership/${currentUser.user._id}`,
      {},
      getState().currentUser
    )
      .then((membership) => {
        LOGGING && console.log("getMembershipStatus got", { membership });
        dispatch(
          setCurrentUser({
            ...user,
            membership: { ...user.membership, ...membership },
          })
        );
      })
      .catch((err) => {
        LOGGING && console.log("startMembership got err", err);
        throw err;
      });
  };
};

export const delegate = (userId) => {
  LOGGING && console.log("delegate:", userId);
  return (dispatch, getState) => {
    return apiCall("put", `/auth/delegate/${userId}`, getState().currentUser)
      .then(({ token, features, ...user }) => {
        LOGGING && console.log("delegate got", { token, features, user });
        if (token && user && user._id) {
          localStorage.setItem(TOKEN_KEY, token);
          dispatch(setCurrentUser(user));
          dispatch(setFeatures(features));
          dispatch(resetPosts());
          dispatch(resetBookmarks());
          return;
        }
      })
      .catch((err) => {
        LOGGING && console.log("delegate got err", err);
        throw err;
      });
  };
};

export const initialize = () => async (dispatch) => {
  try {
    // const { currentUser } = getState();
    const { token, user, features } = await apiCall("GET", "/user/initialize");
    if (token && user) {
      localStorage.setItem(TOKEN_KEY, token);
      dispatch(setCurrentUser({ /*...currentUser.user, */ ...user }));
    } else {
      localStorage.clear();
      dispatch(setCurrentUser({}));
    }
    dispatch(setFeatures(features));
  } catch (err) {
    LOGGING && console.log("initialize got err", err);
    dispatch(setCurrentUser({}));
  }
};

export const fetchUser = () => {
  return (dispatch, getState) => {
    const { currentUser, cart } = getState();
    LOGGING && console.log("fetchUser called with:", { currentUser, cart });
    if (!currentUser.isAuthenticated) {
      return Promise.resolve();
    }
    const mealId = cart?.payLoad?.meal?._id;

    return apiCall("get", `/user/`, { hi: "hi" }, currentUser)
      .then((user) => {
        LOGGING && console.log("fetchUser got", { user });
        dispatch(setCurrentUser(user));
        return user;
      })
      .catch((err) => {
        LOGGING && console.log("fetchUser got err", err);
      });
  };
};

export const fetchUserByName = (userName) => {
  LOGGING && console.log("fetchUserByName");
  return (dispatch, getState) => {
    return apiCall("GET", `/auth/user/${userName}`, {}, getState().currentUser)
      .then((user) => {
        LOGGING && console.log("fetchUserByName got", { user });
        return user;
      })
      .catch((err) => {
        LOGGING && console.log("fetchUserByName got err", err);
      });
  };
};

export const updateUser = (userId, data) => {
  LOGGING && console.log("updateUser called with: ", { userId, data });
  return (dispatch, getState) => {
    return apiCall(
      "POST",
      `/auth/update/${userId}`,
      data,
      getState().currentUser
    )
      .then(({ token, ...user }) => {
        LOGGING && console.log("updateUser got", { token, user });
        if (token && user && user._id) {
          localStorage.setItem("jwtToken", token);
          dispatch(setCurrentUser(user));
        }
      })
      .catch((err) => {
        LOGGING && console.log("updateUser got err", err);
        throw err;
      });
  };
};

export const addCredit = (additionalCredit) => {
  LOGGING && console.log("addCredit called with: ", { additionalCredit });
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "POST",
      `/auth/credit/${currentUser?.user?._id}`,
      { additionalCredit },
      getState().currentUser
    )
      .then(({ credit }) => {
        LOGGING && console.log("addCredit got credit", credit);

        dispatch(setCurrentUser({ ...currentUser?.user, credit }));
      })
      .catch((err) => {
        LOGGING && console.log("addCredit got err", err);
        throw err;
      });
  };
};

// note that data needs to be in teh format of
// {"interaction.30D-trial", 1}
export const updateInteraction = (userId, data) => {
  LOGGING && console.log("updateInteraction called with: ", { userId, data });
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "POST",
      `/auth/interaction/${userId}`,
      data,
      getState().currentUser
    )
      .then(({ interaction }) => {
        LOGGING && console.log("updateInteraction got", interaction);
        dispatch(setCurrentUser({ ...currentUser.user, interaction }));
      })
      .catch((err) => {
        LOGGING && console.log("updateUser got err", err);
        throw err;
      });
  };
};

export const updateUserInfo = (userId, data, options) => {
  LOGGING && console.log("updateUserInfo called with: ", { userId, data });
  return async (dispatch, getState) => {
    try {
      await apiCall("POST", `/users/${userId}`, data, getState().currentUser);
      options?.success?.();
    } catch (err) {
      LOGGING && console.log("updateUserInfo got err", err);
      options?.error?.(err);
    }
  };
};

export const readUsers = (initial) => {
  LOGGING && console.log("readUsers called with:", { initial });
  return (dispatch, getState) => {
    const { currentUser } = getState();
    const userListURL = initial
      ? `/users/eatersByInitial/${initial}/${currentUser.user._id}`
      : `/users/eaters/${currentUser.user._id}`;
    return apiCall("get", userListURL, {}, getState().currentUser)
      .then((users) => {
        LOGGING && console.log("readUsers got", users);

        return users;
      })
      .catch((err) => {
        LOGGING && console.log("readUsers got err", err);
        throw err;
      });
  };
};
export const readUser = (userId) => {
  LOGGING && console.log("readUser called with:", { userId });
  return (dispatch, getState) => {
    return apiCall(
      "get",
      `/users/getOneUser/${userId}`,
      {},
      getState().currentUser
    )
      .then((user) => {
        LOGGING && console.log("readUser got", user);
        return user;
      })
      .catch((err) => {
        LOGGING && console.log("readUser got err", err);
        throw err;
      });
  };
};

export const readUsersSimple = () => {
  LOGGING && console.log("readUsersSimple called.");
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall("get", `/users/listUsersLight`, {}, getState().currentUser)
      .then((users) => {
        LOGGING && console.log("readUsersSimple got", users);
        const lowerCaseUsers = users.map((user) => {
          return {
            ...user,
            name: `${user?.firstName?.toLowerCase()} ${user?.lastName?.toLowerCase()}`,
            email: user?.email?.toLowerCase(),
            phone: user?.phone,
          };
        });
        return lowerCaseUsers;
      })
      .catch((err) => {
        LOGGING && console.log("readUsersSimple got err", err);
        throw err;
      });
  };
};
export const setSurveyAnswer = (question, answer) => {
  LOGGING && console.log("setSurveyAnswer called with:", { question, answer });

  return (dispatch, getState) => {
    const { currentUser } = getState();
    const { user } = currentUser;
    const { survey } = user || {};
    return apiCall(
      "post",
      `/users/survey/${currentUser.user._id}`,
      { survey: { ...survey, [question]: answer }, question },
      getState().currentUser
    )
      .then((updateSurvey) => {
        LOGGING &&
          console.log("setSurveyAnswer got updateSurvey", updateSurvey);
        dispatch(setCurrentUser({ ...user, survey: updateSurvey }));
      })
      .catch((err) => {
        LOGGING && console.log("setSurveyAnswer got err", err);
        throw err;
      });
  };
};

export const setPreferences = (preferences) => {
  LOGGING && console.log("setPreferences called with:", preferences);

  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "post",
      `/users/preferences/${currentUser?.user?._id}`,
      preferences,
      getState().currentUser
    )
      .then((updatedPreferences) => {
        LOGGING &&
          console.log(
            "setPreferences got updatedPreferences",
            updatedPreferences
          );
        dispatch(
          setCurrentUser({
            ...currentUser?.user,
            preferences: updatedPreferences,
          })
        );
      })
      .catch((err) => {
        LOGGING && console.log("setPreferences got err", err);
        throw err;
      });
  };
};

export const saveReminder = (reminder) => {
  LOGGING && console.log("saveReminder called with:", { reminder });

  return (dispatch, getState) => {
    const { currentUser } = getState();
    const { user } = currentUser;
    return apiCall(
      "post",
      `/users/reminder/${currentUser.user._id}`,
      { reminder },
      getState().currentUser
    )
      .then((updatedReminder) => {
        LOGGING &&
          console.log("saveReminder got updatedReminder", updatedReminder);
        dispatch(setCurrentUser({ ...user, reminder: updatedReminder }));
      })
      .catch((err) => {
        LOGGING && console.log("setSurveyAnswer got err", err);
        throw err;
      });
  };
};
export const readUsersByGMV = (period, rankBy) => {
  LOGGING && console.log("readUsersByGMV called with:", { period, rankBy });
  const number = Number(period[0]),
    cycle = period.toUpperCase()[1];
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "post",
      `/users/gmv/${currentUser.user._id}`,
      { number, cycle, rankBy },
      getState().currentUser
    )
      .then((users) => {
        LOGGING && console.log("readUsersByGMV got", users);
        return users;
      })
      .catch((err) => {
        LOGGING && console.log("readUsersByGMV got err", err);
        throw err;
      });
  };
};

export const readUsersMarketing = () => {
  LOGGING && console.log("readUsersMarketing called.");
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "put",
      `/users/eaters/${currentUser.user._id}`,
      {},
      getState().currentUser
    )
      .then((users) => {
        LOGGING && console.log("readUsersMarketing got", users);
        return users;
      })
      .catch((err) => {
        LOGGING && console.log("readUsersMarketing got err", err);
        throw err;
      });
  };
};

export const changePassword = ({ userId, passwordOld, password }) => {
  LOGGING && console.log("changePassword called with: ", { userId });
  return (dispatch, getState) => {
    return apiCall(
      "POST",
      `/auth/password/${userId}`,
      {
        passwordOld,
        password,
      },
      getState().currentUser
    )
      .then(({ token, ...user }) => {
        LOGGING && console.log("changePassword got", { token, user });
        if (token && user && user._id) {
          localStorage.setItem("jwtToken", token);
          dispatch(setCurrentUser(user));
        }
      })
      .catch((err) => {
        LOGGING && console.log("changePassword got err", err);
        throw err;
      });
  };
};

export const changeFavorites = (favorites) => {
  LOGGING && console.log("changeFavorites called with: ", { favorites });

  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "POST",
      `/users/${currentUser?.user?._id}`,
      {
        favorites,
      },
      currentUser
    )
      .then((updatedUser) => {
        LOGGING && console.log("changeFavorites got", updatedUser);
        dispatch(setCurrentUser(updatedUser));
      })
      .catch((err) => {
        LOGGING && console.log("changeFavorites got err", err);
        throw err;
      });
  };
};

export const sendFeedback = ({
  mealId,
  restaurantId,
  dishId,
  windowStart,
  content,
}) => {
  LOGGING &&
    console.log("sendFeedback called with: ", {
      mealId,
      restaurantId,
      dishId,
      windowStart,
      content,
    });

  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "POST",
      `/users/feedback/${currentUser?.user?._id}`,
      {
        mealId,
        restaurantId,
        dishId,
        windowStart,
        content,
      },
      currentUser
    )
      .then((savedFeedback) => {
        LOGGING && console.log("sendFeedback got", savedFeedback);
        return savedFeedback;
      })
      .catch((err) => {
        LOGGING && console.log("sendFeedback got err", err);
        throw err;
      });
  };
};

export const sendFeedbackAnswer = ({ content }) => {
  LOGGING && console.log("sendFeedbackAnswer called with: ", { content });

  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "POST",
      `/users/feedbackAnswer/${currentUser?.user?._id}`,
      content,
      currentUser
    )
      .then((savedFeedbackAnswer) => {
        LOGGING && console.log("sendFeedbackAnswer got", savedFeedbackAnswer);
        dispatch(
          setCurrentUser({
            ...currentUser.user,
            feedback: savedFeedbackAnswer,
          })
        );
      })
      .catch((err) => {
        LOGGING && console.log("sendFeedbackAnswer got err", err);
        throw err;
      });
  };
};

export const visit = (pageName) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING && console.log("visit called with:", pageName, currentUser?.user);
    const { firstName, lastName } = currentUser?.user || {};
    return apiCall(
      "POST",
      `/users/visit`,
      { name: `${firstName} ${lastName}`, event: pageName },
      currentUser
    )
      .then((result) => {
        LOGGING && console.log("visit got", result);
        return result;
      })
      .catch((err) => {
        LOGGING && console.log("visit got err", err);
        throw err;
      });
  };
};

export const getTastes = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING && console.log("getTastes called with:", currentUser?.user?._id);
    return apiCall("GET", `/users/taste/${currentUser?.user?._id}`).then(
      (tastes) => {
        LOGGING && console.log("getTastes got", tastes);
        dispatch(setCurrentUser({ ...currentUser.user, tastes }));
        return tastes;
      }
    );
  };
};

export const getFavorites = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING && console.log("getFavorites called with:", currentUser?.user?._id);
    return apiCall("GET", `/users/favorite/${currentUser?.user?._id}`).then(
      (favorites) => {
        LOGGING && console.log("getFavorites got", favorites);
        // dispatch(setCurrentUser({ ...currentUser.user, favorites }));
        return favorites;
      }
    );
  };
};

export const saveTastes = (tastes) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("saveTastes called with:", currentUser?.user?._id, tastes);
    return apiCall("put", `/users/taste/${currentUser?.user?._id}`, {
      tastes,
    }).then(({ favorites, tastes }) => {
      LOGGING && console.log("saveTastes got", { favorites, tastes });
      dispatch(
        setCurrentUser({
          ...currentUser.user,
          tastes,
          favorites,
        })
      );
      // return favorites;
    });
  };
};

export const toggleFavorite = (restaurantId) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("toggleFavorite called with: ", {
        restaurantId,
        userId: currentUser?.user?._id,
      });
    return apiCall(
      "POST",
      `/users/favorite/${currentUser?.user?._id}`,
      {
        restaurantId,
      },
      currentUser
    )
      .then((favorites) => {
        LOGGING && console.log("toggleFavorite got", favorites);
        dispatch(setCurrentUser({ ...currentUser.user, favorites }));
      })
      .catch((err) => {
        LOGGING && console.log("toggleFavorite got err", err);
        throw err;
      });
  };
};

export const continueWithFacebook = (user) => {
  LOGGING && console.log("continueWithFacebook");
  return (dispatch, getState) => {
    return apiCall("POST", `/auth/facebook`, user, getState().currentUser)
      .then(({ token, ...user }) => {
        LOGGING && console.log("continueWithFacebook got", { token, user });

        if (token && user && user._id) {
          localStorage.setItem("jwtToken", token);
          dispatch(setCurrentUser(user));
        }
      })
      .catch((err) => {
        LOGGING && console.log("continueWithFacebook got err", err);
      });
  };
};

export const continueWithGoogle = (user) => {
  LOGGING && console.log("continueWithGoogle");
  return (dispatch, getState) => {
    return apiCall("POST", `/auth/google`, user, getState().currentUser)
      .then(({ token, ...user }) => {
        LOGGING && console.log("continueWithGoogle got", { token, user });
        if (token && user && user._id) {
          localStorage.setItem("jwtToken", token);
          dispatch(setCurrentUser(user));
        }
      })
      .catch((err) => {
        LOGGING && console.log("continueWithGoogle got err", err);
      });
  };
};

export const saveSetting = (card, delivery, receiveMarketingEmail) => {
  LOGGING && console.log("saveSetting called with:", { card, delivery });
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING && console.log("saveSetting got", { card, delivery });
    return apiCall(
      "post",
      `/auth/setting/${currentUser.user._id}`,
      {
        card,
        delivery,
        receiveMarketingEmail,
      },
      getState().currentUser
    )
      .then((result) => {
        LOGGING && console.log("saveSetting got result from backend:", result);
        const { stripeInfo, deliveryInfo, receiveMarketingEmail } = result;
        dispatch(
          setCurrentUser({
            ...currentUser.user,
            stripeInfo,
            deliveryInfo,
            receiveMarketingEmail,
          })
        );
      })
      .catch((err) => {
        LOGGING && console.log("saveSetting got err", err);
        let error = err;
        if (!err.param) {
          if (err.code === "incorrect_number" || err.code === "card_declined") {
            error.param = "number";
          }
          if (err.code === "invalid_cvc") {
            error.param = "cvc";
          }
          if (err.code === "invalid_expiry_month") {
            error.param = "exp_month";
          }
          if (err.code === "invalid_expiry_year") {
            error.param = "exp_year";
          }
        }
        throw error;
      });
  };
};

export const deleteCard = (options) => {
  return async (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING && console.log("deleteCard called.");
    try {
      const stripeInfo = await apiCall(
        "delete",
        `/user/card`,
        {},
        getState().currentUser
      );
      LOGGING && console.log("deleteCard got stripeInfo: ", stripeInfo);
      dispatch(setCurrentUser({ ...currentUser.user, stripeInfo }));
      options?.success?.();
    } catch (err) {
      options?.error?.(err);
    }
  };
};

export const checkUserEmail = (email) => {
  return async (dispatch, getState) => {
    LOGGING && console.log("checkUserEmail called with email:", email);
    try {
      const result = await apiCall(
        "post",
        `/auth/user/checkExisted/${email}`,
        {},
        getState().currentUser
      );
      LOGGING && console.log("checkUserEmail got: ", result);
      return result;
    } catch (err) {
      throw err;
    }
  };
};
export const createNewCard = (card, options) => {
  return async (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING && console.log("createNewCard called.");
    try {
      const stripeInfo = await apiCall(
        "post",
        `/user/card`,
        { card },
        getState().currentUser
      );
      LOGGING && console.log("createNewCard got stripeInfo: ", stripeInfo);
      dispatch(setCurrentUser({ ...currentUser.user, stripeInfo }));
      options?.success?.();
    } catch (err) {
      options?.error?.(err);
    }
  };
};

export const deleteCardFromSetting = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING && console.log("deleteCardFromSetting called.");
    return apiCall(
      "delete",
      `/auth/setting/${currentUser.user._id}`,
      {},
      getState().currentUser
    )
      .then(({ stripeInfo }) => {
        LOGGING &&
          console.log("deleteCardFromSetting got result from backend:", {
            stripeInfo,
          });
        dispatch(setCurrentUser({ ...currentUser.user, stripeInfo }));
      })
      .catch((err) => {
        LOGGING && console.log("deleteCardFromSetting got err", err);
      });
  };
};

export const checkUserExisted = (email) => {
  LOGGING && console.log("checkUserExisted");
  return (dispatch, getState) => {
    return apiCall(
      "GET",
      `/auth/user/checkExisted/${email}`,
      {},
      getState().currentUser
    );
  };
};

export const forgetPassword = (email) => {
  LOGGING && console.log("forgetPassword");
  return (dispatch, getState) => {
    return apiCall(
      "GET",
      `/auth/user/forgetpassword/${email}`,
      {},
      getState().currentUser
    );
  };
};

export const resetPassword = (user_id, token, password) => {
  LOGGING && console.log("resetpassword");
  return (dispatch, getState) => {
    return apiCall(
      "POST",
      `/auth/user/resetpassword/${user_id}`,
      {
        user_id,
        token,
        password,
      },
      getState().currentUser
    );
  };
};

export const getMarketingEmailTemplate = () => {
  return (dispatch, getState) => {
    return apiCall(
      "GET",
      `/email/marketingEmailTemplate`,
      {},
      getState().currentUser
    );
  };
};

export const checkReferralEligibility = () => {
  LOGGING && console.log("checkReferralEligibility called");
  return (dispatch, getState) => {
    const { currentUser } = getState();
    const { isAuthenticated, user } = currentUser;
    // if not authenticated, ignore
    if (!isAuthenticated) {
      return Promise.resolve();
    }
    return apiCall("get", `/referrals/${user._id}`, {}, getState().currentUser)
      .then(({ ordersPlaced, credit, creditSpent, ordersTotal, referrals }) => {
        LOGGING &&
          console.log(
            "checkReferralEligibility got from server: ",
            ordersPlaced,
            ordersTotal,
            credit,
            creditSpent,
            referrals
          );

        dispatch(
          setCurrentUser({
            ...user,
            ordersPlaced,
            ordersTotal,
            credit,
            creditSpent,
            referrals,
          })
        );
      })
      .catch((err) => {
        LOGGING &&
          console.log("checkReferralEligibility got err from server: ", err);
        throw err;
      });
  };
};

export const createReferrals = (friends) => {
  LOGGING && console.log("createReferrals called with:", friends);
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "POST",
      "/referrals",
      {
        userId: currentUser.user._id,
        friends,
      },
      {},
      getState().currentUser
    )
      .then((updatedUser) => {
        LOGGING && console.log("createReferrals got from server: ", updateUser);
        dispatch(setCurrentUser(updatedUser));
      })
      .catch((err) => {
        LOGGING && console.log("createReferrals got err from server: ", err);
        throw err;
        // dispatch(setCurrentUser(updatedUser));
      });
  };
};

export const generateReferralCode = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING &&
      console.log("generateReferralCode called with currentUser.", currentUser);
    if (!currentUser.user) {
      throw "unauthorzied";
    }
    return apiCall("get", "/referrals", {}, currentUser)
      .then((referralCode) => {
        LOGGING &&
          console.log(
            "generateReferralCode got code from server: ",
            referralCode
          );
        dispatch(setCurrentUser({ ...currentUser.user, referralCode }));
      })
      .catch((err) => {
        LOGGING &&
          console.log("generateReferralCode got err from server: ", err);
        throw err;
      });
  };
};

export const getUserOrderHistory = (userId) => {
  try {
    return (dispatch, getState) => {
      return apiCall(
        "get",
        `/orders/getUserOrderHistory/${userId}`,
        {},
        getState().currentUser
      )
        .then(({ user, orders, schedules }) => {
          LOGGING &&
            console.log("getUserOrderHistory got", { schedules, user, orders });
          return { user, orders, schedules };
        })
        .catch((err) => {
          LOGGING && console.log("getUserOrderHistory got err", err);
          throw err;
        });
    };
  } catch (err) {
    LOGGING && console.log("getUserHistory got err", err);
    throw err;
  }
};

export const readRestaurantsSimple = () => {
  LOGGING && console.log("readRestaurantsSimple called.");
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "get",
      `/restaurants/readRestaurantsSimple`,
      {},
      getState().currentUser
    )
      .then((restaurants) => {
        LOGGING && console.log("readRestaurantsSimple got", restaurants);
        return restaurants;
      })
      .catch((err) => {
        LOGGING && console.log("readUsersSimple got err", err);
        throw err;
      });
  };
};

export const getUserTastes = () => {
  return (dispatch, getState) => {
    return apiCall("GET", `/users/tastes`).then((users) => {
      LOGGING && console.log("getUserTastes got", users);
      return users;
    });
  };
};

export const getUserTasteProfile = (userId) => {
  return (dispatch, getState) => {
    return apiCall("GET", `/users/tasteProfile/${userId}`).then((tastes) => {
      LOGGING && console.log("getUserTasteProfile got", tastes);
      return tastes;
    });
  };
};

export const deleteAccount = () => {
  return (dispatch, getState) => {
    LOGGING && console.log("deleteAccount called.");
    return apiCall("DELETE", `/users/${getState()?.currentUser?.user?._id}`)
      .then((result) => {
        LOGGING && console.log("deleteAccount got", result);
        dispatch(resetCurrentUser());
        LOGGING &&
          console.log(
            "resetCurrentUser got currentUser:",
            getState().currentUser
          );
        return;
      })
      .catch((err) => {
        LOGGING && console.log("deleteAccount got err", err);
        throw err; // Rethrow the error for handling in calling code
      });
  };
};
