import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { Container, Field, Title } from "./Contact";

export const Tip = () => {
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const { tips, tipCustom, tipRate } = cart;
  return (
    <Container>
      <Title>Tip</Title>
      <Field>${tips}</Field>
    </Container>
  );
};
