import './Catelog.scss';

const Catelog = ({ selected, onSelect, itemNames }) => (
  <div className="tabs catelog">
    {Object.entries(itemNames).map(([key, value]) => (
      <button
        key={key}
        className={`tab ${selected === key ? 'selected' : ''}`}
        type="button"
        onClick={onSelect.bind(this, key)}
      >
        <span>{value}</span>
      </button>
    ))}
  </div>
);

export default Catelog;
