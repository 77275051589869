import React from 'react';
import './Hero.scss';
import { faRoute } from '@fortawesome/pro-regular-svg-icons';
// import { faRoute } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Back } from './';

// export const Hero = ({ isAnonymous }) => (
//   <div className="hero">
//     <div className="slogan">
//       <p>Restaurant prices,</p>
//       <p>no fees,</p>
//     </div>
//     <div className="notes">when you share menu with your neighbors.</div>
//     {isAnonymous && <button>sign up</button>}
//     <div className="deco">
//       <FontAwesomeIcon icon={faRoute} />
//     </div>
//   </div>
// );

export const Hero = ({ isAnonymous }) => (
  <div className="hero">
    <div className="slogan">
      <p>Restaurant prices,</p>
      <p>no fees,</p>
    </div>
    <div className="notes">when you share menu with your neighbors.</div>
    {isAnonymous && <button>sign up</button>}
    <div className="deco">
      <FontAwesomeIcon icon={faRoute} />
    </div>
  </div>
);

export default Hero;
