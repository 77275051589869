import { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { MealVisited } from "src/api/meals";
import { loadClickID } from "src/store/actions";
import { LOGGING } from ".";
import { AuthEntry } from "../tasting_menu/components/auth/AuthEntry";
import { AnonymousBackground } from "../tasting_menu/components/home/AnonymousBackground";

class PageAnonymousLanding extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
  }

  async componentDidMount() {
    LOGGING &&
      console.log("PageAnonymousLanding componentDidMount got", {
        state: this.state,
        props: this.props,
      });

    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get("source");
    if (source && source !== "undefined" && source !== "null") {
      MealVisited("landing", "anonymous", null, null, 0, source);
      this.props?.loadClickID(source, null);
      LOGGING &&
        console.log("PageAnonymousLanding got pixel", this.props?.pixel);
    }
    window.scrollTo(0, 0);
  }

  render() {
    const { showModal } = this.state;
    LOGGING &&
      console.log("PageAnonymousLanding got", {
        state: this.state,
        props: this.props,
      });
    if (this?.props?.currentUser?.isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <div className="page no-nav">
        <AnonymousBackground
          showModal={showModal}
          componentToDisplay={
            <>
              <AuthEntry
                next={this.props?.location?.state?.next}
                title={this.props?.location?.state?.title}
                newUserOnly={this.props?.location?.state?.newUserOnly}
              />
            </>
          }
        />

        {/* <AnonymousFooter /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

export default connect(
  mapStateToProps,
  {
    loadClickID,
  },
  null,
  {
    forwardRef: true,
  }
)(PageAnonymousLanding);
