import React, { Component, useEffect } from "react"; // eslint-disable-line no-unused-vars
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { Config } from "src/pages/Config";
import { Coupons } from "src/pages/Coupons";
import { Drivers } from "src/pages/Drivers";
import {
  GiftCard,
  CreateGiftCard,
  ReceiveGiftCard,
} from "src/pages/Home/GiftCard";
import { Credit as MyCredit } from "src/pages/Home/My/Credit";
import { RootState } from "src/store";
import { PageTermsOfService } from "./PageTermsOfService";
import {
  PageChangePassword,
  PageForgetPassword,
  PageResetPassword,
  PagePrivacy,
  PageTerms,
  PageSignUpByEmailReferral,
  PageSignUpByEmailReferralAward,
  PageSignInByEmail,
  PageAnonymousLanding,
  PageAuth,
} from "./shared";
import { PageFeed, PageRestaurant } from "./social_network";
import {
  PageCart,
  PageOrderList,
  PageTeamMealList,
  PageTeamMealDetails,
  PageHome,
  PageGuest,
  PageAdmin,
  PageSetting,
  PageUserList,
  PageTopUsers,
  PageDriver,
  PageRoutes,
  PageCurrentOrderBatch,
  PageRestaurantOrders,
  PageRestaurants,
  PageMenuCalendar,
  PageStatsCalendar,
  PageFAQ,
  PageLocations,
  PageAbout,
  PageRestaurantDirectory,
  PageRestaurantDetails,
  PageDishDetails,
  PageNominations,
  PageStats,
  PageUserGroupAdmin,
  PageUserGroupOrders,
  PageRestaurantSign,
  PageGroup,
  PageOrderDetails,
  PageRestaurantMeal,
  PageRestaurantMealNoSignIn,
  PageRestaurantPastMealNoSignIn,
  PageRestaurantHistory,
  // PageReferralCode,
  // PageOneMeal,
  PageSingleMeal,
  PageRestaurantMenu,
  PageShare,
  PageRestaurantMap,
  PageFavorites,
  PageMembership,
  PageUserStats,
  PageCartList,
  PageCheckout,
  PageNextMeal,
} from "./tasting_menu";
import { PageTopRestaurants } from "./tasting_menu";
import { SetUpReminder } from "./tasting_menu/components/customers/banners/SetUpReminder";
import { NewGift } from "./tasting_menu/components/customers/giftCards/GiftTemplate";
import { CancelMembership } from "./tasting_menu/components/customers/membership/CancelMembership";
import { Taste } from "./tasting_menu/components/customers/survey/Taste";
import { TasteAndTime } from "./tasting_menu/components/customers/survey/TasteAndTime";
import { NominationForm } from "./tasting_menu/components/MenuTwoChoices";
import { PageRestaurantMealMenu } from "./tasting_menu/components/restaurants/PageRestaurantMealMenu";
import { PageRestaurantUpcomingMealOrderReceipts } from "./tasting_menu/components/restaurants/PageRestaurantUpcomingMealOrderReceipts";
import PageAccount from "./tasting_menu/PageAccount";
import PageDeleteAccount from "./tasting_menu/PageDeleteAccount";
import { PageDeliveryV2 } from "./tasting_menu/PageDeliveryV2";
import { PageFavoritesOverview } from "./tasting_menu/PageFavoritesOverview";
import { PageRestaurantFinancials } from "./tasting_menu/PageFinancials";
import { PageGiftClaim } from "./tasting_menu/PageGiftClaim";
import { PageGifts } from "./tasting_menu/PageGifts";
import { PageGroupCart } from "./tasting_menu/PageGroupCart";
import PageHoliday from "./tasting_menu/PageHoliday";
import { PageLeadDriver } from "./tasting_menu/PageLeadDriver";
import { PageMessages } from "./tasting_menu/PageMessages";
import { PageMostOrderedDishes } from "./tasting_menu/PageMostOrderedDishes";
import { PagePlanner } from "./tasting_menu/PagePlanner";
import { PagePostCheckout } from "./tasting_menu/PagePostCheckout";
import { PagePriceComparison } from "./tasting_menu/PagePriceComparison";
import { PageReferralEvent } from "./tasting_menu/PageReferralEvent";
import { PageReferralStatus } from "./tasting_menu/PageReferralStatus";
import { PageReferralsV2 } from "./tasting_menu/PageReferralsV2";
import PageRestaurantGuide from "./tasting_menu/PageRestaurantGuide";
import { PageRestaurantMeals } from "./tasting_menu/PageRestaurantMeals";
import { PageRestaurantStats } from "./tasting_menu/PageRestaurantStats";
import PageSingleMealNoSignIn from "./tasting_menu/PageSingleMealNoSignIn";
import { PageSMLinkPreview } from "./tasting_menu/PageSMLinkPreview";
import { PageSurveyResults } from "./tasting_menu/PageSurveyResults";
import { PageUserTasteDetail } from "./tasting_menu/PageUserTasteDetail";
import { PageUserTasteOverview } from "./tasting_menu/PageUserTasteOverview";
import { PageUserTasteSearch } from "./tasting_menu/PageUserTasteSearch";
import { reportBug, initialize } from "../store/actions";
const bugReporterBuilder = (page) => {
  return (error, info) => {
    reportBug(error, info, page);
  };
};

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>
        Something went wrong. Foodie Admin has been notified. Please try reload
        the page and see if the error persists.
      </p>
    </div>
  );
};

const Root = () => {
  const dispatch = useDispatch();
  // const currentUser = useSelector((state) => state.currentUser);

  useEffect(() => {
    dispatch(initialize());
  }, [dispatch]);

  return (
    <Switch>
      <Route
        exact
        path="/feed"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageFeed")}
            FallbackComponent={ErrorFallback}
          >
            <PageFeed {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/share"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageFeed")}
            FallbackComponent={ErrorFallback}
          >
            <PageShare {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/guide2025/:restaurantId"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantMenu")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantGuide {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/guide2025"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantMenu")}
            FallbackComponent={ErrorFallback}
          >
            <PageTopRestaurants {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/nextmeal"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageNextMeal")}
            FallbackComponent={ErrorFallback}
          >
            <PageNextMeal {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/menu/:restaurantName"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantMenu")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantMenu {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/about"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageAbout")}
            FallbackComponent={ErrorFallback}
          >
            <PageAbout {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />

      <Route
        exact
        path="/restaurant-sign"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantSign")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantSign {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/faq"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageFAQ")}
            FallbackComponent={ErrorFallback}
          >
            <PageFAQ {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path={`/stats/:userId`}
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageUserStats")}
            FallbackComponent={ErrorFallback}
          >
            <PageUserStats {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />

      <Route
        exact
        path={`/smslinkpreview`}
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageSMLinkPreview")}
            FallbackComponent={ErrorFallback}
          >
            <PageSMLinkPreview {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path={`/pricecomparison`}
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageUserStats")}
            FallbackComponent={ErrorFallback}
          >
            <PagePriceComparison {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />

      {/* <Route
        exact
        path="/group"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageGroup")}
            FallbackComponent={ErrorFallback}
          >
            <PageGroup {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      /> */}

      <Route
        exact
        path="/auth"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageAuth")}
            FallbackComponent={ErrorFallback}
          >
            <PageAuth {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/unpaid-carts"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageCartList")}
            FallbackComponent={ErrorFallback}
          >
            <PageCartList {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/locations"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageFAQ")}
            FallbackComponent={ErrorFallback}
          >
            <PageFAQ {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      {/* <Route
        exact
        path="/locations"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageLocations")}
            FallbackComponent={ErrorFallback}
          >
            <PageLocations {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      /> */}
      <Route
        exact
        path="/referrals"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageReferralsV2")}
            FallbackComponent={ErrorFallback}
          >
            <PageReferralsV2 {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/referral-status"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageReferralStatus")}
            FallbackComponent={ErrorFallback}
          >
            <PageReferralStatus {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/survey-results"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageSurveyResults")}
            FallbackComponent={ErrorFallback}
          >
            <PageSurveyResults {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/group/:orderId"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageGroupCart")}
            FallbackComponent={ErrorFallback}
          >
            <PageGroupCart {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/referral-event"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageReferralEvent")}
            FallbackComponent={ErrorFallback}
          >
            <PageReferralEvent {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/referrals/:socialInviteCode"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageSignUpByEmailReferral")}
            FallbackComponent={ErrorFallback}
          >
            <PageSignUpByEmailReferral {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/treat/:socialInviteCode"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageSignUpByEmailReferralAward")}
            FallbackComponent={ErrorFallback}
          >
            <PageSignUpByEmailReferralAward {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/restaurants"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageFavorites")}
            FallbackComponent={ErrorFallback}
          >
            <PageFavorites {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/gifts"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageGifts")}
            FallbackComponent={ErrorFallback}
          >
            <PageGifts {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/claimGift/:giftId"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageGiftClaim")}
            FallbackComponent={ErrorFallback}
          >
            <PageGiftClaim {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/favorites-overview"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageFavoritesOverview")}
            FallbackComponent={ErrorFallback}
          >
            <PageFavoritesOverview {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/membership"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageMembership")}
            FallbackComponent={ErrorFallback}
          >
            <PageMembership {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/most-ordered"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageMostOrderedDishes")}
            FallbackComponent={ErrorFallback}
          >
            <PageMostOrderedDishes {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      {/* <Route
        exact
        path="/referralCode"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder('PageReferralCode')}
            FallbackComponent={ErrorFallback}
          >
            <PageReferralCode {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      /> */}
      {/* <Route
        exact
        path="/preview/:now"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder('PageTwoChoices-Preview')}
            FallbackComponent={ErrorFallback}
          >
            <PageTwoChoices {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      /> */}

      <Route
        exact
        path="/g/:code"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageTwoChoices")}
            FallbackComponent={ErrorFallback}
          >
            <PageHome {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      {/* <Route
        exact
        path="/gift/:code"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageHome")}
            FallbackComponent={ErrorFallback}
          >
            <PageHome {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      /> */}
      <Route
        exact
        path="/createGift/:giftTemplateId"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageHome")}
            FallbackComponent={ErrorFallback}
          >
            <NewGift {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/users"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageUserList")}
            FallbackComponent={ErrorFallback}
          >
            <PageUserList {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/users/:initial"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageUserList")}
            FallbackComponent={ErrorFallback}
          >
            <PageUserList {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />

      <Route
        exact
        path="/leaders"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageTopUsers")}
            FallbackComponent={ErrorFallback}
          >
            <PageTopUsers {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />

      <Route
        exact
        path="/restaurants"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurants")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurants {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/restaurant/:restaurantId/map"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantMap")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantMap {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />

      <Route
        exact
        path="/driver"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageDriver")}
            FallbackComponent={ErrorFallback}
          >
            <PageDriver {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />

      <Route
        exact
        path="/leaddriver"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageLeadDriver")}
            FallbackComponent={ErrorFallback}
          >
            <PageLeadDriver {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        path="/social-network"
        component={() => {
          window.location.href = "https://www.instagram.com/foodie.earth";
          return null;
        }}
      />
      <Route
        path="/nextdoor"
        component={() => {
          window.location.href =
            "https://nextdoor.com/pages/foodieearth-palo-alto-ca";
          return null;
        }}
      />
      <Route
        exact
        path="/test/:orderTime"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageHome-testOrderTime")}
            FallbackComponent={ErrorFallback}
          >
            <PageHome {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/restaurant/orders/:restaurantId"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantOrders")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantOrders {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/restaurant/:restaurantId/history"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantDetails")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantHistory {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/restaurant/:restaurantId"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantDetails")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantDetails {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      {/* ===== Restaurant App ====== */}
      <Route
        exact
        path="/restaurant-current-meal/:restaurantId"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantMealNoSignIn")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantMealNoSignIn {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/restaurant-past-meal/:restaurantId/:mealId"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantPastMealNoSignIn")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantPastMealNoSignIn {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/restaurant"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantMeal")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantMeal {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/restaurants/meals/:mealId/order-receipts"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder(
              "PageRestaurantUpcomingMealOrderReceipts"
            )}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantUpcomingMealOrderReceipts
              {...props}
              key={Date.now()}
            />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/restaurant-meals"
        render={(_props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantMeals")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantMeals />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/restaurant-meals/:mealId/menu"
        render={(_props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantMealMenu")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantMealMenu />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/restaurants/financials/"
        render={(_props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantFinancials")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantFinancials />
          </ErrorBoundary>
        )}
      />
      {/* ===== END Restaurant App ====== */}
      <Route
        exact
        path="/dish/new/restaurant/:restaurantId"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageDishDetails-new")}
            FallbackComponent={ErrorFallback}
          >
            <PageDishDetails {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/dish/:dishId"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageDishDetails")}
            FallbackComponent={ErrorFallback}
          >
            <PageDishDetails {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/orders"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageOrderList")}
            FallbackComponent={ErrorFallback}
          >
            <PageOrderList {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/team-orders"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageTeamMealList")}
            FallbackComponent={ErrorFallback}
          >
            <PageTeamMealList {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/team/:mealId"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageTeamMealDetails")}
            FallbackComponent={ErrorFallback}
          >
            <PageTeamMealDetails {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/order/:orderId"
        render={(props) => <PageOrderDetails {...props} key={Date.now()} />}
      />
      <Route
        exact
        path="/current"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageCurrentOrderBatch")}
            FallbackComponent={ErrorFallback}
          >
            <PageCurrentOrderBatch {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/stats"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageStats")}
            FallbackComponent={ErrorFallback}
          >
            <PageStats {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />

      <Route
        exact
        path="/checkout"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageCheckout")}
            FallbackComponent={ErrorFallback}
          >
            <PageCheckout {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/messages"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PagePostCheckout")}
            FallbackComponent={ErrorFallback}
          >
            <PageMessages {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/post-checkout"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PagePostCheckout")}
            FallbackComponent={ErrorFallback}
          >
            {/* {Math.random() > 0.5 ? ( */}
            <PagePostCheckout {...props} key={Date.now()} />
            {/* ) : (
              <PageReferralsV2 {...props} key={Date.now()} />
            )} */}
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/preferences"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PagePostCheckout")}
            FallbackComponent={ErrorFallback}
          >
            <TasteAndTime {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/taste"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PagePostCheckout")}
            FallbackComponent={ErrorFallback}
          >
            <Taste {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/reminders"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("SetUpReminder")}
            FallbackComponent={ErrorFallback}
          >
            <SetUpReminder {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/nominations"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageNominations")}
            FallbackComponent={ErrorFallback}
          >
            <PageNominations {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/setting"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageSetting")}
            FallbackComponent={ErrorFallback}
          >
            <PageSetting {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/delivery"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageAdmin")}
            FallbackComponent={ErrorFallback}
          >
            <PageAdmin {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/delivery_v2"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageAdmin")}
            FallbackComponent={ErrorFallback}
          >
            <PageDeliveryV2 {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/routes"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRoutes")}
            FallbackComponent={ErrorFallback}
          >
            <PageRoutes {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/restaurant-directory"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantDirectory")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantDirectory {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/calendar"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageMenuCalendar")}
            FallbackComponent={ErrorFallback}
          >
            <PageMenuCalendar {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />

      <Route
        exact
        path="/accounting"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageStatsCalendar")}
            FallbackComponent={ErrorFallback}
          >
            <PageStatsCalendar {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/account"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageAccount")}
            FallbackComponent={ErrorFallback}
          >
            <PageAccount {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/delete-account"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageDeleteAccount")}
            FallbackComponent={ErrorFallback}
          >
            <PageDeleteAccount {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/cart"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageCart")}
            FallbackComponent={ErrorFallback}
          >
            <PageCart {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/privacy"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PagePrivacy")}
            FallbackComponent={ErrorFallback}
          >
            <PagePrivacy {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/service"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageService")}
            FallbackComponent={ErrorFallback}
          >
            <PageTermsOfService {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/terms"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageTerms")}
            FallbackComponent={ErrorFallback}
          >
            <PageTerms {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/setting"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageSetting")}
            FallbackComponent={ErrorFallback}
          >
            <PageSetting {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/restaurant/:placeId"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurant")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurant {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/signin"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageSignInByEmail")}
            FallbackComponent={ErrorFallback}
          >
            <PageSignInByEmail {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/driver/signin"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageSignInByEmail")}
            FallbackComponent={ErrorFallback}
          >
            <PageSignInByEmail {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/changepassword"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageChangePassword")}
            FallbackComponent={ErrorFallback}
          >
            <PageChangePassword {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/forgetpassword"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageForgetPassword")}
            FallbackComponent={ErrorFallback}
          >
            <PageForgetPassword {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/resetpassword"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageResetPassword")}
            FallbackComponent={ErrorFallback}
          >
            <PageResetPassword {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/group-management"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageUserGroupAdmin")}
            FallbackComponent={ErrorFallback}
          >
            <PageUserGroupAdmin {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/group-orders"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageUserGroupOrders")}
            FallbackComponent={ErrorFallback}
          >
            <PageUserGroupOrders {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/config"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageConfig")}
            FallbackComponent={ErrorFallback}
          >
            <Config {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />

      <Route
        exact
        path="/couponCodes"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageConfig")}
            FallbackComponent={ErrorFallback}
          >
            <Coupons {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/drivers"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageDrivers")}
            FallbackComponent={ErrorFallback}
          >
            <Drivers {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/giftCard"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageGiftCard")}
            FallbackComponent={ErrorFallback}
          >
            <GiftCard {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/guest"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageGuest")}
            FallbackComponent={ErrorFallback}
          >
            <PageGuest {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/giftCard/create"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageCreateGiftCard")}
            FallbackComponent={ErrorFallback}
          >
            <CreateGiftCard {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/giftCard/:giftCardId"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageReceiveGiftCard")}
            FallbackComponent={ErrorFallback}
          >
            <ReceiveGiftCard {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/my/credit"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageMyCredit")}
            FallbackComponent={ErrorFallback}
          >
            <MyCredit {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/meal/:date/:mealType/:restaurantName/groupOrder/:groupOrderId"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageSingleMeal")}
            FallbackComponent={ErrorFallback}
          >
            <PageSingleMeal {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/meal/:date/:mealType/:restaurantName"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageSingleMeal")}
            FallbackComponent={ErrorFallback}
          >
            <PageSingleMeal {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/promo-meal/:date/:mealType/:restaurantName"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageSingleMealNoSignIn")}
            FallbackComponent={ErrorFallback}
          >
            <PageSingleMealNoSignIn {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/planner"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PagePlanner")}
            FallbackComponent={ErrorFallback}
          >
            <PagePlanner {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/restaurantStats"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageRestaurantStats")}
            FallbackComponent={ErrorFallback}
          >
            <PageRestaurantStats {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/tastes/:userId"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageUserTasteSearch")}
            FallbackComponent={ErrorFallback}
          >
            <PageUserTasteDetail {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/tastes_overview"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageUserTasteSearch")}
            FallbackComponent={ErrorFallback}
          >
            <PageUserTasteOverview {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/tastes"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageUserTasteSearch")}
            FallbackComponent={ErrorFallback}
          >
            <PageUserTasteSearch {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/cancelMembership"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("CancelMembership")}
            FallbackComponent={ErrorFallback}
          >
            <CancelMembership {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/holiday"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageHoliday")}
            FallbackComponent={ErrorFallback}
          >
            <PageHoliday {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route
        exact
        path="/home"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageAnonymousLanding")}
            FallbackComponent={ErrorFallback}
          >
            <PageAnonymousLanding {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />

      <Route
        exact
        path="/"
        render={(props) => (
          <ErrorBoundary
            onError={bugReporterBuilder("PageHome")}
            FallbackComponent={ErrorFallback}
          >
            <PageHome {...props} key={Date.now()} />
          </ErrorBoundary>
        )}
      />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default withRouter(Root);
