import * as Components from ".";

const Roger = ({ onClick }) => {
  const link = "/meal/02-14-2025/dinner/roger-bar-and-restaurant";
  const image =
    "https://i.pinimg.com/1200x/d2/69/19/d26919f5aa014547e5b5591d03c07cd9.jpg";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>
          Roger Bar and Restaurant
        </Components.RestaurantName>
        <Components.MenuName>Valentine' Day Prix Fixe</Components.MenuName>
        <Components.Notes>
          <Components.Warning>
            {/* <FontAwesomeIcon icon={faExclamationTriangle} /> */}
            <Components.Bullet></Components.Bullet>
            Pre-Order&nbsp;<b>by Thurday 2/13</b>
          </Components.Warning>
          <Components.DeliveryTime>
            {/* <FontAwesomeIcon icon={faTruck} />           */}
            <Components.Bullet></Components.Bullet>
            Receive&nbsp;<b>6-7P Friday, Valentine's Day</b>&nbsp;
          </Components.DeliveryTime>
          <Components.Instructions>
            <Components.Bullet></Components.Bullet>
            All sales are final.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Valentine's Day Prix Fixe Dinner
              </Components.DishName>
              <Components.AlaCartePrice>$69/person</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              <b>First Course</b>
              <br></br>Chef Jack & Diane's Egg Custard
              <br></br>egg custard with a mentaiko & white truffle foam, smoked
              trout roe, nori, chives (gf)
              <br></br>
              <b>Second Course</b>
              <br></br>Chef Chris & Lil's Tacos Campechanos
              <br></br>chorizo, skirt steak, crispy chicharron, onions, and
              cilantro on corn tortillas echo a mano, salsa, lime (gf)
              <br></br>
              <b>Third Course</b>
              <br></br>Chef Brent & Jen's Salmon Crispy Rice
              <br></br>seared salmon over a bed of short grain rice, dashi,
              ikura, nori (gf). A la carte $36.
              <br></br>
              <b>Fourth Course</b>
              <br></br>Chef Joel & Jane's Flourless Chocolate Cake
              <br></br>flourless chocolate cake, earl grey dark chocolate
              mousse, candied lemon (gf). A la carte $14.
              <br></br>
            </Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(link);
            }}
          >
            pre-order Valentine's Day Dinner
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default Roger;
