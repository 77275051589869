import { LOAD_HOLIDAY_MENUS } from "../actionTypes";

const holiday = (
  state = {
    meals: null,
    title: null,
    subTitle: null,
    imageUrl: null,
  },
  action
) => {
  switch (action.type) {
    case LOAD_HOLIDAY_MENUS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default holiday;
